import { type ReactNode, useEffect, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../contexts/AuthContext';
import { useInfiniteQuery } from '../../graphql/client';
import {
  ArtistVaultRowFragmentDoc,
  type FragmentType,
  getFragment,
  GetPriorityActiveVaultSubscriptionsDocument,
  makeFragmentData,
  MenuVaultSubscriptionFragmentDoc,
} from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';
import { type EventObject, EVENTS, type EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { normalizePath } from '../../utils/navigationUtils';
import { pluralizeText } from '../../utils/textUtils';
import { View } from '../common/View';
import { UserRow } from '../structures/UserRow';
import { useBatchedVaultUnseenUpdateCount } from '../views/hooks/useVaultUpdateCount';

gql(/* GraphQL */ `
  fragment MyVaultSubscription on VaultSubscription {
    id
    vault {
      id
      artist: artistProfile {
        id
        ...BubbleArtist
      }
    }
  }

  fragment MenuVaultSubscription on VaultSubscription {
    createdAt
    id
    updatedAt
    status
    vault {
      artist: artistProfile {
        id
        linkValue
        ...artistVaultRow
      }
      isUserArtistAdmin
      contentCount
      id
    }
  }

  query GetPriorityActiveVaultSubscriptions($after: String, $first: Int) {
    activeVaultSubscriptionsByPriority(after: $after, first: $first) {
      edges {
        cursor
        node {
          id
          ...MenuVaultSubscription
          ...MyVaultSubscription
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const LIMIT = 10;

export const SubscribedArtists = (props: {
  selectedHandleMemo?: string | null;
  closeAll: (() => void) | null;
  className?: string;
  source: 'menu' | 'vaults-page';
}) => {
  const { selectedHandleMemo, closeAll, source, className } = props;

  const { loginStatus, loggedInUser } = useAuthContext();

  const isMenuBar = source === 'menu';

  const withVaultTheme = isMenuBar && !!selectedHandleMemo;

  const [bottomRef, isAtBottom] = useInView({
    threshold: 0.1,
  });

  const {
    orderedList: vaults,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(GetPriorityActiveVaultSubscriptionsDocument, {
    enabled: loginStatus === LoginStatus.LOGGED_IN,
    filterQueryKey: {
      artistHandle: selectedHandleMemo,
      userId: loggedInUser?.id,
    },
    staleTime: 0,
    getNextPageParam: ({ data }) => {
      return (
        data.activeVaultSubscriptionsByPriority.pageInfo.hasNextPage && {
          after: data.activeVaultSubscriptionsByPriority.pageInfo.endCursor,
        }
      );
    },
    variables: ({ pageParam }) => {
      return {
        after: pageParam?.after ?? null,
        first: LIMIT,
      };
    },
    list: ({ activeVaultSubscriptionsByPriority }) => {
      return activeVaultSubscriptionsByPriority.edges.map(({ node }) => node);
    },
    uniq: ({ id }) => id,
  });

  const subscriptionData = getFragment(MenuVaultSubscriptionFragmentDoc, vaults);

  const menuClassName = 'mb-1 px-3 md2:py-2 md2:pl-2';

  const filteredSubscriptionData = useMemo(() => {
    const adminArtistIds = new Set(
      loggedInUser?.adminArtists?.map(artist => artist.artistId) ?? [],
    );

    return subscriptionData?.filter(({ vault }) => !adminArtistIds.has(vault.artist?.id ?? ''));
  }, [loggedInUser?.adminArtists, subscriptionData]);

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isAtBottom, isFetchingNextPage]);

  const memoizedAdminArtists = useMemo(() => {
    return loggedInUser?.adminArtists?.map(artist => {
      return (
        <MenuArtistRow
          key={`${artist.artistId}-${source}-user`}
          artist={makeFragmentData(
            {
              id: artist.artistId,
              name: artist.artistName,
              profileImage: artist.artistProfileImage,
              mainVault: {
                id: artist.artistMainVaultId,
                isUserArtistAdmin: true,
              },
              linkValue: artist.artistMainLinkValue,
              userId: artist.artistUserId,
            },
            ArtistVaultRowFragmentDoc,
          )}
          isSelected={!!selectedHandleMemo && artist.artistLinks.includes(selectedHandleMemo)}
          onClick={() => {
            closeAll?.();
          }}
          href={normalizePath(`/${artist.artistMainLinkValue}`)}
          event={{ type: EVENTS.MENU_NAVIGATE, properties: { type: 'my_vault' } }}
          vaultId={artist.artistMainVaultId}
          className={menuClassName}
          managedArtist
          withVaultTheme={withVaultTheme}
        />
      );
    });
  }, [closeAll, loggedInUser?.adminArtists, selectedHandleMemo, source, withVaultTheme]);

  return (
    <View className={className}>
      {memoizedAdminArtists}

      {loginStatus === LoginStatus.LOGGED_IN &&
        filteredSubscriptionData?.map(({ vault }, idx) =>
          !vault.artist ? null : (
            <MenuArtistRow
              artist={vault.artist}
              key={`${vault.id}-${idx}-${source}`}
              isSelected={selectedHandleMemo === vault.artist.linkValue}
              managedArtist={false}
              onClick={() => {
                closeAll?.();
              }}
              href={normalizePath(`/${vault.artist.linkValue}`)}
              event={{
                type: EVENTS.MENU_NAVIGATE,
                properties: { type: 'vault', artistId: vault.artist.id, vaultId: vault.id },
              }}
              vaultId={vault.id}
              className={menuClassName}
              withVaultTheme={withVaultTheme}
            />
          ),
        )}

      <div ref={bottomRef} />
    </View>
  );
};

type Props<Event extends EventType> = {
  artist: FragmentType<ArtistVaultRowFragmentDoc> | null;
  isSelected: boolean;
  onClick: () => void;
  href: string;
  event: EventObject<Event>;
  vaultId: string;
  className?: string;
  managedArtist: boolean;
  withVaultTheme: boolean;
};

function MenuArtistRow<Event extends EventType>({
  artist,
  isSelected,
  onClick,
  href,
  className,
  event,
  vaultId,
  managedArtist,
  withVaultTheme,
}: Props<Event>) {
  const { loginStatus } = useAuthContext();

  const { updateCount } = useBatchedVaultUnseenUpdateCount({
    enabled: loginStatus === LoginStatus.LOGGED_IN,
    vaultId,
    messageChannelId: null,
  });
  const hasUpdateCount = !!updateCount && updateCount > 0;
  const showUpdateCount = hasUpdateCount && !managedArtist;

  const { profileImage, name = 'Unnamed' } = getFragment(ArtistVaultRowFragmentDoc, artist) || {};

  return (
    <Container
      className={twMerge(
        'flex min-h-[58px] flex-row items-center justify-between gap-3 rounded-xl text-[unset] no-underline transition-all duration-300 ease-in-out',
        isSelected &&
          (withVaultTheme
            ? 'bg-vault_text/15 hover:bg-vault_text/20'
            : 'bg-white/15 hover:bg-white/20'),
        withVaultTheme
          ? 'relative hover:bg-vault_text/20 md2:h-9 md2:max-h-9 md2:min-h-9'
          : 'relative hover:bg-white/20 md2:h-9 md2:max-h-9 md2:min-h-9',
        className,
      )}
      href={href}
      onClick={
        event != null
          ? () => {
              trackEvent(event);
              onClick?.();
            }
          : onClick
      }
    >
      <AnimatePresence>
        <UserRow
          managedVault={managedArtist}
          name={name}
          profileImageUrl={profileImage?.artistSmallProfileImageUrl || profileImage?.cdnUrl}
          loading={false}
          verifiedBadge={false}
          useVaultTheme={withVaultTheme}
          subtitle={
            showUpdateCount
              ? `${updateCount} ${pluralizeText({ text: 'update', count: updateCount })}`
              : undefined
          }
          rightComponent={
            hasUpdateCount && (
              <motion.div
                key="updateIndicator"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={twMerge(
                  'h-2 w-2 rounded-full',
                  withVaultTheme ? 'bg-vault_text' : 'bg-white',
                )}
              />
            )
          }
        />
      </AnimatePresence>
    </Container>
  );
}

function Container({
  href,
  children,
  onClick,
  className,
  containerRef,
}: {
  href?: string;
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  containerRef?: (node?: Element | null | undefined) => void;
}) {
  if (href == null) {
    return (
      <View onClick={onClick} className={className} containerRef={containerRef}>
        {children}
      </View>
    );
  }
  return (
    <Link to={href} onClick={onClick} className={className}>
      {children}
    </Link>
  );
}
