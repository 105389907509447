import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { CD } from '../../../constants/imageConstants';
import { useQuery } from '../../../graphql/client';
import { AnnouncementSourceType, ContentToShareDocument } from '../../../graphql/generated';
import { VaultThemeStore } from '../../../hooks/useVaultTheme';
import { generateShareLink } from '../../../utils/linkUtils';
import { Image } from '../../common/Image';
import { View } from '../../common/View';
import { ErrorView } from '../../error/ErrorView';
import { FullPageLoading } from '../../views/FullPageLoading';
import { BaseShareView } from './BaseShareView';

gql(/* GraphQL */ `
  query ContentToShare($linkValue: String!, $artistHandle: String!) {
    vaultContentBySlug(slug: $linkValue, artistHandle: $artistHandle) {
      __typename
      ... on QueryVaultContentBySlugSuccess {
        data {
          __typename
          id
          title
          ... on VaultImage {
            uploadedMedia {
              id
              imageOptimizedUrl(input: { width: 480, height: 480 })
            }
          }
          ... on VaultVideo {
            uploadedMedia {
              id
              videoScreenshotUrl
            }
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

export function ShareContentView({
  linkValue,
  artistHandle,
  artistName,
}: {
  linkValue: string;
  artistHandle: string;
  artistName: string;
}) {
  const { data, isLoading, isError, refetch } = useQuery(ContentToShareDocument, {
    staleTime: 0,
    variables: {
      linkValue,
      artistHandle,
    },
    select: data =>
      data.data.vaultContentBySlug?.__typename === 'QueryVaultContentBySlugSuccess'
        ? data.data.vaultContentBySlug.data
        : null,
  });

  const shareLink = useMemo(() => {
    const firstPath = (() => {
      if (data == null) {
        return 't';
      }

      switch (data?.__typename) {
        case 'VaultTrack':
          return 't';
        case 'VaultVideo':
          return 'v';
        case 'VaultFolder':
          return 'f';
        case 'VaultImage':
          return 'i';
      }
    })();

    return generateShareLink({
      artistLinkValue: artistHandle,
      path: `/${firstPath}/${linkValue}`,
      inviteCode: null,
    });
  }, [artistHandle, data, linkValue]);

  if (isError) {
    return <ErrorView onRetryClick={refetch} withVaultTheme className="md2:w-full" />;
  }

  if (data == null || isLoading) {
    return <FullPageLoading withVaultTheme />;
  }

  const { title } = data;

  const vaultContentTypeText = (() => {
    switch (data.__typename) {
      case 'VaultFolder':
        return 'folder';
      case 'VaultImage':
        return 'image';
      case 'VaultTrack':
        return 'track';
      case 'VaultVideo':
        return 'video';
    }
  })();

  const sourceType = (() => {
    switch (data.__typename) {
      case 'VaultFolder':
        return AnnouncementSourceType.VaultContentFolder;
      case 'VaultTrack':
        return AnnouncementSourceType.VaultContentTrack;
      case 'VaultImage':
      case 'VaultVideo':
        return AnnouncementSourceType.VaultContentMedia;
    }
  })();

  const imageUrl = (() => {
    switch (data.__typename) {
      case 'VaultImage':
        return data.uploadedMedia?.imageOptimizedUrl;
      case 'VaultVideo':
        return data.uploadedMedia?.videoScreenshotUrl;
      default:
        return null;
    }
  })();

  const prefilledMessage = (() => {
    switch (data.__typename) {
      case 'VaultTrack':
        return `Hey, I just dropped a new track. Let me know what you think.`;
      case 'VaultVideo':
        return `Hey, I just dropped a new video. Let me know what you think.\n\n${shareLink}`;
      case 'VaultImage':
        return `Hey, I just dropped a new image. Let me know what you think.\n\n${shareLink}`;
      case 'VaultFolder':
        return `I just added new content to this folder in my Vault. Let me know what you think.\n\n${shareLink}`;
    }
  })();

  return (
    <BaseShareView
      attachedTrackId={data.__typename === 'VaultTrack' ? data.id : null}
      title={title ?? 'Untitled'}
      artistHandle={artistHandle}
      artistName={artistName}
      contentTypeText={vaultContentTypeText}
      sourceId={data.id}
      sourceType={sourceType}
      imageElement={<ImageElement imageUrl={imageUrl} __typename={data.__typename} />}
      url={shareLink}
      prefilledMessage={prefilledMessage}
    />
  );
}

function ImageElement({
  imageUrl,
  __typename,
}: {
  imageUrl: string | null | undefined;
  __typename: 'VaultImage' | 'VaultVideo' | 'VaultFolder' | 'VaultTrack';
}) {
  const VaultThemeSnapshot = useSnapshot(VaultThemeStore);

  if (__typename === 'VaultFolder') {
    return (
      <View
        className={twMerge(
          'flex h-[240px] w-[240px] flex-col items-center justify-center rounded-xl bg-vault_text/5',
        )}
      >
        <View
          className={twMerge(
            'flex h-[111px] w-[114px] items-center justify-center bg-contain bg-no-repeat',
            VaultThemeSnapshot.mode === 'light'
              ? 'bg-folder-background-black-no-icon'
              : 'bg-folder-background-white',
          )}
        />
      </View>
    );
  }

  if (__typename === 'VaultTrack') {
    return (
      <View className="relative flex h-[240px] w-[240px] flex-col items-center justify-center overflow-hidden rounded-[6px] bg-[#181818]">
        <Image
          src={CD}
          alt="Vault image"
          className="absolute h-[287px] w-[287px] translate-y-[120px]"
        />
      </View>
    );
  }

  return (
    <View className="h-[240px] w-[240px] overflow-hidden rounded-xl bg-vault_text/5">
      {!!imageUrl && (
        <Image src={imageUrl} alt="Vault image" className="h-full w-full object-cover" />
      )}
    </View>
  );
}
