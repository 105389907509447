import { useMemo } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { BackButton } from '../../../../components/buttons/BackButton';
import { Button } from '../../../../components/buttons/Button';
import { Text } from '../../../../components/common/Text';
import { View } from '../../../../components/common/View';
import { DefaultLayout } from '../../../../components/layouts/DefaultLayout';
import { LoadingSkeleton } from '../../../../components/loading/LoadingSkeleton';
import { ReleaseView } from '../../../../components/rsvp/ReleaseView';
import { RsvpDropView } from '../../../../components/rsvp/RsvpDropView';
import { RsvpHowItWorksView } from '../../../../components/rsvp/RsvpHowItWorksView';
import { SetupView } from '../../../../components/rsvp/SetupView';
import { RSVPEventSteps } from '../../../../components/rsvp/schema';
import { useRsvpEventForm } from '../../../../components/rsvp/useRsvpEventForm';
import { ROUTES } from '../../../../constants/routeConstants';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../../contexts/BottomsheetContext';
import { useArtistHandle } from '../../../../hooks/useArtistHandle';
import { useStableCallback } from '../../../../hooks/useStableCallback';
import { useVaultTheme } from '../../../../hooks/useVaultTheme';
import { LoginStatus } from '../../../../types/authTypes';
import { getFromList } from '../../../../utils/arrayUtils';
import { artistNavigationPath } from '../../../../utils/navigationUtils';

export function CreateRsvpPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { loggedInUser, loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();
  const { openBottomsheet } = useBottomsheetContainer();

  useVaultTheme();

  const {
    isSetupComplete,
    isReleaseComplete,
    enableSubmit,
    clearErrors,
    clearFields,
    determineNextStep,
    determinePrevStep,
    onSubmit,
    fields,
  } = useRsvpEventForm();

  const step = searchParams.get('step') || RSVPEventSteps.Intro;

  const adminArtist = useMemo(() => {
    if (!artistHandle) return undefined;
    return getFromList(loggedInUser?.adminArtists, artist => {
      const isMatch = artist.artistLinks?.includes(artistHandle) ?? false;
      return isMatch ? artist : false;
    });
  }, [loggedInUser?.adminArtists, artistHandle]);

  const artistId = adminArtist?.artistId;
  const artistName = adminArtist?.artistName;

  const onBackClick = useStableCallback(() => {
    const prevStep = determinePrevStep(step as RSVPEventSteps);
    if (prevStep) {
      setSearchParams({ step: prevStep });
    } else if (step === RSVPEventSteps.Intro) {
      navigate(artistNavigationPath(artistHandle, '/drops'));
    } else {
      openBottomsheet({
        type: 'EXIT_FLOW',
        exitFlowBottomsheetProps: {
          onConfirm: () => {
            navigate(artistNavigationPath(artistHandle, '/drops'));
            clearFields();
            clearErrors();
          },
        },
      });
    }
  });

  const handleNextClick = () => {
    const currentStep = step as RSVPEventSteps;
    const nextStep = determineNextStep(currentStep);
    if (nextStep && nextStep !== step) {
      setSearchParams({ step: nextStep });
    } else if (currentStep === RSVPEventSteps.Preview && enableSubmit && artistId) {
      onSubmit(artistId);
    }
  };

  const buttonText = useMemo(() => {
    switch (step) {
      case RSVPEventSteps.Intro:
        return 'Get started';
      case RSVPEventSteps.Setup:
      case RSVPEventSteps.Release:
        return 'Next';
      case RSVPEventSteps.Preview:
        return 'Publish drop';
      default:
        return null;
    }
  }, [step]);

  const isButtonDisabled = useMemo(() => {
    if (step === RSVPEventSteps.Intro) {
      return false;
    }
    if (step === RSVPEventSteps.Setup) {
      return !isSetupComplete;
    }
    if (step === RSVPEventSteps.Release) {
      return !isReleaseComplete;
    }
    return !enableSubmit;
  }, [enableSubmit, isReleaseComplete, isSetupComplete, step]);

  const content = useMemo(() => {
    switch (step) {
      case RSVPEventSteps.Intro:
        return <RsvpHowItWorksView />;
      case RSVPEventSteps.Setup:
        return <SetupView artistId={artistId} artistName={artistName} isEditMode={false} />;
      case RSVPEventSteps.Release:
        return <ReleaseView artistName={artistName} />;
      case RSVPEventSteps.Preview:
        return adminArtist && artistId ? (
          <RsvpDropView artistId={artistId} fields={fields} isPreview />
        ) : null;
      default:
        return <Navigate to={ROUTES.NOT_FOUND} />;
    }
  }, [adminArtist, artistId, artistName, fields, step]);

  const ctaButton = (
    <View
      className={twMerge(
        'sticky z-mobileNav flex h-[68px] max-h-[68px] w-full items-center justify-center border-0 border-t border-solid py-2',
        'border-t-vault_text/5 bg-vault_background',
      )}
    >
      <Button
        type="primary-themed"
        label={buttonText}
        className="mx-4 w-full self-center md2:mx-6"
        disabled={isButtonDisabled}
        disabledClassName="opacity-50 cursor-not-allowed"
        onClick={handleNextClick}
      />
    </View>
  );

  if (!artistHandle || (!artistId && loginStatus !== LoginStatus.LOADING)) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      headerLeft={
        <BackButton
          onClick={step === RSVPEventSteps.Intro ? undefined : onBackClick}
          className={step !== RSVPEventSteps.Preview ? 'text-vault_text' : undefined}
        />
      }
      headerCenter={
        <>
          {step === RSVPEventSteps.Preview && (
            <Text className="font-title !text-title-m font-medium text-white">Drop preview</Text>
          )}
          {step === RSVPEventSteps.Intro && (
            <Text className="font-title !text-title-m font-medium text-vault_text">
              How it works
            </Text>
          )}
        </>
      }
      shouldSkipMargin
      childrenWrapperClassName="h-full"
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      customBottomNavigator={<View className="w-full md2:hidden">{ctaButton}</View>}
      headerClassName="grow-0"
      contentClassName={twMerge(
        'flex-1',
        step !== RSVPEventSteps.Preview ? 'md2:bg-vault_text/3' : 'bg-black',
      )}
      extend={step === RSVPEventSteps.Preview || step === RSVPEventSteps.Intro}
      footer={<View className="hidden w-full md2:block">{ctaButton}</View>}
    >
      <View className="flex h-full w-full flex-1 flex-col">
        <View
          className={twMerge(
            step !== RSVPEventSteps.Preview
              ? 'px-4 md2:px-6'
              : 'relative box-border overflow-clip pb-8',
            step === RSVPEventSteps.Intro && 'flex flex-1',
          )}
        >
          {content}
        </View>
      </View>
    </DefaultLayout>
  );
}

export const RsvpSkeleton = ({ onBackClick }: { onBackClick: () => void }) => {
  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      headerLeft={<BackButton onClick={onBackClick} className="text-vault_text" />}
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">Drop Setup</Text>
      }
      childrenWrapperClassName="h-full"
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5"
      contentClassName="md2:bg-vault_text/3"
      stretch
    >
      <View className="flex h-full w-full flex-col justify-between py-6 md2:max-w-[600px]">
        <View className="flex h-full w-full flex-col gap-6 md2:gap-7">
          <View className="flex h-full w-full flex-col">
            <LoadingSkeleton className="aspect-square w-full rounded-lg bg-vault_text/10" />

            <LoadingSkeleton className={twMerge('mt-4 h-[30px] w-[150px]', 'bg-vault_text/10')} />
            <LoadingSkeleton className={twMerge('mt-4 h-[30px] w-full', 'bg-vault_text/10')} />

            <LoadingSkeleton className={twMerge('mt-4 h-[30px] w-[150px]', 'bg-vault_text/10')} />
            <LoadingSkeleton className={twMerge('mt-4 h-[30px] w-full', 'bg-vault_text/10')} />
          </View>
        </View>
      </View>
    </DefaultLayout>
  );
};
