import { throttle } from 'lodash-es';
import { z } from 'zod';
import { ERROR_TYPE, PILLARS } from '@soundxyz/vault-utils/dist/constants';
import { COUNTRY_CODES } from '../constants/phoneConstants';
import { IsoCountry, IsousState } from '../graphql/generated';
import { logError } from '../hooks/logger/useLogError';
import { getManyFromList } from './arrayUtils';
import { PersistenceStorage } from './storeUtils';

export const GEO_CODER_CACHE = PersistenceStorage({
  schema: z.record(z.string(), z.custom<google.maps.GeocoderResponse['results'][number]>()),
  key: 'geocoder-cache',
});

export async function findOrFetchGeocoderResult({
  cacheKey,
  geocoder,
  ...rest
}: {
  cacheKey: string;
  geocoder: google.maps.Geocoder;
} & (
  | {
      type: 'COORDINATE';
      coordinate: { lat: number; lng: number };
    }
  | {
      type: 'ADDRESS';
      address: string;
    }
  | { type: 'PLACE_ID'; placeId: string }
)) {
  const cache = await GEO_CODER_CACHE.get();

  const cachedResult = cache?.[cacheKey];

  if (cachedResult != null) {
    return cachedResult;
  }

  const input =
    rest.type === 'COORDINATE'
      ? { location: rest.coordinate }
      : rest.type === 'PLACE_ID'
        ? { placeId: rest.placeId }
        : { address: rest.address };

  const response = await geocoder.geocode(input).catch(e => {
    logError({
      error: e,
      errorType: ERROR_TYPE.UNKNOWN,
      message: 'Error geocoding address',
      level: 'error',
      action: 'MISCELLANEOUS_ERROR',
      unindexedExtra: {
        input,
      },
      pillar: PILLARS.TEXT_BLAST,
    });

    return null;
  });

  const returnedResult = response?.results[0];

  if (returnedResult == null) {
    return null;
  }

  GEO_CODER_CACHE.produceExistingState(
    state => ({
      ...state,
      [cacheKey]: returnedResult,
    }),
    { [cacheKey]: returnedResult },
  );

  return returnedResult;
}

export function getCoordinateCacheKey(
  location: { lat: number; lng: number } | { latitude: number; longitude: number },
) {
  if ('lat' in location && 'lng' in location) {
    return `${Math.round(location.lat * 100)},${Math.round(location.lng * 100)}`;
  }

  return `${Math.round(location.latitude * 100)},${Math.round(location.longitude * 100)}`;
}

export const findAddress = throttle(
  async ({
    geocoder,
    location,
    setAddress,
    setCountryCode,
  }: {
    geocoder: google.maps.Geocoder;
    location: { lat: number; lng: number };
    setAddress: (address: string) => void;
    setCountryCode: (countryCode: IsoCountry | null) => void;
  }) => {
    const result = await findOrFetchGeocoderResult({
      geocoder,
      type: 'COORDINATE',
      coordinate: location,
      cacheKey: getCoordinateCacheKey(location),
    });

    if (result != null) {
      const filteredResults = result.address_components.filter(
        ({ types }) =>
          types.includes('country') ||
          types.includes('administrative_area_level_1') ||
          types.includes('sublocality') ||
          types.includes('locality'),
      );

      const containsSublocality = filteredResults?.some(r => r?.types.includes('sublocality'));

      const country = filteredResults?.find(r => r?.types.includes('country'));
      const adjustedCountryName =
        (country?.short_name.at(0)?.toUpperCase() ?? '') +
        (country?.short_name.at(1)?.toLowerCase() ?? '');

      const displayAddress = getManyFromList(filteredResults ?? [], r => {
        if (r.types.includes('administrative_area_level_1')) {
          return r.short_name;
        }

        if (r.types.includes('locality') && containsSublocality) {
          return null;
        }

        return r.long_name;
      }).join(', ');

      if (displayAddress != null) {
        setAddress(displayAddress);
      }
      if (adjustedCountryName != null && adjustedCountryName in IsoCountry) {
        setCountryCode(IsoCountry[adjustedCountryName as keyof typeof IsoCountry]);
      } else {
        setCountryCode(null);
      }
    }
  },
  1000,
);

export async function getCountryInfo({
  countryCacheKey,
  geocoder,
  ...rest
}: { geocoder: google.maps.Geocoder } & (
  | {
      countryCacheKey: string;
      latitude: number;
      longitude: number;
      type: 'GEO';
    }
  | { type: 'REGION'; countryCacheKey: string; address: string }
  | { type: 'PLACE_ID'; countryCacheKey: string; placeId: string }
)) {
  const input =
    rest.type === 'GEO'
      ? { type: 'COORDINATE' as const, coordinate: { lat: rest.latitude, lng: rest.longitude } }
      : rest.type === 'PLACE_ID'
        ? { type: 'PLACE_ID' as const, placeId: rest.placeId }
        : { type: 'ADDRESS' as const, address: rest.address };

  const foundAddress = await findOrFetchGeocoderResult({
    ...input,
    cacheKey: countryCacheKey,
    geocoder,
  });

  const country = foundAddress?.address_components.find(component =>
    component.types.includes('country'),
  )?.short_name;

  if (country == null) {
    return { countryInfo: null, stateCode: null, countryCacheKey };
  }

  const stateCode = foundAddress?.address_components.find(component =>
    component.types.includes('administrative_area_level_1'),
  )?.short_name;

  const countryInfo = COUNTRY_CODES.find(c => c.code === country);

  return {
    countryInfo,
    stateCode: stateCode != null && isIsoState(stateCode) ? stateCode : null,
    countryCacheKey,
  };
}

export const isIsoCountry = (value: string): value is IsoCountry => {
  const changedValue = (value.at(0) ?? '') + (value.at(1)?.toLowerCase() ?? '');

  return changedValue in IsoCountry;
};

export const isIsoState = (value: string): value is IsousState => {
  const changedValue = (value.at(0) ?? '') + (value.at(1)?.toLowerCase() ?? '');

  return changedValue in IsousState;
};
