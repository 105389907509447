import type { FC } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { startSubscriptionPaymentIntent } from '../../providers/StripeProvider';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { Button } from './Button';

type Props = {
  label?: string;
  className?: string;
  linkClassName?: string;
  linkValue: string;
  artistAvatarUrl?: string | null;
  disabled?: boolean;
  price: number;
  vaultId: string;
  showBottomSheet: boolean;
  code?: string;
  smsCampaignResponseShortcode?: string | null;
  component:
    | 'vault_page'
    | 'audio_player'
    | 'locked_chat'
    | 'all_access_pass'
    | 'referral_page'
    | 'locked_chat_write_access'
    | 'locked_track_comment_write_access';
  onClick?: () => void;
};

export const SubscribeButton: FC<Props> = ({
  label = 'Subscribe',
  linkValue,
  className,
  linkClassName,
  artistAvatarUrl,
  disabled,
  price,
  vaultId,
  showBottomSheet,
  code,
  smsCampaignResponseShortcode,
  component,
  onClick,
}) => {
  const navigate = useNavigate();
  const { loginStatus } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();

  const handleConfirm = () => {
    const queryParams = constructQueryParams({
      artistHandle: linkValue,
      redirect: null,
      code,
      c: smsCampaignResponseShortcode,
    });
    if (loginStatus !== LoginStatus.LOGGED_IN) {
      navigate(`${ROUTES.SIGN_IN}?${queryParams}`);
    } else {
      navigate(artistNavigationPath(linkValue, '/subscribe', queryParams));
    }
  };
  return (
    <Button
      label={label}
      className={className}
      type="primary"
      labelClassName="text-center"
      onClick={() => {
        if (loginStatus === LoginStatus.LOGGED_IN) {
          startSubscriptionPaymentIntent({ vaultId, referralCode: code || null, openToast: null });
        }
        if (showBottomSheet) {
          openBottomsheet({
            type: BOTTOMSHEET_TYPES.SUBSCRIBE_WELCOME_MESSAGE,
            subscribeWelcomeMessageBottomsheetProps: {
              monthlySubPrice: `$${price}`,
              artistAvatarUrl,
              onConfirm: handleConfirm,
              artistHandle: linkValue,
              isJoiningFreeTier: false,
            },
            shared: {
              hideCloseBottomsheetButton: true,
            },
          });
        } else {
          handleConfirm();
        }
        if (onClick) onClick();
      }}
      linkClassName={linkClassName}
      disabled={disabled}
      event={{
        type: EVENTS.OPEN_BOTTOMSHEET,
        properties: {
          bottomsheetType: BOTTOMSHEET_TYPES.SUBSCRIBE_WELCOME_MESSAGE,
          monthlySubPrice: `${price}`,
          artistHandle: linkValue,
          isJoiningFreeTier: false,
          loginStatus,
          code: code || null,
          component,
        },
      }}
    />
  );
};
