import { useEffect, useMemo, useRef } from 'react';
import { useLoginWithSms } from '@privy-io/react-auth';
import parsePhoneNumberFromString, {
  type CountryCode,
  isValidPhoneNumber,
} from 'libphonenumber-js/min';
import { AUTH_ERROR_ACTIONS, ERROR_TYPE, PILLARS } from '@soundxyz/vault-utils/dist/constants';
import { COUNTRY_CODES } from '../constants/phoneConstants';
import { SignInStore, THIRTY_SECONDS_IN_MS } from '../screens/auth/store';
import { EVENTS } from '../types/eventTypes';
import { trackEvent } from '../utils/analyticsUtils';
import { wrapSendCode } from '../utils/privy';
import { useLogError } from './logger/useLogError';
import { useCurrentArtistHandle } from './useArtistHandle';
import { useStableCallback } from './useStableCallback';

export function useSignIn() {
  const signInState = SignInStore.useStore({
    sync: true,
  }).value;
  const logError = useLogError();
  const phone = signInState?.phone ?? '';
  const setPhone = SignInStore.extra.setPhone;

  const { sendCode } = useLoginWithSms();

  const countryCode = signInState?.countryCode ?? 'US';

  const lastCountryCode = signInState?.lastCountryCode;
  const setCountryCode = SignInStore.extra.setCountryCode;

  const errorText = signInState?.errorText;
  const setErrorText = SignInStore.extra.setErrorText;

  const isOpen = signInState?.isOpen;
  const setIsOpen = SignInStore.extra.setIsOpen;

  useEffect(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const { artistHandle } = useCurrentArtistHandle();

  useEffect(() => {
    if (lastCountryCode != null) return;

    const intlCountry = Intl.DateTimeFormat().resolvedOptions().locale.split('-')[1];

    if (!intlCountry) return;

    const countryCode = COUNTRY_CODES.find(({ code }) => code === intlCountry)?.code ?? 'US';

    setCountryCode(countryCode);
  }, [lastCountryCode, setCountryCode]);

  useEffect(() => {
    if (countryCode === SignInStore.state.value?.lastCountryCode) return;

    SignInStore.produceExistingState(
      draft => {
        draft.lastCountryCode = countryCode;
      },
      {
        codeRenabled: {},
        lastActivePhoneNumber: null,
        lastCountryCode: countryCode,
      },
    );
  }, [countryCode]);

  const validPhoneNumber = useMemo(() => {
    const phoneNumber = parsePhoneNumberFromString(phone, countryCode as CountryCode)?.number;

    if (
      phoneNumber == null ||
      (!isValidPhoneNumber(phone, countryCode as CountryCode) && phoneNumber !== '+15555555555')
    ) {
      return null;
    }

    return phoneNumber;
  }, [phone, countryCode]);

  const codeRenabled = signInState?.codeRenabled[validPhoneNumber ?? '_'] ?? 1;
  const lastActivePhoneNumber = signInState?.lastActivePhoneNumber;

  const attemptNumber = useRef(0);

  const onSignInClick = useStableCallback(
    async ({ onSuccess, source }: { onSuccess?: () => void; source: string }) => {
      attemptNumber.current++;

      if (!validPhoneNumber) {
        trackEvent({
          type: EVENTS.SET_PHONE_NUMBER_ERROR,
          properties: {
            artistHandle,
            error_type: 'Invalid phone number',
            phone_number: phone,
            attempt_num: attemptNumber.current,
          },
        });
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: new Error('Invalid phone number'),
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'log',
          message: 'Invalid phone number',
          pillar: PILLARS.AUTH,
          indexedTags: {
            phoneNumber: phone,
          },
          unindexedExtra: {
            source,
          },
        });
        setErrorText('This phone number cannot be used for verification');
        return;
      }

      try {
        setErrorText(null);
        await wrapSendCode(() => sendCode({ phoneNumber: validPhoneNumber }));

        trackEvent({
          type: EVENTS.RECEIVE_VERIFICATION_CODE,
          properties: {
            artistHandle,
            phone_number: validPhoneNumber,
          },
        });
        attemptNumber.current = 0;

        const codeRenabled = Date.now() + THIRTY_SECONDS_IN_MS;

        SignInStore.produceExistingState(
          draft => {
            draft.lastActivePhoneNumber = validPhoneNumber;
            draft.codeRenabled[validPhoneNumber] = codeRenabled;
            draft.phone = '';
          },
          {
            codeRenabled: {
              [validPhoneNumber]: codeRenabled,
            },
            lastActivePhoneNumber: validPhoneNumber,
          },
        );

        onSuccess?.();
      } catch (e) {
        trackEvent({
          type: EVENTS.SET_PHONE_NUMBER_ERROR,
          properties: {
            artistHandle,
            error_type: 'get_verification_code_error',
            phone_number: phone,
            attempt_num: attemptNumber.current,
          },
        });
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: e,
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'warning',
          message: 'We encountered an error sending your verification code',
          pillar: PILLARS.AUTH,
          indexedTags: {
            phoneNumber: validPhoneNumber,
          },
          unindexedExtra: {
            source,
          },
        });

        setErrorText('We encountered an error sending your verification code');
        return;
      }
    },
  );

  const onResendCodeClick = useStableCallback(
    async ({
      source,
      onSuccess,
      codeSentDisabledSecondsRemaining = 0,
    }: {
      codeSentDisabledSecondsRemaining: number;
      source: string;
      onSuccess?: () => void;
    }) => {
      trackEvent({
        type: EVENTS.CLICK_RESEND_VERIFICATION_CODE,
        properties: {
          artistHandle,
          phone_number: phone,
        },
      });
      if (!validPhoneNumber) {
        trackEvent({
          type: EVENTS.SET_PHONE_NUMBER_ERROR,
          properties: {
            artistHandle,
            error_type: 'Invalid phone number',
            phone_number: phone,
            attempt_num: attemptNumber.current,
          },
        });
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: new Error('Invalid phone number'),
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'log',
          message: 'Invalid phone number',
          pillar: PILLARS.AUTH,
          indexedTags: {
            phoneNumber: phone,
          },
          unindexedExtra: {
            source,
          },
        });
        setErrorText('This phone number cannot be used for verification');
        return;
      }

      if (codeSentDisabledSecondsRemaining > 0) return;

      trackEvent({
        type: EVENTS.CLICK_RESEND_VERIFICATION_CODE,
        properties: {
          artistHandle,
          phone_number: validPhoneNumber,
        },
      });

      try {
        await wrapSendCode(() => sendCode({ phoneNumber: `+${validPhoneNumber}` }));

        const codeRenabled = Date.now() + THIRTY_SECONDS_IN_MS;
        SignInStore.produceExistingState(
          draft => {
            draft.codeRenabled[validPhoneNumber] = codeRenabled;
            draft.lastActivePhoneNumber = validPhoneNumber;
          },
          {
            codeRenabled: { [validPhoneNumber]: codeRenabled },
            lastActivePhoneNumber: validPhoneNumber,
          },
        );
        onSuccess?.();
      } catch (e) {
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: e,
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'warning',
          message: 'We encountered an error re-sending your verification code',
          pillar: PILLARS.AUTH,
          indexedTags: {
            phoneNumber: validPhoneNumber,
          },
          unindexedExtra: {
            source,
          },
        });
        setErrorText('We encountered an error re-sending your verification code');

        return;
      }
    },
  );

  return {
    countryCode,
    errorText,
    isOpen,
    phone,
    setCountryCode,
    setErrorText,
    setIsOpen,
    setPhone,
    validPhoneNumber,
    codeRenabled,
    lastActivePhoneNumber,
    onSignInClick,
    onResendCodeClick,
  };
}
