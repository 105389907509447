import { gql } from '@soundxyz/gql-string';
import {
  ERROR_TYPE,
  type ErrorType,
  PILLARS,
  SUBSCRIPTION_ERROR_ACTIONS,
  SUBSCRIPTION_INFO_ACTIONS,
} from '@soundxyz/vault-utils/constants';
import { useToast } from '../contexts/ToastContext';
import { useMutation } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  ArtistByHandleDocument,
  ArtistMainVaultViewFragmentDoc,
  GetActiveVaultsSubscriptionsDocument,
  GetArtistInvitesPageDocument,
  GetArtistsToExploreDocument,
  GetMessageChannelDocument,
  GetPinnedMessagesDocument,
  GetPriorityActiveVaultSubscriptionsDocument,
  GetUserChannelsDocument,
  SubscribeFreeTierDocument,
  TopVaultInvitersDocument,
  UserArtistMembershipDocument,
  UserArtistReceiptsDocument,
  UserVaultInviteStatsDocument,
  VaultContentByFolderDocument,
  VaultContentByFolderPositionDocument,
  VaultContentByIdDocument,
  VaultContentBySlugDocument,
  VaultContentPaginationDocument,
} from '../graphql/generated';
import { EVENTS } from '../types/eventTypes';
import { trackEvent } from '../utils/analyticsUtils';
import { logError } from './logger/useLogError';
import { logInfo } from './logger/useLogInfo';

gql(/* GraphQL */ `
  mutation SubscribeFreeTier($input: MutationSubscribeToVaultFreeInput!) {
    subscribeToVaultFree(input: $input) {
      __typename
      ... on MutationSubscribeToVaultFreeSuccess {
        data {
          id
          status
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [SubscribeFreeTierDocument],
  refetch: [
    ArtistByHandleDocument,
    VaultContentPaginationDocument,
    ArtistMainVaultViewFragmentDoc,
    GetActiveVaultsSubscriptionsDocument,
    GetArtistsToExploreDocument,
    GetPriorityActiveVaultSubscriptionsDocument,
    GetArtistInvitesPageDocument,
    UserVaultInviteStatsDocument,
    TopVaultInvitersDocument,
    VaultContentByFolderDocument,
    VaultContentByFolderPositionDocument,
    VaultContentByIdDocument,
    VaultContentBySlugDocument,
    UserArtistMembershipDocument,
    UserArtistReceiptsDocument,
    GetMessageChannelDocument,
    GetPinnedMessagesDocument,
    GetUserChannelsDocument,
  ],
  throttled: '500ms',
});

const pillar = PILLARS.SUBSCRIPTION;
export function useFreeTier() {
  const { openToast } = useToast();

  const { mutateAsync: subscribeFreeTier, isLoading: isSubscribingFreeTier } = useMutation(
    SubscribeFreeTierDocument,
    {
      retry: 3,
      onSuccess: data => {
        if (data.data.subscribeToVaultFree.__typename === 'MutationSubscribeToVaultFreeSuccess') {
          trackEvent({
            type: EVENTS.SUBSCRIBE_FREE_TIER_SUCCESS,
            properties: null,
          });
          logInfo({
            action: SUBSCRIPTION_INFO_ACTIONS.SUBSCRIPTION_SUCCESS,
            message: 'User successfully subscribed to free tier',
            pillar,
            data: {
              id: data.data.subscribeToVaultFree.data.id,
              status: data.data.subscribeToVaultFree.data.status,
            },
          });
          openToast({
            text: 'You have successfully subscribed to this vault!',
            variant: 'success',
          });
        } else {
          let errType: ErrorType = ERROR_TYPE.UNKNOWN;
          switch (data.data.subscribeToVaultFree.__typename) {
            case 'NotAuthorizedError':
              errType = ERROR_TYPE.AUTH_ERROR;
              break;
            case 'NotFoundError':
              errType = ERROR_TYPE.NOT_FOUND_ERROR;
              break;
            default:
              break;
          }
          logError({
            action: SUBSCRIPTION_ERROR_ACTIONS.SUBSCRIPTION_ERROR,
            error: new Error(data.data.subscribeToVaultFree.message),
            level: 'warning',
            message: data.data.subscribeToVaultFree.message,
            errorType: errType,
            pillar,
            indexedTags: {
              source: 'useFreeTier',
            },

            openToast,
            toast: 'An error occured subscribing to the free tier. Please try again.',
          });
        }
      },
      onError: error => {
        logError({
          action: SUBSCRIPTION_ERROR_ACTIONS.SUBSCRIPTION_ERROR,
          error: error,
          level: 'error',
          message: 'Error subscribing to free tier',
          errorType: ERROR_TYPE.UNKNOWN,
          pillar,
          indexedTags: {
            source: 'useFreeTier',
          },
          openToast,
          toast: 'An error occured subscribing to the free tier. Please try again.',
        });
      },
    },
  );

  return { subscribeFreeTier, isSubscribingFreeTier };
}
