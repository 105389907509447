import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faMusic, faPlus, type IconDefinition } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faStar, faTicket, faTshirt } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { Text } from '../../components/common/Text';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { ThirdPartyPlatform } from '../../graphql/generated';
import { useBetterGate } from '../../hooks/useFeatureGate';
import { useStableCallback } from '../../hooks/useStableCallback';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { getDSPName } from '../campaign/helpers';
import { CampaignType } from '../campaign/schema';
import { useCampaignForm } from '../campaign/useCampaignForm';
import { View } from '../common/View';
import { useRsvpEventForm } from '../rsvp/useRsvpEventForm';

export const CreateButton = ({
  artistHandle,
  iconOnly = false,
  className,
  icon = faPlus,
}: {
  artistHandle: string | undefined;
  iconOnly?: boolean;
  className?: string;
  icon?: IconDefinition;
}) => {
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();
  const { setField: setCampaignField, clearFields: resetCampaignForm } = useCampaignForm();
  const { clearAllFields: resetRsvpForm } = useRsvpEventForm();

  const presaves = useBetterGate('PRESAVES');

  const presavesEnabled = presaves === 'enabled';

  const initializeCampaign = useStableCallback((campaignType: CampaignType) => {
    resetCampaignForm();
    setCampaignField('campaignType', campaignType);
    setCampaignField('dsps', [
      {
        key: ThirdPartyPlatform.Spotify,
        name: getDSPName(ThirdPartyPlatform.Spotify),
        uri: '',
        buttonText: 'Play',
      },
      {
        key: ThirdPartyPlatform.AppleMusic,
        name: getDSPName(ThirdPartyPlatform.AppleMusic),
        uri: '',
        buttonText: 'Play',
      },
    ]);
    // Add this condition to set presave fields
    if (campaignType === CampaignType.Presave) {
      setCampaignField('showAppleMusicPresave', true);
      setCampaignField('showSpotifyPresave', true);
    }
  });

  const createRsvp = useStableCallback(() => {
    if (!artistHandle) return;
    trackEvent({
      type: EVENTS.START_RSVP_CREATE,
      properties: {
        artistHandle,
      },
    });
    resetRsvpForm();
    navigate(artistNavigationPath(artistHandle, '/rsvp/create'));
    closeBottomsheet();
  });

  const onPlusClick = useStableCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    ev.stopPropagation();

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CUSTOM,
      shared: {
        withVaultTheme: true,
      },
      customBottomsheetProps: {
        body: (
          <View className="flex flex-col items-center gap-0.5">
            <Text className="pb-10 font-title text-title-xl text-vault_text">Select drop type</Text>
            <CreateItem
              title="Drop tickets"
              icon={faTicket}
              description="Create RSVP campaigns to let your fans secure spots for your next live events"
              onClick={createRsvp}
              className="rounded-t-xl"
            />
            <CreateItem
              title="Drop merch"
              icon={faTshirt}
              description="Launch exclusive merch drops and allow fans to RSVP for early access"
              onClick={createRsvp}
            />
            {presavesEnabled && (
              <CreateItem
                title="Drop a song"
                icon={faMusic}
                description="Build hype for your upcoming release by collecting pre-saves and driving streams"
                onClick={() => {
                  if (!artistHandle) return;
                  initializeCampaign(CampaignType.Presave);
                  navigate(artistNavigationPath(artistHandle, '/campaign/create'));
                  closeBottomsheet();
                }}
              />
            )}
            <CreateItem
              title="Create custom drop"
              icon={faStar}
              description="Design announcements tailored to your needs. Share special offers or exclusive content"
              onClick={createRsvp}
            />
          </View>
        ),
      },
    });
  });

  return (
    <button
      onClick={onPlusClick}
      className={twMerge(
        'flex h-12 max-h-12 min-h-12 w-12 cursor-pointer flex-row items-center justify-center gap-3 rounded-full border-none !font-base !text-[16px] !font-medium outline-none transition-all duration-500 ease-in-out hover:opacity-90 focus:outline-none md2:justify-start md2:p-3',
        'bg-vault_accent text-vault_accent_text',
        iconOnly && 'md2:w-12 md2:max-w-12',
        className,
      )}
    >
      <FontAwesomeIcon
        className={twMerge('text-[18px] md2:mx-2 md2:text-[20px]', iconOnly && 'md2:mx-1')}
        icon={icon}
      />
      {!iconOnly && <Text>Create</Text>}
    </button>
  );
};

export const CreateItem = ({
  title,
  icon,
  description,
  onClick,
  className,
}: {
  title: string;
  icon: IconDefinition;
  description: string;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <View
      className={twMerge(
        'box-border flex w-full cursor-pointer flex-row items-center gap-4 self-stretch rounded-b-none border-none p-4 outline-none',
        'bg-vault_text/10 text-vault_text hover:bg-vault_text/20',
        className,
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon className="aspect-square" icon={icon} fontSize={24} />
      <View className="flex flex-col">
        <Text className="text-title-s font-medium text-vault_text">{title}</Text>
        <Text className="text-base-m font-normal text-vault_text/40">{description}</Text>
      </View>
    </View>
  );
};
