import type { ReactNode, RefObject } from 'react';
import React, { useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import type { MotionValue } from 'framer-motion';
import { motion, type PanInfo, useMotionValue, useTransform } from 'framer-motion';
import { keyBy } from 'lodash-es';
import { twMerge } from 'tailwind-merge';
import { useLongPress } from 'use-long-press';
import { faReply } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBadgeCheck } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faReply as faReplySolid } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faCrown } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { BASE_EMOJI_KEYWORDS } from '../../constants/emojis';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useToast } from '../../contexts/ToastContext';
import {
  makeFragmentData,
  MediaType,
  MessageReactionTypeInput,
  type ReplyToMessageFragment,
  TierTypename,
} from '../../graphql/generated';
import { ReplyToMessageFragmentDoc } from '../../graphql/generated';
import {
  getFragment,
  MessageBubbleFragmentDoc,
  MessageReactionRowFragmentDoc,
} from '../../graphql/generated';
import { type FragmentType } from '../../graphql/generated';

import { useMessageActions } from '../../hooks/message/useMessageActions';
import { useAdminArtist } from '../../hooks/useAdminArtist';
import { useDoubleClick } from '../../hooks/useDoubleClick';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useUserDisplayName } from '../../hooks/useUserDisplayName';
import { setReplyToMessage, useVaultMessageChannel } from '../../hooks/useVaultMessageChannel';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getFromList, getManyFromList } from '../../utils/arrayUtils';
import { dateToTime } from '../../utils/dateUtils';
import { LinkifyText } from '../common/LinkifyText';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { ProfileImage } from '../user/ProfileImage';
import { RecordingWaveform } from '../waveform/RecordingWaveform';
import { ElevatedMessageBubble } from './ElevatedMessageBubble';
import { ElevatedReplyToMessage } from './ElevatedReplyToMessage';
import { GifAttachment } from './GifAttachment';
import { MediaStack } from './MediaStack';
import { MediaViewer, type MediaViewerMedias } from './MediaViewer';
import { MessageAttachment } from './MessageAttachment';
import { MessageReactionRow } from './MessageReactionRow';

gql(/* GraphQL */ `
  fragment replyToMessage on Message {
    id
    createdAt
    content
    creator {
      id
      __typename
      ... on MessageActorArtist {
        artist {
          id

          linkValue
          name
          profileImage {
            id
            artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
            dominantColor
          }
        }
      }
      ... on MessageActorUser {
        user {
          id
          username
          displayName
          createdAt
          avatar {
            id
            cdnUrl
            userSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
            dominantColor
          }
        }
      }
    }
    messageAttachments {
      id
      media: uploadedMedia {
        id
        mediaType
        cdnUrl
        fullImageUrl: imageOptimizedUrl
        mediumImageUrl: imageOptimizedUrl(input: { width: 600, height: 600 })
      }
      gif {
        id
        url
        title
        aspectRatio
      }
    }
    vaultContent {
      id
      ...vaultMessageAttachment
    }
    activeSubscriptionTier
  }

  fragment messageBubble on Message {
    id
    content
    createdAt
    pinnedPriority
    creator {
      id
      __typename
      ... on MessageActorArtist {
        artist {
          id
          linkValue
          name
          profileImage {
            id
            artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
            dominantColor
          }
        }
      }
      ... on MessageActorUser {
        user {
          id
          username
          displayName
          createdAt
          avatar {
            id
            cdnUrl
            userSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
            dominantColor
          }
        }
      }
    }
    artistReactions {
      id
      type
      emojiKeyword
      ...artistReactionsMessageReactionRow
    }
    myReactions: myReactionsInfo(asArtistId: $asArtistId) {
      id
      type
      emojiKeyword
      ...myReactionsMessageReactionRow
    }
    reactionsSummary {
      ...messageReactionRow
    }
    vaultContent {
      id
      ...vaultMessageAttachment
    }
    messageAttachments {
      id
      media: uploadedMedia {
        id
        mediaType
        cdnUrl
        fullImageUrl: imageOptimizedUrl
        mediumImageUrl: imageOptimizedUrl(input: { width: 600, height: 600 })
      }
      gif {
        id
        url
        title
        aspectRatio
      }
    }
    replyTo {
      id
      ...replyToMessage
    }
    activeSubscriptionTier
    replyToWasDeleted
  }

  fragment messageSubscriptionBubble on CreateMessageSubscription {
    id
    content
    createdAt
    source
    asArtist {
      id
      linkValue
      name
      profileImage {
        id
        artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      }
    }
    creatorUser {
      id
      username
      displayName
      createdAt
      avatar {
        id
        url
        userSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      }
    }
    vaultContent {
      id
      vaultContent: content {
        id
        title
      }
    }
    messageAttachments {
      id
      media: uploadedMedia {
        id
        mediaType
        cdnUrl
        fullImageUrl: imageOptimizedUrl
        mediumImageUrl: imageOptimizedUrl(input: { width: 600, height: 600 })
      }
      gif {
        id
        url
        title
        aspectRatio
      }
    }
    replyTo {
      id
      content
      createdAt
      asArtist {
        id
        linkValue
        name
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
      }
      creatorUser {
        id
        username
        displayName
        createdAt

        avatar {
          id
          url
          userSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
      }
      messageAttachments {
        id
        media: uploadedMedia {
          id
          mediaType
          cdnUrl
          fullImageUrl: imageOptimizedUrl
          mediumImageUrl: imageOptimizedUrl(input: { width: 600, height: 600 })
        }
        gif {
          id
          url
          title
          aspectRatio
        }
      }
      vaultContent {
        id
        vaultContent: content {
          id
        }
      }
      activeSubscriptionTier
    }
    activeSubscriptionTier
  }
`);

export const MessageBubbleInner = ({
  message,
  className,
  isAuthor,
  onProfileImageClick,
  onLongPress,
  onReplyPress,
  onMessageReactionPress,
  onDoubleClick,
  onMessageReactionLongPress,
  onSingleClick,
  onViewMedia,
  type,
  isVaultArtist,
  artistProfileImageUrl,
  areSubscriptionTierBadgesVisible,
}: {
  message: FragmentType<MessageBubbleFragmentDoc>;
  className?: string;
  isAuthor?: boolean;
  onProfileImageClick?: () => void;
  onLongPress?: (ref: RefObject<HTMLElement> | undefined) => void;
  onReplyPress?: (
    ref: RefObject<HTMLElement> | undefined,
    replyToMessage: ReplyToMessageFragment,
  ) => void;
  onSingleClick?: () => void;
  onDoubleClick?: () => void;
  onMessageReactionPress?: (args: { emojiKeyword: string }) => void;
  onMessageReactionLongPress?: () => void;
  onViewMedia?: (medias: MediaViewerMedias, startAt?: number) => void;
  type: 'default' | 'elevated';
  isVaultArtist: boolean;
  artistProfileImageUrl: string | null | undefined;
  areSubscriptionTierBadgesVisible: boolean;
}) => {
  const {
    id,
    creator,
    content,
    artistReactions: artistReactionsRaw,
    myReactions: myReactionsRaw,
    vaultContent,
    messageAttachments,
    reactionsSummary: reactionsSummaryRaw,
    replyTo,
    replyToWasDeleted,
    activeSubscriptionTier,
  } = getFragment(MessageBubbleFragmentDoc, message);

  const asArtist = creator.__typename === 'MessageActorArtist' ? creator.artist : null;
  const user = creator.__typename === 'MessageActorUser' ? creator.user : null;

  const myReactions = useMemo(
    () => myReactionsRaw.filter(reaction => reaction.type === 'EMOJI'),
    [myReactionsRaw],
  );

  const artistReactions = useMemo(
    () => artistReactionsRaw.filter(reaction => reaction.type === 'EMOJI'),
    [artistReactionsRaw],
  );

  const reactionsSummary = useMemo(
    () =>
      reactionsSummaryRaw.filter(
        reaction => getFragment(MessageReactionRowFragmentDoc, reaction).type === 'EMOJI',
      ),
    [reactionsSummaryRaw],
  );

  const sum = reactionsSummary.reduce(
    (acc, value) => acc + getFragment(MessageReactionRowFragmentDoc, value).count,
    0,
  );

  const replyToMessage = getFragment(ReplyToMessageFragmentDoc, replyTo);

  const gifAttachment = useMemo(() => {
    return getFromList(
      messageAttachments,
      attachment => attachment.gif && { ...attachment, gif: attachment.gif },
    );
  }, [messageAttachments]);

  const mediaAttachments = useMemo(() => {
    return getManyFromList(messageAttachments, attachment => attachment.media);
  }, [messageAttachments]);

  const imageVideoAttachments = useMemo(() => {
    return getManyFromList(mediaAttachments, attachment =>
      attachment.mediaType === MediaType.Image || attachment.mediaType === MediaType.Video
        ? attachment
        : null,
    );
  }, [mediaAttachments]);

  const audioAttachments = useMemo(() => {
    return getManyFromList(mediaAttachments, attachment =>
      attachment.mediaType === MediaType.Recording ? attachment : null,
    );
  }, [mediaAttachments]);

  const userDisplayName = useUserDisplayName({
    artistName: asArtist?.name,
    userDisplayName: user?.displayName,
    userId: creator.id,
    userUsername: user?.username,
  });

  const replyToUser =
    replyToMessage != null && replyToMessage?.creator.__typename === 'MessageActorUser'
      ? replyToMessage?.creator.user
      : null;

  const replyToArtist =
    replyToMessage != null && replyToMessage?.creator.__typename === 'MessageActorArtist'
      ? replyToMessage.creator.artist
      : null;

  const replyToUserDisplayName = useUserDisplayName({
    userId: replyToUser?.id || '',
    artistName: replyToArtist?.name,
    userDisplayName: replyToUser?.displayName,
    userUsername: replyToUser?.username,
  });

  const ref = useRef<HTMLDivElement>(null);
  const [reactionButtonRefs, setReactionButtonRefs] = useState<RefObject<HTMLDivElement>[]>([]);

  const onLongPressOverride = useLongPress(e => {
    // If user isn't long pressing on a reaction button, then we call onLongPress
    if (!reactionButtonRefs.some(bRef => bRef.current?.contains(e.target as Node))) {
      onLongPress?.(ref);
    }
  })();

  const handleDoubleClick = useStableCallback((e: Event) => {
    if (ref.current?.contains(e.target as Node)) {
      onDoubleClick?.();
    }
  });

  const handleSingleClick = useStableCallback((e: Event) => {
    if (ref.current?.contains(e.target as Node)) {
      onSingleClick?.();
    }
  });

  useDoubleClick({
    ref,
    onDoubleClick: handleDoubleClick,
    onSingleClick: handleSingleClick,
  });

  return (
    <View
      className={twMerge(
        'flex w-full select-none flex-row items-end',
        isAuthor ? 'justify-end' : 'justify-start',
        sum > 0 && 'pb-[18px]',
        className,
      )}
    >
      {!isAuthor && onProfileImageClick && (
        <ProfileImage
          profileImageUrl={
            asArtist?.profileImage?.artistSmallProfileImageUrl ||
            user?.avatar?.userSmallProfileImageUrl ||
            user?.avatar?.cdnUrl
          }
          className="mr-[4px] h-[30px] cursor-pointer"
          onClick={onProfileImageClick}
        />
      )}

      <View
        className={twMerge(
          'relative flex max-w-[67vw] flex-col gap-1.5 md2:max-w-[430px]',
          type === 'default' && 'max-w-[75%]',
          isAuthor ? 'items-end' : 'items-start',
          (vaultContent.length > 0 || audioAttachments.length > 0) &&
            (type === 'elevated' ? 'w-[67vw] md2:w-[430px]' : 'w-[75%]'),
          type === 'elevated' && 'rounded-xl bg-vault_background',
        )}
      >
        {type !== 'elevated' && (
          <View
            className={twMerge(
              'mx-1 flex items-center text-vault_text/50',
              type === 'default' ? 'cursor-pointer' : 'cursor-default',
              isAuthor ? 'justify-end' : 'justify-start',
            )}
            onClick={replyToMessage ? () => onReplyPress?.(ref, replyToMessage) : undefined}
          >
            {replyToWasDeleted ? (
              <View className="flex flex-row items-center gap-1.5">
                <FontAwesomeIcon icon={faReply} size="xs" />
                <View className="flex items-center font-base text-[12px] font-normal italic">
                  Original message was removed
                </View>
              </View>
            ) : (
              replyToMessage && (
                <View className="flex flex-row items-center gap-1.5">
                  <FontAwesomeIcon icon={faReplySolid} size="xs" />
                  <View className="flex flex-row items-center gap-1 truncate font-base text-[12px]">
                    <span className="font-bold">{`${replyToUserDisplayName}: `}</span>
                    <span className="text-base line-clamp-1 select-none whitespace-pre-line break-all font-base font-normal">
                      {replyToMessage.content || 'Click to see attachment'}
                    </span>
                  </View>
                </View>
              )
            )}
          </View>
        )}
        <View
          className={twMerge(
            'npx flex max-w-full cursor-pointer flex-col break-words border',
            vaultContent.length > 0 || audioAttachments.length > 0
              ? 'w-full'
              : isAuthor
                ? 'items-end'
                : 'justify-start',
          )}
          containerRef={ref}
          onLongPress={onLongPressOverride}
        >
          {/* Gif Attachment, if any */}
          {gifAttachment && (
            <GifAttachment isAuthor={isAuthor} gif={gifAttachment.gif} type={type} />
          )}
          {/* Image/Video attachment media stack, if any */}
          {imageVideoAttachments.length !== 0 && (
            <MediaStack
              isAuthor={isAuthor}
              medias={imageVideoAttachments.map(v => ({
                ...v,
                type: v.mediaType,
                lockedMedia: null,
              }))}
              onView={startAt => {
                if (onViewMedia)
                  onViewMedia(
                    imageVideoAttachments.map(media => ({
                      id: media.id,
                      url: media.fullImageUrl || media.cdnUrl,
                      type: media.mediaType,
                    })),
                    startAt,
                  );
              }}
            />
          )}
          {(content.length !== 0 || vaultContent.length !== 0 || audioAttachments.length !== 0) && (
            <View
              className={twMerge(
                'box-border flex flex-col gap-1 break-words rounded-xl p-3',
                isAuthor ? 'bg-vault_accent' : 'bg-vault_text/20 md2:bg-vault_text/10',
                imageVideoAttachments.length !== 0 && 'w-fit justify-end',
              )}
            >
              {!isAuthor && (
                <Text
                  className={clsx(
                    'm-0 pb-[4px] font-base !text-base-s font-semibold',
                    isVaultArtist ? 'text-vault_accent' : 'text-vault_text',
                  )}
                >
                  {userDisplayName}
                  {isVaultArtist && (
                    <FontAwesomeIcon
                      icon={faBadgeCheck}
                      className="ml-1 select-none text-[12px] text-vault_accent"
                    />
                  )}
                  {activeSubscriptionTier === TierTypename.PaidTier &&
                    areSubscriptionTierBadgesVisible && (
                      <FontAwesomeIcon
                        icon={faCrown}
                        className="ml-1 select-none text-[12px] text-vault_accent"
                      />
                    )}
                </Text>
              )}
              {/* Render Text Content */}{' '}
              {content.length !== 0 && (
                <>
                  {isVaultArtist ? (
                    <LinkifyText
                      className={isAuthor ? 'text-vault_accent_text' : 'text-vault_accent'}
                    >
                      <Text
                        className={twMerge(
                          'm-0 select-none whitespace-pre-line font-base !text-base-m font-normal',
                          isAuthor ? 'text-vault_accent_text' : 'text-vault_text',
                          // Check if any word is longer than 45 chars (likely a URL or unbroken string)
                          // If found, use aggressive break-all, otherwise use standard word breaks
                          content.split(' ').some(word => word.length > 45)
                            ? 'overflow-wrap-anywhere break-all'
                            : 'break-words',
                        )}
                      >
                        {content}
                      </Text>
                    </LinkifyText>
                  ) : (
                    <Text
                      className={twMerge(
                        'm-0 select-none whitespace-pre-line font-base !text-base-m font-normal',
                        isAuthor ? 'text-vault_accent_text' : 'text-vault_text',
                        // Check if any word is longer than 45 chars (likely a URL or unbroken string)
                        // If found, use aggressive break-all, otherwise use standard word breaks
                        content.split(' ').some(word => word.length > 45)
                          ? 'overflow-wrap-anywhere break-all'
                          : 'break-words',
                      )}
                    >
                      {content}
                    </Text>
                  )}
                </>
              )}
              {/* Render Audio Media */}
              {audioAttachments.length > 0 &&
                audioAttachments.map(audio => (
                  <RecordingWaveform
                    key={audio.id}
                    className={twMerge(!isAuthor && 'pt-3')}
                    height={20}
                    audioUrl={audio.cdnUrl}
                    isLoading={false}
                    variant="message"
                  />
                ))}
              {/* Render Vault Content (Tracks/Images/Videos)*/}
              {/* TODO: Revisit this so images and videos render outside of the message bubble */}
              {vaultContent?.map(vaultContent => (
                <View className="w-full" key={vaultContent.id}>
                  <MessageAttachment
                    messageContent={vaultContent}
                    type="full"
                    className="mt-0"
                    isAuthor={isAuthor}
                    onViewMedia={onViewMedia}
                  />
                </View>
              ))}
            </View>
          )}
        </View>

        {type === 'default' && (
          <MessageReactionRow
            messageId={id}
            reactionsSummary={reactionsSummary}
            artistReactions={artistReactions}
            myReactions={myReactions}
            onMessageReactionPress={onMessageReactionPress}
            onMessageReactionLongPress={onMessageReactionLongPress}
            setReactionButtonRefs={setReactionButtonRefs}
            artistProfileImageUrl={artistProfileImageUrl}
          />
        )}
      </View>
    </View>
  );
};

export const MessageBubbleInteractions = {
  enableLongPress: true,
};

export const MessageBubble = ({
  message,
  className,
  isOwner = false,
  containerMarginRight,
  onLongPress: onLongPressCallback,
  onReplyPress: onReplyPressCallback,
  artistName,
  artistLinkValue,
  artistProfileImageUrl,
  isVaultArtist,
  isGroupChat,
  vaultArtistId,
  type = 'default',
  areSubscriptionTierBadgesVisible,
  hasChatWriteAccess,
  vaultId,
  isBanned,
}: {
  message: FragmentType<MessageBubbleFragmentDoc>;
  className?: string;
  isOwner: boolean;
  isElevated?: boolean;
  containerMarginRight?: string;
  onLongPress?: () => void;
  onReplyPress?: () => void;
  artistName: string | undefined;
  artistLinkValue: string | undefined;
  artistProfileImageUrl: string | null | undefined;
  isVaultArtist: boolean;
  isGroupChat: boolean;
  vaultArtistId: string | undefined;
  type?: 'default' | 'see_details';
  areSubscriptionTierBadgesVisible: boolean;
  hasChatWriteAccess: boolean;
  vaultId: string | undefined;
  isBanned: boolean;
}) => {
  const { openBottomsheet } = useBottomsheetContainer();
  const { openOverlayWithAnimation, openOverlay, closeOverlay } = useOverlayContainer();
  const { loggedInUser } = useAuthContext();

  const {
    id,
    creator,
    content,
    createdAt,
    myReactions,
    vaultContent,
    messageAttachments,
    reactionsSummary,
    activeSubscriptionTier,
  } = getFragment(MessageBubbleFragmentDoc, message);
  const { openToast } = useToast();

  const asArtist = creator.__typename === 'MessageActorArtist' ? creator.artist : null;
  const user = creator.__typename === 'MessageActorUser' ? creator.user : null;

  const adminArtist = useAdminArtist({ artistHandle: artistLinkValue });

  const userDisplayName = useUserDisplayName({
    artistName: asArtist?.name,
    userDisplayName: user?.displayName,
    userId: user?.id || '',
    userUsername: user?.username,
  });

  const x = useMotionValue(0);
  const iconOpacity = useTransform(x, [0, 40], [0, 1]);
  const initialX = useRef(0);
  const [isReplayable, setIsReplayable] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    if (type === 'see_details' || isBanned) return;

    initialX.current = info.point.x;
  };

  const handleDrag = (_event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    if (type === 'see_details' || !hasChatWriteAccess || isBanned) return;

    const currentOffset = info.point.x - initialX.current;
    if (currentOffset > 0) {
      setIsDragging(true);
      const replyable = currentOffset > 40;
      x.set(Math.min(50, currentOffset)); // limit to 50 pixels drag to the right
      setIsReplayable(replyable);
    } else {
      x.set(0); // dragging to the left, don't translate
    }
  };

  const handleDragEnd = () => {
    if (type === 'see_details' || !hasChatWriteAccess || isBanned) return;

    const finalOffset = x.get();
    if (finalOffset > 40) {
      trackEvent({
        type: EVENTS.SET_REPLY_TO_MESSAGE,
        properties: {
          messageId: id,
          type: 'drag',
        },
      });

      setReplyToMessage(
        makeFragmentData(
          {
            id: id,
            creator: creator,
            content: content,
            createdAt: createdAt,
            vaultContent: vaultContent,
            asArtist: asArtist,
            messageAttachments: messageAttachments.map(attachment => ({
              id: attachment.id,
              media: attachment.media,
              gif: attachment.gif,
            })),
            source: 'VAULT_CHAT',
            activeSubscriptionTier: activeSubscriptionTier,
          },
          ReplyToMessageFragmentDoc,
        ),
      );
    }
    setIsReplayable(false);
    setIsDragging(false);
    x.set(0);
    initialX.current = 0;
  };

  const isOptimistic = 'isOptimistic' in message;

  const isAuthor = asArtist
    ? asArtist.id === adminArtist?.artistId || asArtist.id === loggedInUser?.artist?.id
    : user?.id === loggedInUser?.id;

  const onLongPress = useStableCallback((ref: RefObject<HTMLElement> | undefined) => {
    if (
      !MessageBubbleInteractions.enableLongPress ||
      isOptimistic ||
      isDragging ||
      (!hasChatWriteAccess && isGroupChat) ||
      isBanned
    )
      return;

    onLongPressCallback?.();
    if (ref?.current) {
      openOverlayWithAnimation(
        ref,
        <ElevatedMessageBubble
          messageFrag={{ id, ...message }}
          isAuthor={isAuthor}
          areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
          isOwner={isOwner}
          artistProfileImageUrl={artistProfileImageUrl}
          artistLinkValue={artistLinkValue}
          parentType={type}
          isVaultArtist={isVaultArtist}
          isGroupChat={isGroupChat}
        />,
        150, // custom top threshold for message bubble
        400, // custom bottom threshold for message bubble
      );
    } else {
      // This should never happen but fall back to opening the overlay without animation
      openOverlay(
        <ElevatedMessageBubble
          messageFrag={{ id, ...message }}
          isAuthor={isAuthor}
          areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
          isOwner={isOwner}
          artistProfileImageUrl={artistProfileImageUrl}
          artistLinkValue={artistLinkValue}
          parentType={type}
          isVaultArtist={isVaultArtist}
          isGroupChat={isGroupChat}
        />,
      );
    }
  });

  const onReplyPress = useStableCallback(
    (ref: RefObject<HTMLElement> | undefined, replyToMessage: ReplyToMessageFragment) => {
      if (isBanned) return;

      trackEvent({ type: EVENTS.VIEW_REPLY, properties: { messageId: id } });

      onReplyPressCallback?.();
      if (ref?.current) {
        openOverlayWithAnimation(
          ref,
          <ElevatedReplyToMessage
            messageFrag={message}
            replyToMessage={replyToMessage}
            areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
            isReplyToAuthor={
              replyToMessage.creator.__typename === 'MessageActorArtist'
                ? replyToMessage.creator.artist.id === adminArtist?.artistId
                : replyToMessage.creator.user.id === loggedInUser?.id
            }
            isAuthor={isAuthor}
            isOwner={isOwner}
            vaultArtistId={vaultArtistId}
            artistProfileImageUrl={artistProfileImageUrl}
            vaultId={vaultId}
          />,
          400, // custom top threshold for reply to message
          20, // custom bottom threshold fore reply to message
        );
      } else {
        // This should never happen but fall back to opening the overlay without animation
        openOverlay(
          <ElevatedMessageBubble
            messageFrag={{ id, ...message }}
            areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
            isAuthor={isAuthor}
            isOwner={isOwner}
            artistProfileImageUrl={artistProfileImageUrl}
            artistLinkValue={artistLinkValue}
            parentType={type}
            isVaultArtist={isVaultArtist}
            isGroupChat={isGroupChat}
          />,
        );
      }
    },
  );

  const openProfileBottomsheet = () => {
    if (!user?.id) return;

    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: {
        bottomsheetType: BOTTOMSHEET_TYPES.USER_PROFILE,
        userId: user.id,
      },
    });

    openBottomsheet({
      type: 'USER_PROFILE',
      shared: {
        withVaultTheme: true,
      },
      userProfileBottomsheetProps: {
        userLocation: null,
        vaultId,
        userId: user.id,
        joinDate: null,
        vaultArtistId,
        avatarUrl:
          asArtist?.profileImage?.artistSmallProfileImageUrl ||
          user.avatar.userSmallProfileImageUrl ||
          user.avatar.cdnUrl,
        username: asArtist?.linkValue || user.username || '',
        displayName: userDisplayName,
        isVaultArtist,
        activeSubscriptionTier,
        showAdminOptions: isOwner,
        withVaultTheme: true,
      },
    });
  };

  const myReactionsObj = useMemo(
    () => keyBy(myReactions, v => v.type + v.emojiKeyword),
    [myReactions],
  );

  const { reactionUpdate } = useVaultMessageChannel();

  const { createMessageReaction, isCreatingReaction, deleteMessageReaction, isDeletingReaction } =
    useMessageActions({
      messageId: id,
    });

  const isReacting = isCreatingReaction || isDeletingReaction;

  const onMessageReactionPress = useStableCallback(
    async ({ emojiKeyword }: { emojiKeyword: string }) => {
      if (!loggedInUser || isOptimistic || isReacting || !hasChatWriteAccess || isBanned) return;

      const userId = loggedInUser.id;

      const execute = async () => {
        if (myReactionsObj[MessageReactionTypeInput.Emoji + emojiKeyword] == null) {
          const { revert } = reactionUpdate({
            id,
            created: true,
            reactionType: MessageReactionTypeInput.Emoji,
            userId,
            reactionTotalCount: null,
            isArtistReaction: isOwner,
            asArtistId: adminArtist?.artistId || null,
            emojiKeyword,
          });
          await createMessageReaction({
            input: {
              messageId: id,
              reactionType: MessageReactionTypeInput.Emoji,
              asArtistId: adminArtist?.artistId,
              emojiKeyword,
            },
            toast: {
              text: (
                <p>
                  This message could not be reacted to at this time.{' '}
                  <span
                    className="cursor-pointer !text-base-m font-semibold underline"
                    onClick={execute}
                  >
                    Try again.
                  </span>
                </p>
              ),
              variant: 'error',
            },
            onError: () => {
              revert();
            },
          });
        } else {
          const { revert } = reactionUpdate({
            id,
            created: false,
            reactionType: MessageReactionTypeInput.Emoji,
            userId,
            reactionTotalCount: null,
            isArtistReaction: isOwner,
            asArtistId: adminArtist?.artistId || null,
            emojiKeyword,
          });

          await deleteMessageReaction({
            input: {
              messageId: id,
              reactionType: MessageReactionTypeInput.Emoji,
              asArtistId: adminArtist?.artistId,
              emojiKeyword,
            },
            onError: () => {
              revert();
            },
            toast: {
              text: (
                <p>
                  This message reaction could not be removed at this time.{' '}
                  <span
                    className="cursor-pointer !text-base-m font-semibold underline"
                    onClick={execute}
                  >
                    Try again.
                  </span>
                </p>
              ),
              variant: 'error',
            },
          });
        }
      };

      execute();
    },
  );

  const onMessageReactionLongPress = useStableCallback(() => {
    if (isOptimistic || isBanned) return;

    const reactSum = getFragment(MessageReactionRowFragmentDoc, reactionsSummary);

    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: {
        bottomsheetType: BOTTOMSHEET_TYPES.MESSAGE_REACTION,
        messageId: id,
        reactionSummary: reactSum,
      },
    });

    openBottomsheet({
      type: 'MESSAGE_REACTION',
      messageReactionBottomsheetProps: {
        messageId: id,
        reactionsSummary: reactSum,
      },
    });
  });

  const onDoubleClick = useStableCallback(async () => {
    if (!loggedInUser || isOptimistic || isReacting || isBanned) return;

    const userId = loggedInUser.id;

    if (!userId || !hasChatWriteAccess) return;

    if (myReactionsObj[MessageReactionTypeInput.Emoji + BASE_EMOJI_KEYWORDS.HEART] != null) return;

    const execute = async () => {
      const { revert } = reactionUpdate({
        id,
        created: true,
        reactionType: MessageReactionTypeInput.Emoji,
        userId,
        reactionTotalCount: null,
        isArtistReaction: isOwner,
        asArtistId: adminArtist?.artistId || null,
        emojiKeyword: BASE_EMOJI_KEYWORDS.HEART,
      });

      await createMessageReaction({
        input: {
          messageId: id,
          reactionType: 'EMOJI',
          emojiKeyword: BASE_EMOJI_KEYWORDS.HEART,
          asArtistId: adminArtist?.artistId,
        },
        onSuccess: () => {
          closeOverlay();
        },
        onError: () => {
          revert();
          openToast({
            text: (
              <p>
                This message could not be reacted to at this time.{' '}
                <span
                  className="cursor-pointer !text-base-m font-semibold underline"
                  onClick={execute}
                >
                  Try again.
                </span>
              </p>
            ),
            variant: 'error',
          });
        },
      });
    };

    execute();
  });

  const onViewMedia = useStableCallback((medias: MediaViewerMedias, startAt?: number) => {
    openOverlay(
      <MediaViewer title={artistName} startAt={startAt} medias={medias} onClose={closeOverlay} />,
    );
  });

  const messageHour = useMemo(() => {
    return dateToTime(createdAt);
  }, [createdAt]);

  return (
    <View className="relative overflow-hidden">
      {/* Static icon container that does not move */}
      <motion.div
        className="absolute bottom-0 left-0 top-0 flex items-center pl-2"
        style={{ opacity: iconOpacity }}
      >
        <FontAwesomeIcon icon={isReplayable ? faReplySolid : faReply} size="sm" />
      </motion.div>

      {/* Draggable content */}
      <AnimatedContainer
        handleDrag={handleDrag}
        handleDragEnd={handleDragEnd}
        handleDragStart={handleDragStart}
        isDragging={isDragging}
        isOptimistic={isOptimistic}
        x={x}
        type={type}
        containerMarginRight={containerMarginRight ?? '-60px'}
        disabled={isBanned || !hasChatWriteAccess}
      >
        <MessageBubbleInner
          message={message}
          className={className}
          isAuthor={isAuthor}
          onLongPress={onLongPress}
          onReplyPress={onReplyPress}
          onDoubleClick={onDoubleClick}
          onProfileImageClick={openProfileBottomsheet}
          onMessageReactionPress={onMessageReactionPress}
          onMessageReactionLongPress={onMessageReactionLongPress}
          onViewMedia={onViewMedia}
          type="default"
          isVaultArtist={isVaultArtist}
          artistProfileImageUrl={artistProfileImageUrl}
          areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
        />
        <p className="ml-[10px] w-[50px] shrink-0 pl-[10px] !text-base-xs text-vault_text/50">
          {messageHour}
        </p>
      </AnimatedContainer>
    </View>
  );
};

const AnimatedContainer = ({
  type,
  children,
  isOptimistic,
  isDragging,
  x,
  containerMarginRight,
  handleDragStart,
  handleDrag,
  handleDragEnd,
  disabled,
}: {
  type: 'default' | 'see_details';
  children: ReactNode;
  isOptimistic: boolean;
  isDragging: boolean;
  x: MotionValue<number>;
  containerMarginRight: string;
  handleDragStart: (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void;
  handleDrag: (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void;
  handleDragEnd: () => void;
  disabled: boolean;
}) => {
  if (type === 'see_details') {
    return (
      <View
        className={twMerge(
          'relative ml-[-49px] flex items-center pb-5 transition-all duration-300 ease-out',
          isOptimistic && 'opacity-70',
        )}
        style={{ right: containerMarginRight }}
      >
        {children}
      </View>
    );
  } else {
    return (
      <motion.div
        className={twMerge(
          'relative ml-[-49px] flex items-center pb-5 transition-all duration-300 ease-out',
          isDragging ? 'cursor-grabbing' : 'cursor-grab',
          isOptimistic && 'opacity-70',
        )}
        drag={disabled ? false : 'x'}
        dragDirectionLock
        style={{ x, right: containerMarginRight }}
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0.3}
      >
        {children}
      </motion.div>
    );
  }
};

export const SkeletonMessageBubble = ({
  isAuthor,
  className,
  avatarClassName,
  contentClassName,
}: {
  isAuthor: boolean;
  className?: string;
  avatarClassName?: string;
  contentClassName?: string;
}) => {
  return (
    <View
      className={twMerge(
        'box-border flex w-full flex-row items-end pb-[10px]',
        isAuthor ? 'justify-end' : 'justify-start',
        className,
      )}
    >
      <LoadingSkeleton
        className={twMerge(
          'mr-[4px] h-[30px] w-[30px] rounded-full bg-vault_text/10',
          avatarClassName,
        )}
      />
      <LoadingSkeleton
        className={twMerge(
          'flex h-[80px] w-[200px] flex-col rounded-xl bg-base800 bg-vault_text/10 p-[12px]',
          contentClassName,
        )}
      />
    </View>
  );
};
