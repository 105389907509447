import React from 'react';
import { debounce } from 'lodash-es';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';
import { gql } from '@soundxyz/gql-string';
import { loadTrack, seek, useAudioController } from '../../audio/AudioController';
import { play } from '../../audio/AudioEngineHTML5';
import { useAudioPosition } from '../../audio/AudioPosition';
import { BAR_MARGIN, BAR_WIDTH } from '../../constants/waveformConstants';
import {
  type FragmentType,
  getFragment,
  WaveformTrackInfoFragmentDoc,
} from '../../graphql/generated';
import { useStableCallback } from '../../hooks/useStableCallback';
import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { paintCanvas, waveformAvgChunker } from '../../utils/waveformUtils';
import { View } from '../common/View';

gql(/* GraphQL */ `
  fragment WaveformTrackInfo on VaultTrack {
    id
    normalizedPeaks
    duration

    parentVaultContentId
    vaultId
  }
`);

// This is necessary so that the rendered peak are not blurry
const RESOLUTION_MULTIPLIER = 4;

export const Waveform = ({
  className,
  track,
  height,
  isDisabled,
  isAuthor,
}: {
  height: number;
  track: FragmentType<WaveformTrackInfoFragmentDoc>;
  className?: string;
  isDisabled: boolean;
  isAuthor: boolean;
}) => {
  const { activeTrackId, track: activeTrack } = useAudioController();
  const { percentComplete } = useAudioPosition();

  const vaultTheme = useSnapshot(VaultThemeStore);

  const {
    normalizedPeaks,
    id: trackId,
    duration,
    vaultId,
    parentVaultContentId,
  } = getFragment(WaveformTrackInfoFragmentDoc, track);

  const [waveformWidth, setWaveformWidth] = React.useState(0);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const isActiveTrack = trackId === activeTrackId;

  const trackPerc = isActiveTrack ? percentComplete / 100 : 0;
  const playingBarNum = Math.floor((trackPerc * waveformWidth) / (BAR_WIDTH + BAR_MARGIN));

  const [hoverXCoord, setHoverXCoord] = React.useState<number>(0);

  const chunkedPeaks = React.useMemo(() => {
    const numBars = Math.min(
      Math.floor(waveformWidth / (BAR_WIDTH + BAR_MARGIN)),
      normalizedPeaks.length,
    );
    return waveformAvgChunker(normalizedPeaks, numBars);
  }, [normalizedPeaks, waveformWidth]);

  const paintWaveformCanvas = React.useCallback(() => {
    if (!canvasRef.current) {
      return;
    }

    paintCanvas({
      canvasRef,
      normalizedPeaks: chunkedPeaks,
      maxBarHeight: height * RESOLUTION_MULTIPLIER,
      barWidth: BAR_WIDTH * RESOLUTION_MULTIPLIER,
      barMargin: BAR_MARGIN * RESOLUTION_MULTIPLIER,
      playingBarNum,
      hoverXCoord: hoverXCoord * RESOLUTION_MULTIPLIER,
      isActiveTrack,
      showHalf: false,
      snippet: null,
      customActiveColor: isAuthor ? vaultTheme.textColor : vaultTheme.accentColor,
    });
  }, [
    chunkedPeaks,
    height,
    playingBarNum,
    hoverXCoord,
    isActiveTrack,
    isAuthor,
    vaultTheme.textColor,
    vaultTheme.accentColor,
  ]);

  React.useEffect(() => {
    paintWaveformCanvas();
  }, [paintWaveformCanvas]);

  React.useEffect(() => {
    const handleResize = debounce(() => {
      if (containerRef.current) {
        setWaveformWidth(containerRef.current.offsetWidth);
      }
    }, 200);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [containerRef]);

  const mouseOutRef = React.useRef(false);

  const handleMouseMove = useStableCallback((e: { clientX: number }) => {
    if (!canvasRef.current || isDisabled) return;

    // debounced hover coord updates
    requestAnimationFrame(() => {
      if (!canvasRef.current || mouseOutRef.current) return;

      setHoverXCoord(Math.round(e.clientX - canvasRef.current.getBoundingClientRect().left));
    });
  });

  const handleClick = useStableCallback(async (e: React.MouseEvent<HTMLCanvasElement>) => {
    e.stopPropagation();
    if (!canvasRef.current || isDisabled) return;

    const xCoord = e.clientX - canvasRef.current.getBoundingClientRect().left;

    const seekPerc = xCoord / waveformWidth;

    const seekTo = Math.floor(seekPerc * duration);

    if (isActiveTrack) {
      activeTrack != null &&
        trackEvent({
          type: EVENTS.SEEK_TRACK,
          properties: {
            trackId: activeTrack.id,
            vaultId: activeTrack.vault.id,
            artistId: activeTrack.vault.artist?.id,
            type: 'waveform',
            loadedTrack: false,
          },
        });

      seek(seekTo);
      play();
    } else {
      loadTrack({
        trackId,
        vaultId,
        component: 'waveform',
        folderId: parentVaultContentId,
      }).then(() => {
        trackEvent({
          type: EVENTS.SEEK_TRACK,
          properties: {
            trackId,
            vaultId,
            type: 'waveform',
            loadedTrack: true,
          },
        });

        seek(seekTo);
      });
    }
  });

  const onMouseIn = React.useCallback(() => {
    mouseOutRef.current = false;
  }, []);

  const onMouseOut = React.useCallback(() => {
    mouseOutRef.current = true;
    setHoverXCoord(0);
  }, []);

  React.useEffect(() => {
    // resets the hoverstate when user changes program or tab after hovering
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        onMouseOut();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [onMouseOut]);

  return (
    <View className={className} containerRef={containerRef}>
      <canvas
        className={twMerge(
          'display-block w-full',
          isDisabled ? 'cursor-default' : 'cursor-pointer',
        )}
        style={{
          height,
          WebkitTapHighlightColor: 'transparent',
        }}
        ref={canvasRef}
        height={height * RESOLUTION_MULTIPLIER}
        width={waveformWidth * RESOLUTION_MULTIPLIER}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
        onMouseEnter={onMouseIn}
        onMouseOut={onMouseOut}
        onBlur={onMouseOut}
      />
    </View>
  );
};
