import type { FC } from 'react';
import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate, useNavigate } from 'react-router';
import { faCheckCircle, faCircleX } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCreditCard } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../../constants/bottomsheetConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import {
  type FragmentType,
  getFragment,
  ManageSubscriptionFragmentDoc,
  TierTypename,
  VaultType,
} from '../../../graphql/generated';
import { useSubscribeToVault } from '../../../hooks/useSubscribeToVault';
import { useActiveSubscriptionFeatures } from '../../../hooks/useTierFeatures';
import { startSubscriptionPaymentIntent } from '../../../providers/StripeProvider';
import { LoginStatus } from '../../../types/authTypes';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { getFromList } from '../../../utils/arrayUtils';
import { formatDateString } from '../../../utils/textUtils';
import { Button } from '../../buttons/Button';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { PaymentMethodRow } from '../../payment/PaymentMethodRow';
import { VaultRow } from '../../vault/VaultRow';

gql(/* GraphQL */ `
  fragment ManageSubscription on VaultSubscription {
    id
    createdAt
    updatedAt
    status
    currentPeriodEnd
    stripeSubscriptionId
    vault {
      type
      artist: artistProfile {
        id
        linkValue
        name
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
        ...artistVaultRow
      }
      activeSubscription {
        id
        ...ActiveSubscriptionFeatures
      }
      contentCount
      id
      price
    }
    paymentMethod {
      id
      active
      last4
      ...paymentMethodRow
    }
  }
`);

const ManageSubscriptionView: FC<{
  subscription: FragmentType<ManageSubscriptionFragmentDoc>;
}> = ({ subscription }) => {
  const { loggedInUser, loginStatus } = useAuthContext();
  const { unsubscribe, subscribe, isLoading } = useSubscribeToVault();
  const { openBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();

  const { id, vault, status, updatedAt, currentPeriodEnd, paymentMethod, stripeSubscriptionId } =
    getFragment(ManageSubscriptionFragmentDoc, subscription);

  const adminArtist = useMemo(() => {
    return getFromList(
      loggedInUser?.adminArtists,
      adminArtist => adminArtist.artistId === vault.artist?.id && adminArtist,
    );
  }, [loggedInUser?.adminArtists, vault.artist?.id]);

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.artist?.id === adminArtist?.artistId,
  });

  const isFreeTier = activeSubscriptionFeatures?.tier === TierTypename.FreeTier;
  const isFreeSubscription = vault.type !== VaultType.FreeOnly && isFreeTier;

  if (
    (status === 'INACTIVE' &&
      (currentPeriodEnd == null || currentPeriodEnd < new Date().toISOString())) ||
    vault.price == null
  ) {
    return <Navigate to={`${ROUTES.SETTINGS}/memberships`} replace />;
  }

  const onCancel = () => {
    openBottomsheet({
      type: 'CONFIRMATION',
      confirmationBottomsheetProps: {
        onConfirm: () => {
          trackEvent({
            type: EVENTS.CANCEL_SUBSCRIPTION,
            properties: {
              subscriptionId: id,
              vaultId: vault.id,
              artistId: vault.artist?.id ?? null,
              isFreeTier,
            },
          });

          unsubscribe({ subscriptionId: id });
        },
        subText: `Your membership will be cancelled${
          currentPeriodEnd != null
            ? ` on ${formatDateString({
                date: currentPeriodEnd,
                format: 'numerical_month_day_year',
              })} and you won't be able to access this vault after this date`
            : ''
        }`,
      },
    });
  };

  const onResubscribe = () => {
    openBottomsheet({
      type: 'CONFIRMATION',
      confirmationBottomsheetProps: {
        onConfirm: () => {
          stripeSubscriptionId != null &&
            subscribe({
              subscriptionId: stripeSubscriptionId,
              vaultId: vault.id,
              linkValue: vault.artist?.linkValue ?? null,
              shouldRedirect: false,
            });
        },
        subText: `You will be subscribed${vault.artist?.name ? ' to ' + vault.artist.name : ''} and will be charged on card ending in ${paymentMethod != null ? paymentMethod.last4 : 'xxxx'}${
          currentPeriodEnd != null
            ? ` starting ${formatDateString({
                date: currentPeriodEnd,
                format: 'numerical_month_day_year',
              })}.`
            : '.'
        }`,
      },
    });
  };

  const onFullAccessSubscribe = () => {
    if (!vault.artist?.linkValue) return;

    const linkValue = vault.artist?.linkValue;
    if (loginStatus === LoginStatus.LOGGED_IN) {
      startSubscriptionPaymentIntent({ vaultId: vault.id, referralCode: null, openToast: null });
    }

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.SUBSCRIBE_WELCOME_MESSAGE,
      subscribeWelcomeMessageBottomsheetProps: {
        monthlySubPrice: `$${vault.price}`,
        artistAvatarUrl: vault.artist?.profileImage?.artistSmallProfileImageUrl,
        onConfirm: () => {
          if (loginStatus !== LoginStatus.LOGGED_IN) {
            navigate(`${ROUTES.SIGN_IN}?artistHandle=${linkValue}`);
          } else {
            navigate(`/${linkValue}/subscribe`);
          }
        },
        artistHandle: linkValue,
        isJoiningFreeTier: false,
      },
      shared: {
        hideCloseBottomsheetButton: true,
      },
    });
  };

  return (
    <View className="w-full">
      <VaultRow
        artist={vault.artist}
        subTextComponent={
          status === 'ACTIVE' ? (
            isFreeTier ? (
              <Text className="font-base text-base-m font-normal text-base400">
                Subscribed since{' '}
                {formatDateString({
                  date: updatedAt,
                  format: 'numerical_month_day_year',
                })}
              </Text>
            ) : currentPeriodEnd != null ? (
              <Text className="font-base text-base-m font-normal text-white">
                Your next bill is for <b>{`$${vault.price}`}</b> on{' '}
                {formatDateString({
                  date: currentPeriodEnd,
                  format: 'numerical_month_day_year',
                })}
              </Text>
            ) : undefined
          ) : (
            status === 'INACTIVE' &&
            currentPeriodEnd != null && (
              <Text className="font-base text-base-m font-normal text-white">
                Your membership ends on{' '}
                {formatDateString({
                  date: currentPeriodEnd,
                  format: 'numerical_month_day_year',
                })}
              </Text>
            )
          )
        }
      />
      {paymentMethod != null && (
        <PaymentMethodRow paymentMethod={paymentMethod} className="mb-4" withVaultTheme={false} />
      )}
      {status === 'ACTIVE' && isFreeSubscription && (
        <View
          className="mb-4 box-border flex w-full cursor-pointer flex-row justify-between rounded-xl bg-base800 px-[28px] py-[20px]"
          onClick={onFullAccessSubscribe}
        >
          <View className="flex flex-row items-center">
            <FontAwesomeIcon icon={faCreditCard} className="mr-[12px]" />
            <Text className="mr-[12px] font-title text-title-s font-medium text-white">
              Get all access pass
            </Text>
          </View>
        </View>
      )}
      {status === 'ACTIVE' ? (
        <Button
          type="secondary"
          label={
            vault.type === VaultType.FreeOnly || isFreeTier ? 'Leave Vault' : 'Cancel membership'
          }
          leadingIcon={faCircleX}
          className="font-title text-[16px]/[20px] text-destructive300"
          onClick={onCancel}
          loading={isLoading}
          event={{
            type: EVENTS.OPEN_BOTTOMSHEET,
            properties: {
              bottomsheetType: BOTTOMSHEET_TYPES.CONFIRMATION,
              event: EVENTS.CANCEL_SUBSCRIPTION,
            },
          }}
        />
      ) : (
        paymentMethod != null &&
        paymentMethod.active && (
          <Button
            type="secondary"
            leadingIcon={faCheckCircle}
            label="Resubscribe"
            onClick={onResubscribe}
            className="font-title text-[16px]/[20px] text-white"
            loading={isLoading}
            event={{
              type: EVENTS.RESUBSCRIBE,
              properties: { artistId: vault.artist?.id ?? null, vaultId: vault.id },
            }}
          />
        )
      )}
    </View>
  );
};

export { ManageSubscriptionView };
