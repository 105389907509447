import { type FC, type RefObject, useEffect, useRef } from 'react';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';
import { faInstagram, faSpotify, faTiktok } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { faGlobe } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import { useAuthContext } from '../../contexts/AuthContext';

import { useMenuContainer } from '../../contexts/MenuContext';
import type { TierTypename } from '../../graphql/generated';
import {
  ArtistMainVaultViewFragmentDoc,
  type FragmentType,
  getFragment,
} from '../../graphql/generated';
import { useAdminArtist } from '../../hooks/useAdminArtist';
import { useBetterGate } from '../../hooks/useFeatureGate';
import { useFreeTier } from '../../hooks/useFreeTier';
import { useIsVisible } from '../../hooks/useIsVisible';

import { VaultThemeStore } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { AuthCTABox } from '../auth/AuthCTABox';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { DeprecatedAllAccessPassWithForm } from '../vault/AllAccessPass';
import { ArtistLogo } from './items/artist/ArtistLogo';

export const VaultHeader: FC<{
  artist: FragmentType<ArtistMainVaultViewFragmentDoc>;
  setShowProfileImage: (header: boolean) => void;
  scrollRef: RefObject<HTMLDivElement>;
  isOwner: boolean;
  chatAvailableForFreeUsers: boolean;
  activeSubscriptionTier: TierTypename | undefined;
  onJoinFreeClick: () => void;
}> = ({
  artist: artistFrag,
  setShowProfileImage,
  scrollRef,
  onJoinFreeClick,
  activeSubscriptionTier,
  chatAvailableForFreeUsers,
  isOwner,
}) => {
  const { loginStatus } = useAuthContext();

  const { isVaultCustomizeOpen } = useMenuContainer();

  const vaultTheme = useSnapshot(VaultThemeStore);

  const visibleRef = useRef<HTMLDivElement | null>(null);

  const isVisible = useIsVisible({ scrollRef, visibleRef });

  const artist = getFragment(ArtistMainVaultViewFragmentDoc, artistFrag);
  const {
    id: artistId,
    name,
    mainVault: { id: vaultId, price },
    instagramHandle,
    customWebsiteUrl,
    spotifyUrl,
    tiktokHandle,
    linkValue,
  } = artist;

  const { isSubscribingFreeTier } = useFreeTier();

  useEffect(() => {
    setShowProfileImage(!isVisible);
  }, [isVisible, setShowProfileImage]);

  const monthlySubPrice = price ?? DEFAULT_PRICE;

  const showAllAccessPass = !isOwner && !activeSubscriptionTier;

  const hasUrl =
    instagramHandle != null ||
    spotifyUrl != null ||
    tiktokHandle != null ||
    customWebsiteUrl != null;

  const newAuth = useBetterGate('VAULT_NEW_SIGN_IN_WEB') === 'enabled';

  const adminArtist = useAdminArtist({
    artistId,
  });

  return (
    <View className={twMerge('flex flex-col gap-6', adminArtist ? 'mb-6' : 'mb-1')}>
      <View className="flex flex-col items-center gap-3">
        <View className="flex h-[70px] items-end">
          {!!vaultTheme.logoMediaUrl ? (
            <View className="flex w-full items-center justify-center" containerRef={visibleRef}>
              <ArtistLogo src={vaultTheme.logoMediaUrl} alt={name} />
            </View>
          ) : (
            <Text
              className="text-center font-title text-[38px] font-medium text-vault_text md2:text-[48px]"
              containerRef={visibleRef}
            >
              {vaultTheme.name ?? name}
            </Text>
          )}
        </View>

        {hasUrl && (
          <View className="flex flex-row gap-4">
            {customWebsiteUrl && (
              <Button
                label="globe"
                leadingIcon={faGlobe}
                href={customWebsiteUrl}
                isExternal
                disabled={isVaultCustomizeOpen}
                className="text-[24px] text-vault_text"
                iconOnly
                event={{
                  type: EVENTS.ARTIST_SOCIAL,
                  properties: {
                    vaultId,
                    artistId,
                    link: customWebsiteUrl,
                    type: 'other',
                  },
                }}
              />
            )}
            {spotifyUrl && (
              <Button
                label="spotify"
                className="text-[24px] text-vault_text"
                iconOnly
                disabled={isVaultCustomizeOpen}
                leadingIcon={faSpotify}
                isExternal
                href={spotifyUrl}
                event={{
                  type: EVENTS.ARTIST_SOCIAL,
                  properties: {
                    vaultId,
                    artistId,
                    link: spotifyUrl,
                    type: 'spotify',
                  },
                }}
              />
            )}
            {instagramHandle != null && (
              <Button
                label="instagram"
                className="text-[26px] text-vault_text"
                iconOnly
                disabled={isVaultCustomizeOpen}
                leadingIcon={faInstagram}
                isExternal
                href={`https://www.instagram.com/${instagramHandle.replace('@', '')}`}
                event={{
                  type: EVENTS.ARTIST_SOCIAL,
                  properties: {
                    vaultId,
                    artistId,
                    link: `https://www.instagram.com/${instagramHandle.replace('@', '')}`,
                    type: 'instagram',
                  },
                }}
              />
            )}
            {tiktokHandle != null && (
              <Button
                label="tiktok"
                className="text-[26px] text-vault_text"
                iconOnly
                disabled={isVaultCustomizeOpen}
                leadingIcon={faTiktok}
                isExternal
                href={`https://www.tiktok.com/@${tiktokHandle.replace('@', '')}`}
                event={{
                  type: EVENTS.ARTIST_SOCIAL,
                  properties: {
                    vaultId,
                    artistId,
                    link: `https://www.tiktok.com/@${tiktokHandle.replace('@', '')}`,
                    type: 'tiktok',
                  },
                }}
              />
            )}
          </View>
        )}
      </View>

      {showAllAccessPass &&
        (newAuth ? (
          <AuthCTABox type="inner-circle-vault" artist={artist} fill />
        ) : (
          <View
            className={twMerge(
              'mb-6 w-full',
              loginStatus === LoginStatus.LOGGED_IN ? '-mb-2' : 'mb-0',
            )}
          >
            <DeprecatedAllAccessPassWithForm
              chatAvailableForFreeUsers={chatAvailableForFreeUsers}
              isSubscribingFreeTier={isSubscribingFreeTier}
              linkValue={linkValue}
              monthlySubPrice={monthlySubPrice}
              onJoinFreeClick={onJoinFreeClick}
            />
          </View>
        ))}
    </View>
  );
};
