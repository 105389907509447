import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router';
import { useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { EditMassMessageView } from '../../../components/massMessage/EditMassMessageView';
import { FullPageLoading } from '../../../components/views/FullPageLoading';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ImagesAttachment } from '../../../contexts/ImagesMessageAttachmentContext';
import { VideoAttachment } from '../../../contexts/VideoMessageAttachmentContext';
import { useQuery } from '../../../graphql/client';
import { GetAnnouncementDocument, MediaType } from '../../../graphql/generated';
import { useMassMessageForm } from '../../../hooks/message/useMassMessageForm';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useOwnerOnlyAccess } from '../../../hooks/useOwnerOnlyAcess';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { useWindow } from '../../../hooks/useWindow';
import { LoginStatus } from '../../../types/authTypes';
import { getFromList, getManyFromList } from '../../../utils/arrayUtils';

gql(/* GraphQL */ `
  query GetAnnouncement($announcementId: UUID!) {
    announcementById(announcementId: $announcementId) {
      id
      content
      scheduledAt
      featureAccess {
        feature {
          __typename
        }
      }
      artistEventTarget {
        id
        title
      }
      locationTargetV2 {
        type
        __typename
        ... on AnnouncementTargetingLocationV2Geo {
          displayName
          latitude
          longitude
          radiusMiles
          isoCountry
        }
        ... on AnnouncementTargetingLocationV2Region {
          isoCountry
          isoUsStateCode
        }
      }
      vaultContentAttachment {
        id
      }
      messageAttachments {
        id
        cdnUrl
        mediaType
      }
    }
  }
`);

export function EditMassMessagePage() {
  const { messageId } = useParams();
  const { artistHandle } = useArtistHandle();
  const { loggedInUser, loginStatus } = useAuthContext();

  const { isLg } = useWindow();

  useVaultTheme();

  useOwnerOnlyAccess();

  const {
    data: announcement,
    isLoading,
    isError,
  } = useQuery(GetAnnouncementDocument, {
    variables: !!messageId && { announcementId: messageId },
    staleTime: Infinity,
    enabled: !!loggedInUser,
    select: data => data.data.announcementById,
  });

  const artist = loggedInUser?.adminArtists?.find(({ artistLinks }) =>
    artistLinks.includes(artistHandle ?? ''),
  );

  const { clearErrors, clearFields } = useMassMessageForm({ vaultId: artist?.artistMainVaultId });

  const { clearVideo } = useSnapshot(VideoAttachment);
  const { clearImages } = useSnapshot(ImagesAttachment);

  const {
    setField,
    fields: { fetchedAnnouncementId },
  } = useMassMessageForm({ vaultId: artist?.artistMainVaultId });

  useEffect(() => {
    if (isLoading || isError || !announcement || !messageId || fetchedAnnouncementId === messageId)
      return;

    if (!!announcement.content) {
      setField('message', announcement.content);
    }

    if (!!announcement.artistEventTarget) {
      setField(
        'drops',
        announcement.artistEventTarget.map(({ id, title }) => ({ id, name: title ?? '' })),
      );
    }

    if (!!announcement.locationTargetV2) {
      setField(
        'locationsV2',
        announcement.locationTargetV2.map(location => {
          if (location.__typename === 'AnnouncementTargetingLocationV2Geo') {
            return {
              type: 'GEO' as const,
              displayName: location.displayName,
              latitude: location.latitude,
              longitude: location.longitude,
              radiusMiles: location.radiusMiles,
              isoCountry: location.isoCountry,
            };
          }

          return {
            type: 'REGION' as const,
            isoCountry: location.isoCountry,
            isoUsStateCode: location.isoUsStateCode,
          };
        }),
      );
    }

    if (!!announcement.featureAccess) {
      setField(
        'memberType',
        announcement.featureAccess.map(({ feature }) =>
          feature.__typename === 'FreeScheduledEvent' ? 'FREE_EVENT' : 'PAID_EVENT',
        ),
      );
    }

    if (!!announcement.scheduledAt) {
      setField('date', new Date(announcement.scheduledAt));
    }

    if (!!announcement.vaultContentAttachment) {
      setField('attachedTrackId', announcement.vaultContentAttachment.id);
    }

    if (!!announcement.messageAttachments) {
      const video = getFromList(
        announcement.messageAttachments,
        attachment => attachment.mediaType === MediaType.Video && attachment,
      );

      const images = getManyFromList(
        announcement.messageAttachments,
        attachment => attachment.mediaType === MediaType.Image && attachment,
      );

      if (video) {
        const incomingVideo = {
          identifier: video.id,
          progress: 0,
          objectUrl: video.cdnUrl,
          uploaded: true,
          mediaId: video.id,
          cdnUrl: video.cdnUrl,
          type: MediaType.Video,
        };
        VideoAttachment.video = incomingVideo;
      }

      const newImages = images.map(image => ({
        identifier: image.id,
        progress: 0,
        objectUrl: image.cdnUrl,
        uploaded: true,
        mediaId: image.id,
        cdnUrl: image.cdnUrl,
        type: MediaType.Image,
      }));

      ImagesAttachment.images = newImages;
    }

    setField('fetchedAnnouncementId', messageId);
  }, [announcement, fetchedAnnouncementId, isError, isLoading, messageId, setField]);

  useEffect(() => {
    return () => {
      if (isLg) {
        clearFields();
        clearErrors();
        clearImages();
        clearVideo();
      }
    };
  }, [clearErrors, clearFields, clearImages, clearVideo, isLg]);

  if (loginStatus === LoginStatus.LOADING || isLoading) {
    return <FullPageLoading withVaultTheme />;
  }

  if (!artist || !artistHandle || loginStatus === LoginStatus.LOGGED_OUT || !messageId || isError) {
    return <Navigate to={ROUTES.NOT_FOUND} replace />;
  }

  return (
    <EditMassMessageView
      artistHandle={artistHandle}
      mainVaultId={artist.artistMainVaultId}
      artistId={artist.artistId}
      artistProfileImageUrl={artist.artistProfileImage?.artistSmallProfileImageUrl ?? null}
      artistName={artist.artistName}
      announcementId={messageId}
      isEdit
    />
  );
}
