import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { resetActiveTrack } from '../../audio/AudioController';
import { BackButton } from '../../components/buttons/BackButton';
import { Text } from '../../components/common/Text';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { MediaUploadView } from '../../components/views/upload/MediaUploadView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { TrackUpload, useTrackUpload } from '../../contexts/TrackUploadContext';
import { VaultContentAccessFeatureInput } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';

export function MediaUploadPage() {
  const navigate = useNavigate();
  const { openBottomsheet } = useBottomsheetContainer();

  const { artistHandle } = useArtistHandle();
  const { fileRef, isPromptOpen } = useTrackUpload();

  useVaultTheme();

  const media = fileRef.current;

  useEffect(() => {
    if (!artistHandle) {
      navigate(ROUTES.NOT_FOUND);
      return;
    }

    if (!media && !isPromptOpen) {
      if (
        (window.history.state.idx != null && window.history.state.idx > 0) ||
        (window.history?.length && window.history.length > 1)
      ) {
        navigate(-1);
      } else {
        navigate(artistNavigationPath(artistHandle, '/vault'));
      }
    }
  }, [artistHandle, media, navigate, isPromptOpen]);

  useEffect(() => {
    return () => {
      resetActiveTrack();
    };
  }, []);

  const onBackClick = useStableCallback(() => {
    trackEvent({
      type: EVENTS.OPEN_BOTTOMSHEET,
      properties: { bottomsheetType: BOTTOMSHEET_TYPES.EXIT_FLOW, event: EVENTS.UPLOAD_TRACK },
    });

    openBottomsheet({
      type: 'EXIT_FLOW',
      exitFlowBottomsheetProps: {
        onConfirm: () => {
          TrackUpload.isUploadingTrack = false;
          TrackUpload.isPromptOpen = false;
          TrackUpload.fileRef.current = null;
          TrackUpload.title = '';
          TrackUpload.shouldSendSms = true;
          TrackUpload.caption = '';
          TrackUpload.notificationMessage = null;
          TrackUpload.featureAccessType = VaultContentAccessFeatureInput.FreeVaultContent;
        },
        event: EVENTS.UPLOAD_TRACK,
      },
    });
  });

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="bg-vault_background md2:rounded-t-[20px] md2:border md2:border-vault_text/5"
      headerLeft={<BackButton onClick={onBackClick} className="text-vault_text" />}
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">
          {media == null ? '' : `Upload ${media.contentType.toLowerCase()}`}
        </Text>
      }
      contentClassName="md2:bg-vault_text/3"
      showBorder
      stretch
    >
      <MediaUploadView />
    </DefaultLayout>
  );
}
