import * as z from 'zod';
import { PersistenceStorage } from '../../utils/storeUtils';

export const SignInStore = PersistenceStorage({
  schema: z.object({
    codeRenabled: z.record(z.number()),
    lastActivePhoneNumber: z.string().nullable(),
    lastCountryCode: z.string().optional(),

    phone: z.string().default(''),
    countryCode: z.string().default('US'),

    isOpen: z.boolean().default(false),

    errorText: z.string().nullable().default(null),
  }),
  key: 'SignInTimestamps',
  eager: true,
  extra: {
    setPhone: (phone: string) => {
      SignInStore.produceExistingState(
        draft => {
          draft.phone = phone;
        },
        {
          codeRenabled: {},
          lastActivePhoneNumber: null,
        },
      );
    },
    setCountryCode: (countryCode: string) => {
      SignInStore.produceExistingState(
        draft => {
          if (draft.lastCountryCode === countryCode) return;
          draft.countryCode = countryCode;
        },
        {
          codeRenabled: {},
          lastActivePhoneNumber: null,
        },
      );
    },
    setErrorText: (errorText: string | null) => {
      SignInStore.produceExistingState(
        draft => {
          if (draft.errorText === errorText) return;

          draft.errorText = errorText;
        },
        {
          codeRenabled: {},
          lastActivePhoneNumber: null,
        },
      );
    },
    setIsOpen: (isOpen: boolean) => {
      SignInStore.produceExistingState(
        draft => {
          draft.isOpen = isOpen;
        },
        {
          codeRenabled: {},
          lastActivePhoneNumber: null,
        },
      );
    },
  },
});

export const THIRTY_SECONDS_IN_MS = 30_000;
