import { setTimeout } from 'timers/promises';
import React, { useEffect, useMemo, useRef } from 'react';
import type { FC, ReactNode } from 'react';
import { debounce } from 'lodash-es';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { ENVIRONMENT } from '@soundxyz/utils/src/const';
import type { Paths } from '@soundxyz/vault-utils/paths';
import { MetatagsHeader } from '../components/metatags/MetatagsHeader';
import { FullPageLoading } from '../components/views/FullPageLoading';
import { ROUTES } from '../constants/routeConstants';
import { useAuthContext } from '../contexts/AuthContext';
import {
  useBottomsheetContainer,
  useExtraBottomsheetContainer,
} from '../contexts/BottomsheetContext';
import { fetchQuery } from '../graphql/client';
import {
  ArtistByHandleDocument,
  ArtistUserSettingsFeature,
  TierTypename,
} from '../graphql/generated';
import { CurrentArtistHandle, useCurrentArtistHandle } from '../hooks/useArtistHandle';
import { useClickThroughSmsCampaign } from '../hooks/useClickThroughSmsCampaign';
import { useRedirectUserOnboarding } from '../hooks/useRedirectUserOnboarding';
import { MainProvider } from '../providers/MainProvider';
import { AboutPage } from '../screens/AboutPage';
import { AllVaultsPage } from '../screens/AllVaultsPage';
import { EventInsightsPage } from '../screens/EventInsightsPage';
import { ExplorePage } from '../screens/ExplorePage';
import { InvitesPage } from '../screens/InvitesPage';
import { LandingPage } from '../screens/LandingPage';
import { NotFoundPage } from '../screens/NotFoundPage';
import { SeeDetailsMessageChannelPage } from '../screens/SeeDetailsMessageChannelPage';
import { SpotifyConnectPage } from '../screens/SpotifyConnect';
import { TestPage } from '../screens/TestPage';
import { TextMe } from '../screens/TextPage';
import { OnboardingUsernamePage } from '../screens/auth/OnboardingUsernamePage';
import { SignInPage } from '../screens/auth/SignInPage';
import { VerifyPage } from '../screens/auth/VerifyPage';
import { SubscribePage } from '../screens/payment/SubscribePage';
import { SubscribeSuccessPage } from '../screens/payment/SubscribeSuccessPage';
import { AccountManagementPage } from '../screens/settings/AccountManagement';
import { AddPaymentMethodPage } from '../screens/settings/AddPaymentMethodPage';
import { BillingPage } from '../screens/settings/BillingPage';
import { EarningsBreakdownPage } from '../screens/settings/EarningsBreakdownPage';
import { EarningsPage } from '../screens/settings/EarningsPage';
import { EditArtistProfilePage } from '../screens/settings/EditArtistPage';
import { EditSubscriberProfilePage } from '../screens/settings/EditSubscriberProfilePage';
import { ManagePaymentMethodsPage } from '../screens/settings/ManagePaymentMethodsPage';
import { ManageSubscriptionPage } from '../screens/settings/ManageSubscriptionPage';
import { MySubscribersPage } from '../screens/settings/MySubscribersPage';
import { NotificationSettingsPage } from '../screens/settings/NotificationSettings';
import { PayoutsPage } from '../screens/settings/PayoutsPage';
import { SettingsPage } from '../screens/settings/SettingsPage';
import { SmsSettingsPage } from '../screens/settings/SmsSettingsPage';
import { SubscriptionsPage } from '../screens/settings/SubscriptionsPage';
import { TestUserSettingsPage } from '../screens/settings/TestUserSettingsPage';
import { UpdatePaymentMethodPage } from '../screens/settings/UpdatePaymentMethodPage';
import { ArtistNotificationsSettingsScreen } from '../screens/settings/notifications/ArtistNotificationsSettings';
import { FeatureArtistSettings } from '../screens/settings/notifications/FeatureSettings';
import { PushNotificationSettings } from '../screens/settings/notifications/PushNotificationSettings';
import { SmsNotificationSettingsPage } from '../screens/settings/notifications/SmsNotificationSettings';
import { EditSnippetPage } from '../screens/vault/EditSnippetPage';
import { EditTrackPage } from '../screens/vault/EditTrackPage';
import { FolderPage } from '../screens/vault/FolderPage';
import { MediaLandingPage } from '../screens/vault/MediaLandingPage';
import { MediaUploadPage } from '../screens/vault/MediaUpload';
import { MessagesPage } from '../screens/vault/MessagesPage';
import { MoveToPage } from '../screens/vault/MoveToPage';
import { TrackLandingPage } from '../screens/vault/TrackLandingPage';
import { VaultPage } from '../screens/vault/VaultPage';
import { CampaignPage } from '../screens/vault/campaign/CampaignPage';
import { ClaimReceiptPage } from '../screens/vault/campaign/ClaimReceiptPage';
import { CreateCampaignPage } from '../screens/vault/campaign/CreateCampaignPage';
import { EditCampaignPage } from '../screens/vault/campaign/EditCampaignPage';
import { ClaimPage } from '../screens/vault/drops/ClaimPage';
import { DropPage } from '../screens/vault/drops/DropPage';
import { CreateRsvpPage } from '../screens/vault/drops/rsvp/CreateRsvpPage';
import { EditRsvpPage } from '../screens/vault/drops/rsvp/EditRsvpPage';
import { ArtistDashboard } from '../screens/vault/membership/ArtistDashboard';
import { EventsPage } from '../screens/vault/membership/EventsPage';
import { MembershipPage } from '../screens/vault/membership/MembershipPage';
import { ReceiptsPage } from '../screens/vault/membership/ReceiptsPage';
import { CreateMassMessagePage } from '../screens/vault/message/CreateMassMessagePage';
import { EditMassMessagePage } from '../screens/vault/message/EditMassMessagePage';
import { FilterMembersScreen } from '../screens/vault/message/FilterMembersScreen';
import { MassMessageInsightsPage } from '../screens/vault/message/MassMessageInsightsPage';
import { MassMessagePreviewPage } from '../screens/vault/message/MassMessagePreviewPage';
import { MassMessagesPage } from '../screens/vault/message/MassMessagesPage';
import { ShareContentPage } from '../screens/vault/share/ShareContentPage';
import { ShareDropPage } from '../screens/vault/share/ShareDropPage';
import { SortVaultContent } from '../screens/vault/sortVault/SortVaultContent';
import { Sentry } from '../sentry';
import { LoginStatus } from '../types/authTypes';
import type { BottomsheetType } from '../types/bottomsheetTypes';
import { CurrentPageState, sendPageAnalytics } from '../utils/analyticsUtils';
import { artistNavigationPath } from '../utils/navigationUtils';
import { getSubdomain, isValidSubdomain } from '../utils/subdomainUtils';

type Props = { children?: ReactNode };

export const MainRouter: FC<Props> = ({ children }) => {
  return (
    <BrowserRouter>
      <MainProvider>
        {children}
        <RoutesInternal />
      </MainProvider>
    </BrowserRouter>
  );
};

const useSubdomainNavigationHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const savedPathName = useRef(location.pathname);

  useEffect(() => {
    const handleSubdomainNavigation = () => {
      const currentPath = location.pathname;

      if (isValidSubdomain()) {
        const subdomain = getSubdomain();

        const routesToRemoveSubdomain = [
          ROUTES.VAULTS,
          ROUTES.EXPLORE,
          ROUTES.ABOUT,
          ROUTES.SETTINGS,
        ];

        const shouldRemoveSubdomain = routesToRemoveSubdomain.some(route =>
          currentPath.startsWith(route),
        );

        if (shouldRemoveSubdomain) {
          const newPath = currentPath.replace(subdomain + '.', '');
          const newUrl = `${window.location.protocol}//${window.location.host.replace(`${subdomain}.`, '')}${newPath}`;

          window.location.href = newUrl;
        }
      }
      savedPathName.current = currentPath;
    };

    handleSubdomainNavigation();
  }, [location.pathname, navigate]);
};

const useNavigationEvent = () => {
  const { pathname } = useLocation();
  const savedPathName = useRef(location.pathname);
  const savedUserId = useRef<string | null>(null);
  const savedOpenBottomsheet = useRef<BottomsheetType | null>(null);
  const previousAnalyticsPage = useRef(null);
  const [searchParams] = useSearchParams();

  const { artistHandle } = useCurrentArtistHandle();

  const { loggedInUser } = useAuthContext();

  const userId = loggedInUser?.id ?? null;

  useEffect(() => {
    savedUserId.current = userId;
  }, [userId]);

  const { extraBottomsheetProps } = useExtraBottomsheetContainer();
  const { bottomsheetProps } = useBottomsheetContainer();

  const openBottomsheet = extraBottomsheetProps?.type ?? bottomsheetProps?.type ?? null;

  const sendAnalytics = useMemo(() => {
    return debounce(
      async ({
        pathname,
        searchParams,
        artistHandle,
        userId,
        openBottomsheet,
      }: {
        pathname: string;
        searchParams: URLSearchParams;
        artistHandle: string | null;
        userId: string | null;
        openBottomsheet: BottomsheetType | null;
      }) => {
        if (
          savedPathName.current !== pathname ||
          previousAnalyticsPage.current === null ||
          savedUserId.current !== userId ||
          savedOpenBottomsheet.current !== openBottomsheet
        ) {
          const query: { [key: string]: string } = {};
          for (const entry of searchParams.entries()) {
            const key = entry[0];
            query[key] = entry[1];
          }

          const vaultSubscriptionStatus = artistHandle
            ? await Promise.race([
                setTimeout(500).then(() => 'unknown'),
                fetchQuery(ArtistByHandleDocument, {
                  variables: { link: artistHandle },
                  staleTime: Infinity,
                })
                  .then(data =>
                    data.data.artistLink?.artist.mainVault.activeSubscription != null
                      ? data.data.artistLink.artist.mainVault.activeSubscription.tier.__typename ===
                        TierTypename.PaidTier
                        ? 'paid-subbed'
                        : 'subbed'
                      : 'unsubbed',
                  )
                  .catch(() => 'unknown'),
              ])
            : null;

          sendPageAnalytics({
            pathname,
            previousAnalyticsPage: previousAnalyticsPage,
            searchParams: query,
            vaultArtistHandle: artistHandle,
            vaultSubscriptionStatus,
            userId,
            openBottomsheet,
          });

          savedPathName.current = location.pathname;
          savedUserId.current = userId;
          savedOpenBottomsheet.current = openBottomsheet;
        }
      },
      10,
      { trailing: true },
    );
  }, []);

  useEffect(() => {
    sendAnalytics({ pathname, searchParams, artistHandle, userId, openBottomsheet });

    return sendAnalytics.cancel;
  }, [pathname, searchParams, sendAnalytics, artistHandle, userId, openBottomsheet]);
};

const useSmsCampaignResponseEvent = () => {
  const { clickThroughSmsCampaign } = useClickThroughSmsCampaign();
  const [searchParams] = useSearchParams();
  const smsCampaignResponseShortcode = searchParams.get('c');

  useEffect(() => {
    if (smsCampaignResponseShortcode) {
      clickThroughSmsCampaign({ shortcode: smsCampaignResponseShortcode }).catch(error => {
        Sentry.captureException(error, {
          extra: {
            message: 'Error in clickThroughSmsCampaign mutation',
            shortcode: smsCampaignResponseShortcode,
          },
          tags: {
            type: 'clickThroughSmsCampaignMutation',
          },
        });
      });
    }
  }, [clickThroughSmsCampaign, smsCampaignResponseShortcode]);
};

const RouteComponent = function RouteComponent({
  children,
  page,
  isArtistComponent,
}: {
  children: ReactNode;
  page: Paths | 'UNMAPPED';
  isArtistComponent?: boolean;
}) {
  if (CurrentPageState.page !== page) {
    CurrentPageState.page = page;
  }

  const { artistHandle } = useParams();

  if (isArtistComponent) {
    if (artistHandle && CurrentArtistHandle.artistHandle !== artistHandle) {
      CurrentArtistHandle.artistHandle = artistHandle;
    }
  } else if (!CurrentArtistHandle.isSubdomain && CurrentArtistHandle.artistHandle) {
    CurrentArtistHandle.artistHandle = null;
  }

  return children;
};

const generateVaultRoutes = (loginStatus: LoginStatus) => (
  <>
    <Route
      index
      element={
        <RouteComponent page="/" isArtistComponent>
          <VaultPage />
        </RouteComponent>
      }
    />

    <Route
      path="upload"
      element={
        <RouteComponent page="/[artist]/upload" isArtistComponent>
          {loginStatus === LoginStatus.LOADING ? (
            <FullPageLoading withVaultTheme />
          ) : (
            <MediaUploadPage />
          )}
        </RouteComponent>
      }
    />

    <Route path="event">
      <Route
        path="insights/:eventId"
        element={
          <RouteComponent page="/[artist]/event/insights/:eventId" isArtistComponent>
            <EventInsightsPage />
          </RouteComponent>
        }
      />
    </Route>
    <Route path="campaign">
      <Route
        path="create"
        element={
          <RouteComponent page="/[artist]/campaign/create" isArtistComponent>
            <CreateCampaignPage />
          </RouteComponent>
        }
      />
      <Route
        path="edit/:campaignId"
        element={
          <RouteComponent page="/[artist]/campaign/edit/:campaignId" isArtistComponent>
            <EditCampaignPage />
          </RouteComponent>
        }
      />
    </Route>
    <Route path="rsvp">
      <Route
        path="create"
        element={
          <RouteComponent page="/[artist]/rsvp/create" isArtistComponent>
            <CreateRsvpPage />
          </RouteComponent>
        }
      />
      <Route
        path="edit/:eventId"
        element={
          <RouteComponent page="/[artist]/rsvp/edit/:eventId" isArtistComponent>
            <EditRsvpPage />
          </RouteComponent>
        }
      />
    </Route>
    <Route
      path="edit/:vaultContentId"
      element={
        <RouteComponent page="/[artist]/edit/:vaultContentId" isArtistComponent>
          {loginStatus === LoginStatus.LOADING ? (
            <FullPageLoading withVaultTheme />
          ) : (
            <EditTrackPage />
          )}
        </RouteComponent>
      }
    />
    <Route
      path="snippet/:vaultContentId"
      element={
        <RouteComponent page="/[artist]/snippet/:vaultContentId" isArtistComponent>
          {loginStatus === LoginStatus.LOADING ? (
            <FullPageLoading withVaultTheme />
          ) : (
            <EditSnippetPage />
          )}
        </RouteComponent>
      }
    />
    <Route path="messages">
      <Route
        index
        element={
          <RouteComponent page="/[artist]/messages" isArtistComponent>
            {loginStatus === LoginStatus.LOADING ? (
              <FullPageLoading withVaultTheme />
            ) : (
              <MessagesPage showOnMobile="list" defaultOpen="chat" />
            )}
          </RouteComponent>
        }
      />
      <Route
        path="dm"
        element={
          <RouteComponent page="/[artist]/messages/dm" isArtistComponent>
            {loginStatus === LoginStatus.LOADING ? (
              <FullPageLoading withVaultTheme />
            ) : (
              <MessagesPage showOnMobile="list" defaultOpen="dm" />
            )}
          </RouteComponent>
        }
      />
      <Route
        path="details"
        element={
          <RouteComponent page="/[artist]/messages/details" isArtistComponent>
            <SeeDetailsMessageChannelPage />
          </RouteComponent>
        }
      />
      <Route
        path=":channelId"
        element={
          <RouteComponent page="/[artist]/messages/[id]" isArtistComponent>
            <MessagesPage showOnMobile="detail" defaultOpen="chat" />
          </RouteComponent>
        }
      />
      <Route
        path=":channelId/details"
        element={
          <RouteComponent page="/[artist]/messages/[id]/details" isArtistComponent>
            <SeeDetailsMessageChannelPage />
          </RouteComponent>
        }
      />
      <Route path="insights">
        <Route
          index
          element={
            <RouteComponent page="/[artist]/messages/insights" isArtistComponent>
              <MassMessagesPage />
            </RouteComponent>
          }
        />
        <Route
          path=":messageId"
          element={
            <RouteComponent page="/[artist]/messages/insights/[id]" isArtistComponent>
              <MassMessageInsightsPage />
            </RouteComponent>
          }
        />
      </Route>
      <Route path="edit">
        <Route
          path=":messageId"
          element={
            <RouteComponent page="/[artist]/messages/edit/[id]" isArtistComponent>
              <EditMassMessagePage />
            </RouteComponent>
          }
        />
        <Route
          path="preview"
          element={
            <RouteComponent page="/[artist]/messages/edit/preview" isArtistComponent>
              <MassMessagePreviewPage />
            </RouteComponent>
          }
        />
        <Route path="filter" element={<FilterMembersScreen />} />
      </Route>
      <Route path="create">
        <Route
          index
          element={
            <RouteComponent page="/[artist]/messages/create" isArtistComponent>
              <CreateMassMessagePage />
            </RouteComponent>
          }
        />
        <Route
          path="preview"
          element={
            <RouteComponent page="/[artist]/messages/create/preview" isArtistComponent>
              <MassMessagePreviewPage />
            </RouteComponent>
          }
        />
        <Route
          path="filter"
          element={
            <RouteComponent page="/[artist]/messages/create/filter" isArtistComponent>
              <FilterMembersScreen />
            </RouteComponent>
          }
        />
      </Route>
    </Route>
    <Route path="chat">
      <Route
        index
        element={
          <RouteComponent page="/[artist]/messages/chat" isArtistComponent>
            <MessagesPage showOnMobile="detail" defaultOpen="chat" />
          </RouteComponent>
        }
      />
    </Route>

    <Route
      path=":vaultContentId"
      element={
        <RouteComponent page="/[artist]/[vaultContentSlug]" isArtistComponent>
          <TrackLandingPage />
        </RouteComponent>
      }
    />
    <Route path="t/:vaultContentSlug">
      <Route
        index
        element={
          <RouteComponent page="/[artist]/t/[id]" isArtistComponent>
            <TrackLandingPage />
          </RouteComponent>
        }
      />
      <Route
        path="share"
        element={
          <RouteComponent page="/[artist]/t/[id]/share" isArtistComponent>
            <ShareContentPage />
          </RouteComponent>
        }
      />
    </Route>
    <Route
      path="subscribe"
      element={
        <RouteComponent page="/[artist]/subscribe" isArtistComponent>
          <SubscribePage />
        </RouteComponent>
      }
    />
    <Route
      path="success"
      element={
        <RouteComponent page="/[artist]/subscribe/success" isArtistComponent>
          <SubscribeSuccessPage />
        </RouteComponent>
      }
    />
    <Route
      path="invites"
      element={
        <RouteComponent page="/[artist]/invites" isArtistComponent>
          <InvitesPage />
        </RouteComponent>
      }
    />
    <Route
      path="folder/:folderId"
      element={
        <RouteComponent page="/[artist]/folder/[id]" isArtistComponent>
          <FolderPage />
        </RouteComponent>
      }
    />
    <Route
      path="f/:folderSlug"
      element={
        <RouteComponent page="/[artist]/f/[id]" isArtistComponent>
          <FolderPage />
        </RouteComponent>
      }
    />
    <Route
      path="f/:vaultContentSlug/share"
      element={
        <RouteComponent page="/[artist]/f/[id]/share" isArtistComponent>
          <ShareContentPage />
        </RouteComponent>
      }
    />
    <Route path="i/:vaultContentSlug">
      <Route
        index
        element={
          <RouteComponent page="/[artist]/i/[id]" isArtistComponent>
            <MediaLandingPage />
          </RouteComponent>
        }
      />
      <Route
        path="share"
        element={
          <RouteComponent page="/[artist]/i/[id]/share" isArtistComponent>
            <ShareContentPage />
          </RouteComponent>
        }
      />
    </Route>
    <Route path="v/:vaultContentSlug">
      <Route
        index
        element={
          <RouteComponent page="/[artist]/v/[id]" isArtistComponent>
            <MediaLandingPage />
          </RouteComponent>
        }
      />
      <Route
        path="share"
        element={
          <RouteComponent page="/[artist]/v/[id]/share" isArtistComponent>
            <ShareContentPage />
          </RouteComponent>
        }
      />
    </Route>
    <Route
      path="image/:vaultContentId"
      element={
        <RouteComponent page="/[artist]/image/[id]" isArtistComponent>
          <MediaLandingPage />
        </RouteComponent>
      }
    />
    <Route
      path="video/:vaultContentId"
      element={
        <RouteComponent page="/[artist]/video/[id]" isArtistComponent>
          <MediaLandingPage />
        </RouteComponent>
      }
    />
    <Route
      path="s/:campaignSlugOrId"
      element={
        <RouteComponent page="/[artist]/s/[id]" isArtistComponent>
          <CampaignPage />
        </RouteComponent>
      }
    />
    <Route
      path="s/:campaignSlugOrId/claim"
      element={
        <RouteComponent page="/[artist]/s/[id]/claim" isArtistComponent>
          <ClaimReceiptPage />
        </RouteComponent>
      }
    />
    <Route
      path="s/:dropSlug/share"
      element={
        <RouteComponent page="/[artist]/s/[id]/share" isArtistComponent>
          <ShareDropPage type="campaign" />
        </RouteComponent>
      }
    />
    <Route
      path="d/:dropSlug"
      element={
        <RouteComponent page="/[artist]/d/[id]" isArtistComponent>
          <DropPage />
        </RouteComponent>
      }
    />
    <Route
      path="d/:dropSlug/claim"
      element={
        <RouteComponent page="/[artist]/d/[id]/claim" isArtistComponent>
          <ClaimPage />
        </RouteComponent>
      }
    />
    <Route
      path="d/:dropSlug/share"
      element={
        <RouteComponent page="/[artist]/d/[id]/share" isArtistComponent>
          <ShareDropPage type="rsvp" />
        </RouteComponent>
      }
    />
    <Route
      path="move-to/:folderId?"
      element={
        <RouteComponent page="/[artist]/move-to/[id]" isArtistComponent>
          <MoveToPage />
        </RouteComponent>
      }
    />
    <Route
      path="rearrange/:folderId?"
      element={
        <RouteComponent page="/[artist]/rearrange/[id]" isArtistComponent>
          <SortVaultContent />
        </RouteComponent>
      }
    />
    <Route
      path="vault"
      element={
        <RouteComponent page="/[artist]/vault" isArtistComponent>
          <VaultPage />
        </RouteComponent>
      }
    />
    <Route
      path="dashboard"
      element={
        <RouteComponent page="/[artist]/dashboard" isArtistComponent>
          {loginStatus === LoginStatus.LOADING ? (
            <FullPageLoading withVaultTheme />
          ) : (
            <ArtistDashboard />
          )}
        </RouteComponent>
      }
    />
    <Route path="drops">
      <Route
        index
        element={
          <RouteComponent page="/[artist]/drops" isArtistComponent>
            {loginStatus === LoginStatus.LOADING ? (
              <FullPageLoading withVaultTheme />
            ) : (
              <MembershipPage />
            )}
          </RouteComponent>
        }
      />
      <Route
        path="events"
        element={
          <RouteComponent page="/[artist]/drops/events" isArtistComponent>
            <EventsPage />
          </RouteComponent>
        }
      />
      <Route
        path="receipts"
        element={
          <RouteComponent page="/[artist]/drops/receipts" isArtistComponent>
            <ReceiptsPage />
          </RouteComponent>
        }
      />
    </Route>
    <Route
      path="textme"
      element={
        <RouteComponent page="/[artist]/textme" isArtistComponent>
          <TextMe />
        </RouteComponent>
      }
    />
    <Route
      path="members"
      element={
        <RouteComponent page="/[artist]/members" isArtistComponent>
          <MySubscribersPage />
        </RouteComponent>
      }
    />
  </>
);

const RoutesInternal: FC = () => {
  const { loginStatus, isArtist, loggedInUser } = useAuthContext();

  useNavigationEvent();
  useRedirectUserOnboarding();
  useSubdomainNavigationHandler();
  useSmsCampaignResponseEvent();

  const isDev = ENVIRONMENT === 'development';

  if (isValidSubdomain()) {
    return (
      <Routes>
        <Route
          path={ROUTES.SIGN_IN}
          element={
            <RouteComponent page="/sign-in">
              <SignInPage />
            </RouteComponent>
          }
        />
        <Route
          path={ROUTES.VERIFY}
          element={
            <RouteComponent page="/verify">
              <VerifyPage />
            </RouteComponent>
          }
        />
        <Route path={ROUTES.ONBOARDING}>
          <Route
            index
            element={
              <RouteComponent page="/onboarding">
                <NotFoundPage />
              </RouteComponent>
            }
          />
          <Route
            path="username"
            element={
              <RouteComponent page="/onboarding/username">
                <OnboardingUsernamePage />
              </RouteComponent>
            }
          />
        </Route>
        {generateVaultRoutes(loginStatus)}
        <Route
          path={ROUTES.EXPLORE}
          element={
            <RouteComponent page="/explore">
              <FullPageLoading withVaultTheme={false} />
            </RouteComponent>
          }
        />
        <Route
          path={ROUTES.VAULTS}
          element={
            <RouteComponent page="/vaults">
              <FullPageLoading withVaultTheme={false} />
            </RouteComponent>
          }
        />
        <Route
          path={ROUTES.ABOUT}
          element={
            <RouteComponent page="/about">
              <FullPageLoading withVaultTheme={false} />
            </RouteComponent>
          }
        />
        <Route
          path={ROUTES.SETTINGS}
          element={
            <RouteComponent page="/settings">
              <FullPageLoading withVaultTheme={false} />
            </RouteComponent>
          }
        />
        <Route
          path={ROUTES.SPOTIFY_CONNECT}
          element={
            <RouteComponent page="/spotify-connect">
              <SpotifyConnectPage />
            </RouteComponent>
          }
        />
        <Route
          path={ROUTES.NOT_FOUND}
          element={
            <RouteComponent page="/not-found">
              <NotFoundPage />
            </RouteComponent>
          }
        />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route
          path={ROUTES.SIGN_IN}
          element={
            <RouteComponent page="/sign-in">
              <SignInPage />
            </RouteComponent>
          }
        />
        <Route
          path={ROUTES.VERIFY}
          element={
            <RouteComponent page="/verify">
              <VerifyPage />
            </RouteComponent>
          }
        />
        <Route
          path={ROUTES.SPOTIFY_CONNECT}
          element={
            <RouteComponent page="/spotify-connect">
              <SpotifyConnectPage />
            </RouteComponent>
          }
        />
        <Route
          path={ROUTES.VAULTS}
          element={
            <RouteComponent page="/vaults">
              {loginStatus === LoginStatus.LOADING ? (
                <FullPageLoading withVaultTheme={false} />
              ) : loginStatus === LoginStatus.LOGGED_IN ? (
                <AllVaultsPage />
              ) : (
                <AllVaultsPage />
              )}
            </RouteComponent>
          }
        />
        {(loginStatus !== LoginStatus.LOGGED_OUT || isDev) && (
          <Route
            path="/test"
            element={
              <RouteComponent page="UNMAPPED">
                {loginStatus === LoginStatus.LOADING ? (
                  <FullPageLoading withVaultTheme={false} />
                ) : loggedInUser?.isInternal || isDev ? (
                  <TestPage />
                ) : (
                  <NotFoundPage />
                )}
              </RouteComponent>
            }
          />
        )}
        {loginStatus !== LoginStatus.LOGGED_OUT && (
          <>
            <Route path={ROUTES.ONBOARDING}>
              <Route
                index
                element={
                  <RouteComponent page="/onboarding">
                    <NotFoundPage />
                  </RouteComponent>
                }
              />
              <Route
                path="username"
                element={
                  <RouteComponent page="/onboarding/username">
                    <OnboardingUsernamePage />
                  </RouteComponent>
                }
              />
            </Route>
            <Route path={ROUTES.SETTINGS}>
              <Route
                index
                element={
                  <RouteComponent page="/settings">
                    <SettingsPage />
                  </RouteComponent>
                }
              />
              <Route
                path="notification"
                element={
                  <RouteComponent page="/settings/notification">
                    {loginStatus === LoginStatus.LOADING ? (
                      <FullPageLoading withVaultTheme={false} />
                    ) : (
                      <NotificationSettingsPage />
                    )}
                  </RouteComponent>
                }
              />
              <Route
                path="sms-notification"
                element={
                  <RouteComponent page="/settings/sms-notification">
                    <SmsNotificationSettingsPage />
                  </RouteComponent>
                }
              />
              <Route
                path="push-notification"
                element={
                  <RouteComponent page="/settings/push-notification">
                    <PushNotificationSettings />
                  </RouteComponent>
                }
              />
              <Route
                path="test-user-settings"
                element={
                  <RouteComponent page="UNMAPPED">
                    <TestUserSettingsPage />
                  </RouteComponent>
                }
              />
              <Route path="artist-notification/:artistHandle">
                <Route
                  index
                  element={
                    <RouteComponent page="/settings/artist-notification/[id]">
                      <ArtistNotificationsSettingsScreen />
                    </RouteComponent>
                  }
                />
                <Route
                  path="dms"
                  element={
                    <RouteComponent page="/settings/dms">
                      <FeatureArtistSettings
                        notificationType={ArtistUserSettingsFeature.DmFromUser}
                      />
                    </RouteComponent>
                  }
                />
                <Route
                  path="artist-dms"
                  element={
                    <RouteComponent page="/settings/artist-dms">
                      <FeatureArtistSettings
                        notificationType={ArtistUserSettingsFeature.DmFromArtist}
                      />
                    </RouteComponent>
                  }
                />
                <Route
                  path="group-chats"
                  element={
                    <RouteComponent page="/settings/group-chats">
                      <FeatureArtistSettings
                        notificationType={ArtistUserSettingsFeature.GroupChatMessageFromArtist}
                      />
                    </RouteComponent>
                  }
                />
                <Route
                  path="content"
                  element={
                    <RouteComponent page="/settings/content">
                      <FeatureArtistSettings
                        notificationType={ArtistUserSettingsFeature.NewVaultContent}
                      />
                    </RouteComponent>
                  }
                />
                <Route
                  path="group-chat"
                  element={
                    <RouteComponent page="/settings/group-chat">
                      <FeatureArtistSettings
                        notificationType={ArtistUserSettingsFeature.GroupChatMessage}
                      />
                    </RouteComponent>
                  }
                />
              </Route>
              <Route
                path="account-management"
                element={
                  <RouteComponent page="/settings/account-management">
                    <AccountManagementPage />
                  </RouteComponent>
                }
              />
              <Route
                path="profile"
                element={
                  <RouteComponent page="/settings/profile">
                    <EditSubscriberProfilePage />
                  </RouteComponent>
                }
              />
              {isArtist && (
                <Route
                  path="profile-artist"
                  element={
                    <RouteComponent page="/settings/profile-artist">
                      <EditArtistProfilePage />
                    </RouteComponent>
                  }
                />
              )}
              <Route path="subscriptions">
                <Route
                  index
                  element={
                    <RouteComponent page="/settings/subscriptions">
                      <SubscriptionsPage />
                    </RouteComponent>
                  }
                />
                <Route
                  path=":subscriptionId"
                  element={
                    <RouteComponent page="/settings/subscriptions/[id]">
                      <ManageSubscriptionPage />
                    </RouteComponent>
                  }
                />
              </Route>
              <Route path="memberships">
                <Route
                  index
                  element={
                    <RouteComponent page="/settings/memberships">
                      <SubscriptionsPage />
                    </RouteComponent>
                  }
                />
                <Route
                  path=":subscriptionId"
                  element={
                    <RouteComponent page="/settings/memberships/[id]">
                      <ManageSubscriptionPage />
                    </RouteComponent>
                  }
                />
              </Route>
              <Route path="methods">
                <Route
                  index
                  element={
                    <RouteComponent page="/settings/methods">
                      <ManagePaymentMethodsPage />
                    </RouteComponent>
                  }
                />
                <Route
                  path="add"
                  element={
                    <RouteComponent page="/settings/methods/add">
                      <AddPaymentMethodPage />
                    </RouteComponent>
                  }
                />
                <Route
                  path="update"
                  element={
                    <RouteComponent page="/settings/methods/update">
                      <UpdatePaymentMethodPage />
                    </RouteComponent>
                  }
                />
              </Route>
              <Route
                path="billing"
                element={
                  <RouteComponent page="/settings/billing">
                    <BillingPage />
                  </RouteComponent>
                }
              />
              <Route
                path="earnings"
                element={
                  <RouteComponent page="/settings/earnings">
                    <EarningsPage isArtistPage={false} />
                  </RouteComponent>
                }
              />

              {isArtist && (
                <>
                  <Route
                    path="artist-earnings"
                    element={
                      <RouteComponent page="/settings/artist-earnings">
                        <EarningsPage isArtistPage />
                      </RouteComponent>
                    }
                  />
                  <Route
                    path="artist-earnings-breakdown"
                    element={
                      <RouteComponent page="/settings/artist-earnings-breakdown">
                        <EarningsBreakdownPage isArtistPage />
                      </RouteComponent>
                    }
                  />
                  <Route
                    path="members"
                    element={
                      <RouteComponent page="/settings/members">
                        <MySubscribersPage />
                      </RouteComponent>
                    }
                  />
                  <Route
                    path="sms-settings"
                    element={
                      <RouteComponent page="/settings/sms-settings">
                        <SmsSettingsPage />
                      </RouteComponent>
                    }
                  />
                </>
              )}
              <Route
                path="payouts"
                element={
                  <RouteComponent page="/settings/payouts">
                    <PayoutsPage />
                  </RouteComponent>
                }
              />
            </Route>
          </>
        )}
        <Route
          path={ROUTES.NOT_FOUND}
          element={
            <RouteComponent page="/not-found">
              <NotFoundPage />
            </RouteComponent>
          }
        />

        <Route path="/:artistHandle">{generateVaultRoutes(loginStatus)}</Route>

        <Route
          path={ROUTES.EXPLORE}
          element={
            <RouteComponent page="/explore">
              <ExplorePage />
            </RouteComponent>
          }
        />
        {loginStatus !== LoginStatus.LOADING && (
          <Route path="*" element={<Navigate to={ROUTES.NOT_FOUND} />} />
        )}
        <Route
          path={ROUTES.ABOUT}
          element={
            <RouteComponent page="/about">
              <AboutPage />
            </RouteComponent>
          }
        />
        <Route
          path={ROUTES.LANDING_PAGE}
          element={
            <RouteComponent page="/">
              {loginStatus === LoginStatus.LOADING ? (
                <FullPageLoading withVaultTheme={false} />
              ) : loginStatus !== LoginStatus.LOGGED_IN ? (
                <LandingPage />
              ) : loggedInUser?.artist?.mainLinkValue != null ? (
                <Navigate
                  to={artistNavigationPath(loggedInUser.artist.mainLinkValue, '/')}
                  replace
                />
              ) : (
                <Navigate to={ROUTES.VAULTS} replace />
              )}
            </RouteComponent>
          }
        />
      </Routes>
      <MetatagsHeader />
    </>
  );
};
