import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { twMerge } from 'tailwind-merge';
import { faCircleXmark, faSearch } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faClose } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { ERROR_TYPE, FEATURES, PILLARS } from '@soundxyz/vault-utils/dist/constants';
import {
  useBottomsheetContainer,
  useExtraBottomsheetContainer,
} from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import type { IsoCountry, IsousState } from '../../graphql/generated';
import { useLogError } from '../../hooks/logger/useLogError';
import type { MassMessageStateFields } from '../../hooks/message/useMassMessageForm';
import { useAdminArtist } from '../../hooks/useAdminArtist';
import { GeocoderResultSchema } from '../../hooks/useSelectLocation';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useWindow } from '../../hooks/useWindow';
import type { AddLocationBottomsheetProps } from '../../types/bottomsheetTypes';
import { findOrFetchGeocoderResult, isIsoCountry, isIsoState } from '../../utils/mapUtils';
import { BackButton } from '../buttons/BackButton';
import { Button } from '../buttons/Button';
import { LocationMap } from '../common/LocationMap';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ViewHeader } from '../common/ViewHeader';
import {
  GoogleLocationRow,
  type GoogleLocationRowProps,
  InitialLocationRow,
  useMemberCountsByLocation,
} from '../massMessage/GoogleLocationRow';

const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

if (!GOOGLE_API_KEY) {
  throw new Error('VITE_GOOGLE_MAPS_API_KEY is not defined');
}

export function AddLocationBottomsheet({
  artistHandle,
  onAddLocation,
  initialLocation,
  removeLocation,
}: AddLocationBottomsheetProps) {
  const { isDesktop } = useWindow();
  const geocoder = useRef<google.maps.Geocoder>(new google.maps.Geocoder());

  const { closeBottomsheet } = useBottomsheetContainer();
  const { closeExtraBottomsheet } = useExtraBottomsheetContainer();
  const adminArtist = useAdminArtist({ artistHandle });

  const [location, setLocation] = useState<{
    latitude: number;
    longitude: number;
    radiusMiles: number;
  } | null>(initialLocation?.type === 'GEO' ? initialLocation : null);

  const onClickBack = useStableCallback(() => {
    if (isDesktop) {
      closeExtraBottomsheet();
    } else {
      closeBottomsheet();
    }
  });

  const [country, setCountry] = useState<IsoCountry | null>(initialLocation?.isoCountry ?? null);
  const [address, setAddress] = useState<string | null>(
    initialLocation?.type === 'GEO' ? initialLocation.displayName : null,
  );

  /* Preload member counts by location */
  useMemberCountsByLocation({ artistId: adminArtist?.artistId });

  useEffect(() => {
    if (adminArtist == null) {
      if (isDesktop) {
        closeExtraBottomsheet();
      } else {
        closeBottomsheet();
      }
    }
  }, [adminArtist, closeBottomsheet, closeExtraBottomsheet, isDesktop]);

  if (adminArtist == null) {
    return null;
  }

  if (location == null) {
    return (
      <AddBottomsheetLayout
        removeLocation={removeLocation}
        initialLocation={initialLocation}
        onClickBack={onClickBack}
        bottomClassName="hidden"
        addEnabled={undefined}
        onAddClick={null}
      >
        <SearchLocation
          initialLocation={initialLocation?.type === 'REGION' ? initialLocation : null}
          onSelectLocation={({ location }) => {
            if (location.type === 'GEO') {
              setAddress(location.displayName);
              setCountry(location.isoCountry);
              setLocation({
                latitude: location.latitude,
                longitude: location.longitude,
                radiusMiles: location.radiusMiles,
              });
              return;
            }

            onAddLocation(location);
            onClickBack();
          }}
          geocoder={geocoder.current}
          artistId={adminArtist.artistId}
        />
      </AddBottomsheetLayout>
    );
  }

  return (
    <AddBottomsheetLayout
      removeLocation={removeLocation}
      initialLocation={initialLocation}
      onClickBack={onClickBack}
      addEnabled={location != null && country != null}
      onAddClick={() => {
        if (location == null || country == null) {
          return;
        }

        onAddLocation({
          type: 'GEO',
          latitude: location.latitude,
          longitude: location.longitude,
          radiusMiles: location.radiusMiles,
          displayName: address ?? '',
          isoCountry: country,
        });
        onClickBack();
      }}
      addButtonClassName="w-full md2:w-[unset]"
    >
      <View className="relative flex w-full flex-1 flex-col overflow-hidden rounded-lg">
        <LocationMap
          address={address ?? ''}
          countryCode={country}
          location={location}
          setLocation={setLocation}
          setAddress={setAddress}
          setCountryCode={setCountry}
        />
      </View>
    </AddBottomsheetLayout>
  );
}

function AddBottomsheetLayout({
  children,
  removeLocation,
  initialLocation,
  onClickBack,
  onAddClick,
  addEnabled = true,
  bottomClassName,
  addButtonClassName,
}: {
  children: React.ReactNode;
  removeLocation: (() => void) | null | undefined;
  initialLocation: MassMessageStateFields['locationsV2'][number] | null;
  onClickBack: () => void;
  onAddClick: (() => void) | null;
  addEnabled: boolean | undefined;
  bottomClassName?: string;
  addButtonClassName?: string;
}) {
  return (
    <View className="flex h-[calc(100vh-100px)] w-full flex-col items-start gap-4 md2:w-[513px]">
      <View className="flex w-full flex-row items-center justify-between">
        <BackButton onClick={onClickBack} className="hidden text-[24px] text-vault_text md2:flex" />
        {removeLocation != null && initialLocation != null && (
          <Button
            label="Remove"
            onClick={() => {
              removeLocation();
              onClickBack();
            }}
            className="hidden font-title text-[16px]/[20px] font-medium text-destructive300 md2:flex"
          />
        )}
      </View>
      <ViewHeader
        className="pb-1"
        center={
          <Text className="font-title text-[18px]/[22px] font-medium text-vault_text md2:text-[28px]/[34px]">
            {initialLocation != null ? `Change location` : `Add location`}
          </Text>
        }
        left={
          <BackButton
            icon={faClose}
            onClick={onClickBack}
            className="flex text-[24px] text-vault_text md2:hidden"
          />
        }
        right={
          removeLocation != null &&
          initialLocation != null && (
            <Button
              label="Remove"
              onClick={() => {
                removeLocation();
                onClickBack();
              }}
              className="font-base text-[16px]/[20px] font-semibold text-destructive300 md2:hidden"
            />
          )
        }
      />
      {children}
      <View
        className={twMerge(
          'box-border flex w-full flex-row justify-end gap-6 border-0 border-t border-solid border-vault_text/10 px-2 pb-4 pt-6',
          bottomClassName,
        )}
      >
        <Button
          disabledClassName="opacity-50"
          type="primary-themed"
          label={initialLocation != null ? 'Save' : 'Add'}
          className={addButtonClassName}
          disabled={onAddClick == null || !addEnabled}
          onClick={onAddClick ?? undefined}
        />
      </View>
    </View>
  );
}

function SearchLocation({
  onSelectLocation,
  geocoder,
  artistId,
  initialLocation,
}: {
  onSelectLocation: ({
    location,
  }: {
    location: MassMessageStateFields['locationsV2'][number];
  }) => void;
  geocoder: google.maps.Geocoder;
  artistId: string;
  initialLocation?: Extract<
    MassMessageStateFields['locationsV2'][number],
    { type: 'REGION' }
  > | null;
}) {
  const { isDesktop } = useWindow();
  const [inputValue, setInputValue] = useState<string>('');
  const logError = useLogError();
  const { openToast } = useToast();

  const Option = useStableCallback((props: GoogleLocationRowProps) => {
    return <GoogleLocationRow {...props} geocoder={geocoder} artistId={artistId} />;
  });

  const ClearIndicator = useStableCallback(({ clearValue }: { clearValue: () => void }) => {
    return (
      <View
        className="hidden cursor-pointer items-center justify-center rounded-full bg-vault_text md2:flex"
        onClick={() => {
          clearValue();
        }}
      >
        <FontAwesomeIcon icon={faCircleXmark} className="text-[20px] text-vault_text_opposite" />
      </View>
    );
  });

  const onChange = useStableCallback(async (value: unknown) => {
    if (value == null) {
      return;
    }

    const parsedValue = GeocoderResultSchema.parse(value);
    // We need zod parsing due to missing proper typing

    if (parsedValue == null) {
      logError({
        error: new Error('Invalid location value'),
        errorType: ERROR_TYPE.UNKNOWN,
        message: 'Invalid location value',
        level: 'warning',
        feature: FEATURES.MISCELLANEOUS,
        pillar: PILLARS.TEXT_BLAST,
        action: 'TEXT_BLAST_SEARCH_LOCATION_ERROR',
        openToast,
        toast: 'Invalid location value. Please try again.',
      });
      return;
    }

    const {
      label,
      value: { types },
    } = parsedValue;

    const isCountry = types.includes('country');
    const isPossibleState = types.includes('administrative_area_level_1');

    const geocoderResult = await findOrFetchGeocoderResult({
      cacheKey: label,
      geocoder,
      type: 'ADDRESS',
      address: label,
    });

    if (geocoderResult == null) {
      logError({
        error: new Error('Geocoder result not found'),
        errorType: ERROR_TYPE.UNKNOWN,
        message: 'Geocoder result not found',
        level: 'warning',
        feature: FEATURES.MISCELLANEOUS,
        pillar: PILLARS.TEXT_BLAST,
        action: 'TEXT_BLAST_SEARCH_LOCATION_ERROR',
        indexedTags: {
          label,
        },
        openToast,
        toast: 'Invalid location value. Please try again.',
      });
      return;
    }

    const {
      address_components,
      geometry: { location },
    } = geocoderResult;

    const shortName = address_components.find(component =>
      component.types.includes('country'),
    )?.short_name;

    const isoCountry = isIsoCountry(shortName ?? '') ? (shortName as IsoCountry) : null;

    if (isoCountry == null) {
      logError({
        error: new Error('Geocoder result not valid IsoCountry'),
        errorType: ERROR_TYPE.UNKNOWN,
        message: 'Geocoder result not valid IsoCountry',
        level: 'warning',
        feature: FEATURES.MISCELLANEOUS,
        pillar: PILLARS.TEXT_BLAST,
        action: 'TEXT_BLAST_SEARCH_LOCATION_ERROR',
        indexedTags: {
          label,
        },
        openToast,
        toast: 'Invalid location value. Please try again.',
      });
      return;
    }

    if (isCountry) {
      onSelectLocation({
        location: {
          type: 'REGION',
          isoCountry,
          isoUsStateCode: null,
        },
      });
      return;
    }

    if (isPossibleState && isoCountry === 'US') {
      const stateCode = address_components.find(component =>
        component.types.includes('administrative_area_level_1'),
      )?.short_name;

      const isoUsStateCode =
        !!stateCode && isIsoState(stateCode) ? (stateCode as IsousState) : null;

      onSelectLocation({
        location: {
          type: 'REGION',
          isoCountry,
          isoUsStateCode,
        },
      });
      return;
    }

    onSelectLocation({
      location: {
        type: 'GEO',
        latitude:
          typeof location.lat === 'number' ? (location.lat as unknown as number) : location.lat(),
        longitude:
          typeof location.lng === 'number' ? (location.lng as unknown as number) : location.lng(),
        radiusMiles: 20,
        displayName: label,
        isoCountry,
      },
    });
  });

  return (
    <View className="flex w-full flex-1 flex-col">
      <View className="box-border flex w-full flex-row items-center gap-2 rounded-xl bg-vault_text/10 p-2 pl-4">
        <FontAwesomeIcon icon={faSearch} className="text-vault_text/60" />
        <GooglePlacesAutocomplete
          apiKey={GOOGLE_API_KEY}
          apiOptions={{
            language: 'en',
          }}
          autocompletionRequest={{
            types: ['locality', 'sublocality', 'administrative_area_level_1', 'country'],
          }}
          minLengthAutocomplete={2}
          selectProps={{
            autoFocus: isDesktop,
            className: 'w-full text-vault_text',
            inputValue,
            onInputChange: setInputValue,
            onChange,
            noOptionsMessage: () => null,
            isClearable: true,
            placeholder: `Search country, state, city`,
            blurInputOnSelect: true,
            classNames: {
              placeholder: () => '!text-vault_text/50',
              dropdownIndicator: () => '!hidden',
              indicatorSeparator: () => '!hidden',
              control: () =>
                '!shadow-none !cursor-pointer !border-0 !bg-transparent !focus:outline-none !outline-none !focus:shadow-none !drop-shadow-none !focus:drop-shadow-none !hover:outline-none !focus:border-0 !ring-0 !focus:ring-0',
              input: () =>
                '!cursor-text !text-vault_text !bg-transparent !focus:outline-none !outline-none !ring-0 !focus:ring-0',
              valueContainer: () => '!text-vault_text !bg-transparent !ring-0',
              menuList: () => '!bg-transparent flex flex-col !no-scrollbar !h-auto',
              singleValue: () => '!text-vault_text !bg-transparent',
              container: () =>
                '!bg-transparent rounded-lg !pr-4 !focus:outline-none !outline-none !ring-0',
              menuPortal: () => '!bg-transparent !h-auto !overflow-visible',
              menu: () =>
                '!bg-transparent !min-h-0 !h-auto !max-h-none location-select-menu !overflow-visible !absolute !z-50',
              loadingIndicator: () => '!text-vault_text/60',
              loadingMessage: () => 'hidden',
              noOptionsMessage: () => '!text-vault_text/60',
            },
            components: {
              Option,
              ClearIndicator,
            },
          }}
        />
      </View>
      {inputValue.length === 0 && initialLocation != null && (
        <InitialLocationRow location={initialLocation} geocoder={geocoder} />
      )}
    </View>
  );
}
