import { setTimeout } from 'timers/promises';
import { type ChangeEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { captureException } from '@sentry/react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { Navigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';
import { mixpanelClient } from '../../clients/mixpanelClient';
import { ROUTES } from '../../constants/routeConstants';
import { invalidateOperations, useMutation } from '../../graphql/client';
import { AuthUserDocument, UpdateUserOnboardingDocument } from '../../graphql/generated';
import { useBetterGate } from '../../hooks/useFeatureGate';
import { useFreeTier } from '../../hooks/useFreeTier';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { removeInvalidUsernameChars, usernameSchema } from '../../utils/username';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { FormErrorIndicator } from '../forms/FormErrorIndicator';
import { Checkbox } from '../input/Checkbox';
import { SimplifiedAllAccessPass } from '../vault/AllAccessPass';
import { StaticMembershipBadge } from '../vault/MembershipBadge';

export function MembershipView({
  isLoading,
  artistName,
  serialNumber,
  imageUrl,
  displayName,
  createdAt,
  receiptCount,
  children,
  artistHandle,
  className,
}: {
  isLoading: boolean;
  artistName: string;
  serialNumber: number | null | undefined;
  imageUrl: string | null | undefined;
  displayName: string | null | undefined;
  createdAt: string | undefined;
  receiptCount: number | undefined;
  children?: React.ReactNode;
  artistHandle: string;
  className?: string;
}) {
  return (
    <>
      <StaticMembershipBadge
        isLoading={isLoading}
        artistName={artistName}
        serialNumber={serialNumber}
        imageUrl={imageUrl}
        displayName={displayName}
        createdAt={createdAt}
        receiptCount={receiptCount}
        artistHandle={artistHandle}
        className={className}
      />
      {children}
    </>
  );
}

const validationSchema = z.object({
  newUsername: usernameSchema,
  optIn: z.boolean().optional(),
});
type ValidationSchema = z.infer<typeof validationSchema>;

export function MembershipConfirmationView({
  vaultId,
  isLoading,
  artist,
  loggedInUserUsername,
  loginStatus,
  inviteCode,
  smsCampaignResponseShortcode,
  sourceReleaseCampaignId,
  onComplete,
  isModal = false,
  className,
}: {
  vaultId: string | null | undefined;
  isLoading: boolean;
  artist:
    | {
        linkValue: string;
        name: string;
        membershipImageUrl: string | null | undefined;
      }
    | null
    | undefined;
  loggedInUserUsername: string | null | undefined;
  loginStatus: LoginStatus;
  inviteCode: string | null | undefined;
  smsCampaignResponseShortcode: string | null | undefined;
  sourceReleaseCampaignId: string | null | undefined;
  onComplete: () => void;
  isModal?: boolean;
  className?: string;
}) {
  const { mutateAsync } = useMutation(UpdateUserOnboardingDocument, {});
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [currentNewUsername, setCurrentNewUsername] = useState(loggedInUserUsername ?? 'username');
  const [showUsernameInput] = useState(!loggedInUserUsername);

  const newSignIn = useBetterGate('VAULT_NEW_SIGN_IN_WEB') === 'enabled';

  const defaultOptIn = newSignIn ? true : false;
  const [optIn, setOptIn] = useState(defaultOptIn);

  const { subscribeFreeTier } = useFreeTier();

  const randomSerialId = useMemo(() => Math.floor(Math.random() * 1000), []);
  const now = useMemo(() => new Date().toISOString(), []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    setError,
  } = useForm<ValidationSchema>({
    defaultValues: {
      newUsername: '',
      optIn: defaultOptIn,
    },
    resolver: zodResolver(validationSchema),
  });

  useEffect(() => {
    if (!defaultOptIn) return;

    if (optIn) return;

    setOptIn(true);
    setValue('optIn', true);
  }, [defaultOptIn, setOptIn, optIn, setValue]);

  useEffect(() => {
    if (loggedInUserUsername != null) {
      setValue('newUsername', loggedInUserUsername);
    }
  }, [loggedInUserUsername, setValue]);

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  const onChangeUsername: ChangeEventHandler<HTMLInputElement> = e => {
    if (isSubmitting) return;
    const newUsername = removeInvalidUsernameChars(e.target.value.toLowerCase().trim());
    setValue('newUsername', newUsername);
    setCurrentNewUsername(newUsername);
  };

  const onChangeOptIn = () => {
    if (isSubmitting) return;
    setValue('optIn', !optIn);
    setOptIn(!optIn);
  };

  const errorText = errors.newUsername?.message;

  const attemptNumber = useRef(0);

  const onSubmit: SubmitHandler<ValidationSchema> = async (data, e) => {
    attemptNumber.current++;
    e?.preventDefault();

    if (artist && !data.optIn) {
      setError('optIn', {
        message: 'You must agree to continue',
      });
      return;
    }

    if (!showUsernameInput) {
      setIsFormLoading(true);

      vaultId &&
        (await subscribeFreeTier({
          input: {
            vaultId,
            inviteCode,
            smsCampaignResponseShortcode,
            sourceReleaseCampaignId,
          },
        }));

      onComplete();
      return;
    }

    const newUsername = data.newUsername.trim().toLowerCase();

    if (newUsername.length < 3) {
      setError('newUsername', {
        message: 'Username must be at least 3 characters',
      });
      trackEvent({
        type: EVENTS.SET_USERNAME_ERROR,
        properties: {
          artistHandle: null,
          error_type: 'invalid_format',
          username: newUsername,
          attempt_num: attemptNumber.current,
        },
      });
      return;
    }

    try {
      setIsFormLoading(true);

      const result = await mutateAsync({ input: { newUsername } });

      if (result.data.updateUser.__typename === 'MutationUpdateUserSuccess') {
        mixpanelClient.people.set({ name: newUsername });

        trackEvent({
          type: EVENTS.SET_USERNAME_SUCCESS,
          properties: {
            artistHandle: null,
            attempt_num: attemptNumber.current,
          },
        });
        attemptNumber.current = 0;

        await invalidateOperations({ operations: [AuthUserDocument] });

        if (vaultId) {
          await subscribeFreeTier({
            input: {
              vaultId,
              inviteCode,
              smsCampaignResponseShortcode,
              sourceReleaseCampaignId,
            },
          });
        }
        onComplete();
      } else if (result.data.updateUser.__typename === 'ValidationError') {
        setIsFormLoading(false);
        setError('newUsername', {
          message: 'Only lowercase letters, numbers, hyphens, and underscores',
        });
        trackEvent({
          type: EVENTS.SET_USERNAME_ERROR,
          properties: {
            artistHandle: null,
            error_type: 'invalid_format',
            username: newUsername,
            attempt_num: attemptNumber.current,
          },
        });
        return;
      } else if (result.data.updateUser.__typename === 'UsernameUnavailableError') {
        setIsFormLoading(false);
        setError('newUsername', {
          message: 'Username is not available, please try again',
        });
        trackEvent({
          type: EVENTS.SET_USERNAME_ERROR,
          properties: {
            artistHandle: null,
            error_type: 'username_taken',
            username: newUsername,
            attempt_num: attemptNumber.current,
          },
        });
      } else {
        setError('newUsername', {
          message: 'An error occurred while updating your username',
        });
        trackEvent({
          type: EVENTS.SET_USERNAME_ERROR,
          properties: {
            artistHandle: null,
            error_type: 'unknown',
            username: newUsername,
            attempt_num: attemptNumber.current,
          },
        });
      }
    } catch (e) {
      setIsFormLoading(false);
      setError('newUsername', {
        message: 'An error occurred while updating your username',
      });
      captureException(e, {
        extra: {
          newUsername,
        },
      });
      trackEvent({
        type: EVENTS.SET_USERNAME_ERROR,
        properties: {
          artistHandle: null,
          error_type: 'unknown',
          username: newUsername,
          attempt_num: attemptNumber.current,
        },
      });
      throw e;
    }
  };

  if (artist) {
    return (
      <div
        className={twMerge(
          'w-full overflow-hidden bg-vault_background',
          isModal ? 'h-fit  md2:min-w-[390px]' : 'h-screen',
          className,
        )}
      >
        <div
          className={twMerge(
            'flex flex-col items-center justify-center md2:mx-auto md2:px-0',
            isModal ? 'h-fit md2:max-w-md' : 'h-screen px-4 md2:max-w-lg',
          )}
        >
          <View
            className={twMerge(
              'mt-4 box-content flex w-full flex-col items-center justify-center md:mt-0',
              isModal ? '' : 'md:mx-6',
            )}
          >
            <MembershipView
              isLoading={isLoading}
              artistName={artist.name}
              serialNumber={randomSerialId}
              imageUrl={artist.membershipImageUrl}
              displayName={currentNewUsername}
              createdAt={now}
              receiptCount={0}
              artistHandle={artist.linkValue}
              className="sm:w-full md2:w-full"
            />
          </View>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="box-content flex w-full flex-col gap-[10px] pb-[20px] pt-7"
          >
            <View className="flex flex-col gap-2">
              <Text className="text-center font-title text-[20px] text-vault_text">
                Complete your membership
              </Text>
              <Text className="text-center font-base text-[16px] font-normal leading-[21px] text-vault_text/70">
                I'll add you to my contacts
                <br />
                so we can chat via text.
              </Text>
            </View>

            {newSignIn && <SimplifiedAllAccessPass artistHandle={artist.linkValue} />}

            <View className="flex flex-col gap-4">
              {showUsernameInput && (
                <View className="box-content flex flex-col gap-2 sm:mx-6">
                  <View className="relative flex flex-row items-center gap-4">
                    <input
                      type="text"
                      {...register('newUsername')}
                      placeholder="Enter username"
                      onChange={onChangeUsername}
                      className="flex-1 rounded-md border border-solid border-vault_text/15 bg-transparent p-3 font-base text-base-l text-vault_text placeholder:text-vault_text/50 focus:outline-none"
                      maxLength={20}
                      onFocus={async e => {
                        await setTimeout(100);
                        e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
                      }}
                    />
                    {errorText != null && (
                      <View className="absolute right-3 mt-1">
                        <FormErrorIndicator />
                      </View>
                    )}
                  </View>
                  {errorText != null && (
                    <Text className="text-center font-base text-base-m font-normal text-destructive300">
                      {errorText}
                    </Text>
                  )}
                </View>
              )}

              {defaultOptIn === false && (
                <View className="flex flex-row items-start sm:mx-6">
                  <Checkbox selected={optIn} withVaultTheme onClick={onChangeOptIn} />
                  <input
                    type="checkbox"
                    id="optInCheckbox"
                    {...register('optIn', { required: true })}
                    onChange={onChangeOptIn}
                    checked={optIn}
                    disabled={isFormLoading}
                    className="hidden"
                  />
                  <label
                    htmlFor="optInCheckbox"
                    className="ml-3 block cursor-pointer font-base text-base200"
                  >
                    <Text className="text-base-s leading-[18px] text-vault_text/60">
                      I agree to receive recurring messages via SMS from{' '}
                      {artist.name ?? artist.linkValue ?? 'the artist'}. Msg & Data Rates may apply.
                      Reply STOP to unsubscribe.
                    </Text>
                  </label>
                </View>
              )}
              {errors.optIn && (
                <Text className="text-center font-base text-base-m font-normal text-destructive300">
                  You must agree to continue
                </Text>
              )}
            </View>

            <div className="bg-dstructive50 flex w-full justify-center">
              <div className="mx-6 pt-6">
                <Button
                  label={!isFormLoading ? 'Join' : null}
                  type="primary-themed"
                  buttonType="submit"
                  loading={isFormLoading}
                  iconOnly={isFormLoading}
                  disabled={isFormLoading || optIn === false}
                  disabledClassName="opacity-50 cursor-not-allowed"
                  event={{ type: EVENTS.NEXT, properties: { type: 'Onboarding Username' } }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={twMerge(
          'w-full overflow-hidden bg-vault_background',
          isModal ? 'h-fit' : 'h-screen',
        )}
      >
        <div
          className={twMerge(
            'flex flex-col items-center justify-center  px-6 md2:mx-auto md2:max-w-lg md2:px-0',
            isModal ? 'h-fit' : 'h-screen',
          )}
        >
          <Text className="mb-5 font-title text-title-xl font-normal text-white">
            Choose a username
          </Text>
          <div className="mb-5 flex h-1 w-[168px] flex-row gap-2">
            <div className="flex h-1 w-40 rounded-full bg-yellow100" />
          </div>
          <Text className="mb-8 text-center font-base text-base-l font-normal text-base200">
            Your username is public and is how you
            <br />
            will appear to artists and members.
          </Text>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <View className="mx-6 my-3 box-content flex flex-col">
              <View className="mx-5 flex flex-row items-center gap-4">
                <input
                  type="text"
                  {...register('newUsername')}
                  placeholder="Username"
                  onChange={onChangeUsername}
                  className="flex-1 border-none bg-transparent font-base text-base-l font-normal text-white focus:border-none focus:outline-none"
                  maxLength={20}
                />
                {errorText != null && <FormErrorIndicator />}
              </View>
              <View
                className={twMerge(
                  'mt-3 h-[1px] w-full bg-base700',
                  errorText != null && 'bg-destructive300',
                )}
              />
              {errorText != null && (
                <Text className="mt-3 text-center font-base text-base-m font-normal text-destructive300">
                  {errorText}
                </Text>
              )}
            </View>
            <div className="bg-dstructive50 mb-5 mt-10 flex w-full justify-center">
              <div className="mx-6">
                <Button
                  label={!isFormLoading ? 'Done' : null}
                  type="primary"
                  buttonType="submit"
                  loading={isFormLoading}
                  iconOnly={isFormLoading}
                  disabled={isSubmitting}
                  disabledClassName="opacity-50 cursor-not-allowed"
                  event={{ type: EVENTS.NEXT, properties: { type: 'Onboarding Username' } }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
