import { type FC, useEffect, useMemo } from 'react';
import React from 'react';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInView } from 'react-intersection-observer';
import { twMerge } from 'tailwind-merge';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useVaultContentByFolderPosition } from '../../hooks/useVaultContent';
import { LoginStatus } from '../../types/authTypes';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { VaultUploadPromptView } from '../vault/VaultUploadPromptView';
import { VaultItemsSkeleton } from '../vault/items/ItemSkeleton';
import { VaultItem } from '../vault/items/VaultItem';
import { EmptyVaultPreview } from './EmptyVaultPreview';
import { UploadDropdownSection } from './Upload';

export const VaultContents: FC<{
  isOwner: boolean;
  vaultId: string;
  artistLinkValue: string;
  folderId: string | null;
  emptyState: {
    title: string;
    subTitle: string;
    icon: IconDefinition;
  };
}> = ({ isOwner, vaultId, artistLinkValue, folderId, emptyState }) => {
  const { isVaultCustomizeOpen } = useMenuContainer();

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const {
    orderedList,
    isInitialLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useVaultContentByFolderPosition({
    vaultId,
    folderId,
  });

  const allImageAndVideos = useMemo(
    () =>
      orderedList.filter(
        node => node.__typename === 'VaultImage' || node.__typename === 'VaultVideo',
      ),
    [orderedList],
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView, isFetchingNextPage]);

  const LoadingFooter = React.useCallback(
    () => (isFetchingNextPage || isInitialLoading ? <VaultItemsSkeleton itemCount={3} /> : null),
    [isFetchingNextPage, isInitialLoading],
  );

  return (
    <View className="mb-4 w-full">
      {folderId == null && isOwner && (
        <View className="flex w-full items-center justify-between pb-3 pt-5">
          <Text className="w-full text-[24px]/[24px] font-medium text-vault_text">Your vault</Text>
          <UploadDropdownSection
            artistLinkValue={artistLinkValue}
            vaultId={vaultId}
            folderId={folderId}
          />
        </View>
      )}
      <EmptyState
        artistLinkValue={artistLinkValue}
        // Center vertically on mobile if folderId is present, if not, add margin top
        className={twMerge(!!folderId ? 'h-[calc(100vh-200px)] md2:mt-12 md2:h-auto' : 'mt-12')}
        contentCount={orderedList.length}
        emptyState={emptyState}
        folderId={folderId}
        isLoading={isFetchingNextPage || isInitialLoading || isLoading}
        isOwner={isOwner}
        isVaultCustomizeOpen={isVaultCustomizeOpen}
        vaultId={vaultId}
      />
      <View className="grid flex-1 grid-cols-2 items-start gap-x-4 gap-y-[18px] overflow-y-scroll pt-4 scrollbar-none md2:grid-cols-3">
        {orderedList.map((item, i) => {
          return (
            <VaultItem
              isOwner={isOwner}
              key={item.id}
              item={item}
              allImageAndVideos={allImageAndVideos}
              vaultId={vaultId}
              containerRef={i === orderedList.length - 1 ? ref : undefined}
              artistHandle={artistLinkValue}
            />
          );
        })}
        <LoadingFooter />
      </View>
    </View>
  );
};

export const VaultContentSkeleton: FC = () => {
  return (
    <View className="no-scrollbar mt-24 flex min-h-full w-full flex-1 flex-col items-center overflow-y-scroll">
      <View className="relative top-0 z-base aspect-square w-full overflow-clip" />
      <View className="z-above1 -mt-[322px] flex w-full flex-col md2:-mt-[418px]">
        <View className="flex flex-col items-center gap-[68px] md2:gap-[70px]">
          <View className="flex h-[70px] items-end ">
            <LoadingSkeleton className="mb-2 h-3/4 w-[250px] bg-vault_text/10 md2:h-4/5" />
          </View>

          <View className="flex w-full items-center px-2">
            <View className="box-border grid w-full flex-1 grid-cols-2 flex-col items-center gap-x-4 gap-y-[18px] px-4 md2:grid-cols-3 md2:px-6">
              <VaultItemsSkeleton itemCount={3} />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

function EmptyState({
  artistLinkValue,
  className,
  contentCount,
  emptyState,
  folderId,
  isLoading,
  isOwner,
  isVaultCustomizeOpen,
  vaultId,
}: {
  artistLinkValue: string;
  className: string;
  contentCount: number;
  emptyState: {
    title: string;
    subTitle: string;
    icon: IconDefinition;
  };
  folderId: string | null;
  isLoading: boolean;
  isOwner: boolean;
  isVaultCustomizeOpen: boolean;
  vaultId: string;
}) {
  const { loginStatus } = useAuthContext();

  if (isLoading || contentCount > 0) {
    return null;
  }

  // For owners/managers
  if (isOwner && loginStatus === LoginStatus.LOGGED_IN && contentCount === 0) {
    // Fake empty state for customize mode
    if (isVaultCustomizeOpen) {
      return <EmptyVaultPreview artistHandle={artistLinkValue} />;
    }

    return (
      <VaultUploadPromptView
        vaultId={vaultId}
        artistLinkValue={artistLinkValue}
        folderId={folderId}
      />
    );
  }

  // For regular users
  return (
    <View className={twMerge('flex flex-col items-center justify-center', className)}>
      <FontAwesomeIcon className="mb-7 text-[60px] text-vault_text" icon={emptyState.icon} />
      <Text className="mb-2 text-center font-title text-[16px]/[20px] font-medium text-vault_text">
        {emptyState.title}
      </Text>
      <Text className="text-center font-base text-[14px]/[18px] font-normal text-vault_text/50">
        {emptyState.subTitle}
      </Text>
    </View>
  );
}
