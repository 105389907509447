import type { FC } from 'react';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faEllipsis } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCheck } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { AuthCTABox } from '../components/auth/AuthCTABox';
import { BackButton } from '../components/buttons/BackButton';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { UserRow } from '../components/structures/UserRow';
import { DEFAULT_AVATAR } from '../constants/imageConstants';
import { useVaultTheme } from '../hooks/useVaultTheme';

type Tab = 'auth-box' | 'user-row';

export const TestPage: FC = () => {
  useVaultTheme();

  const [activeTab, setActiveTab] = useState<Tab>('auth-box');

  function handleTabClick(tab: Tab) {
    setActiveTab(tab);
  }

  return (
    <DefaultLayout
      withVaultTheme={false}
      showRoundedTop
      hasChatReadAccess={false}
      messageChannelId={undefined}
      vaultId={undefined}
      showBorder
      withBottomNavigator={false}
      headerLeft={<BackButton href="/" />}
      contentClassName="md2:bg-white/3"
    >
      <View className="flex w-full flex-col">
        <View className="flex w-full">
          <button
            onClick={() => handleTabClick('auth-box')}
            className={twMerge(
              'text-base flex-1 cursor-pointer rounded-lg py-3 font-medium transition-all',
              'focus:outline-none focus-visible:ring-2 focus-visible:ring-white/20',
              activeTab === 'auth-box'
                ? 'bg-white text-black'
                : 'bg-white/10 text-white hover:bg-white/20',
            )}
          >
            Auth Box
          </button>
          <button
            onClick={() => handleTabClick('user-row')}
            className={twMerge(
              'text-base flex-1 cursor-pointer rounded-lg py-3 font-medium transition-all',
              'focus:outline-none focus-visible:ring-2 focus-visible:ring-white/20',
              activeTab === 'user-row'
                ? 'bg-white text-black'
                : 'bg-white/10 text-white hover:bg-white/20',
            )}
          >
            User Row
          </button>
        </View>

        <View className="box-border flex w-full flex-col gap-4 p-4">
          {activeTab === 'auth-box' && (
            <AuthCTABox type="inner-circle-landing" artistHandle="babyjake" fill artist={null} />
          )}
          {activeTab === 'user-row' && <UserRowPreview />}
        </View>
      </View>
    </DefaultLayout>
  );
};

function UserRowPreview() {
  return (
    <View className="flex w-full flex-col gap-6 text-white">
      <View className="flex w-full flex-col gap-4">
        <Text className="text-[18px] font-medium text-white">Main Menu</Text>

        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          name="John Doe"
          verifiedBadge={false}
          managedVault
        />
        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          name="John Doe"
          subtitle="1 update"
          verifiedBadge={false}
          managedVault={false}
          rightComponent={<View className="h-2 w-2 rounded-full bg-white" />}
        />
        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          name="John Doe"
          verifiedBadge={false}
          managedVault={false}
        />
      </View>

      <View className="flex w-full flex-col gap-4">
        <Text className="text-[18px] font-medium text-white">Vault Selector</Text>

        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          name="John Doe"
          verifiedBadge={false}
          size="large"
          managedVault
        />
        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          name="John Doe"
          subtitle="1 update"
          verifiedBadge={false}
          managedVault={false}
          rightComponent={<View className="h-2 w-2 rounded-full bg-white" />}
          size="large"
        />
      </View>

      <View className="flex w-full flex-col gap-4">
        <Text className="text-[18px] font-medium text-white">Menu Footer</Text>

        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          managedVault={false}
          name="John Doe"
          subtitle="@johndoe"
          rightComponent={
            <button className="cursor-pointer border-none bg-transparent outline-none">
              <FontAwesomeIcon icon={faEllipsis} className="text-[16px] text-white" />
            </button>
          }
          verifiedBadge
        />
        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          managedVault={false}
          name="John Doe"
          subtitle="@johndoe"
          rightComponent={
            <button className="cursor-pointer border-none bg-transparent outline-none">
              <FontAwesomeIcon icon={faEllipsis} className="text-[16px] text-white" />
            </button>
          }
          verifiedBadge={false}
        />
      </View>

      <View className="flex w-full flex-col gap-4">
        <Text className="text-[18px] font-medium text-white">Account Switcher</Text>

        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          managedVault={false}
          name="John Doe"
          subtitle="@johndoe"
          verifiedBadge
          rightComponent={<FontAwesomeIcon icon={faCheck} className="text-[16px] text-white/50" />}
        />
      </View>

      <View className="flex w-full flex-col gap-4">
        <Text className="text-[18px] font-medium text-white">Long Names</Text>

        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          managedVault={false}
          name="This is a super mega ultra duper tuper long name"
          subtitle="@johndoe"
          verifiedBadge
          rightComponent={<FontAwesomeIcon icon={faCheck} className="text-[16px] text-white/50" />}
        />
        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          managedVault={false}
          name="Singlelinesupermegaultradupertuperlongname"
          subtitle="@johndoe"
          verifiedBadge
          rightComponent={<FontAwesomeIcon icon={faCheck} className="text-[16px] text-white/50" />}
        />
      </View>

      <View className="flex w-full flex-col gap-4">
        <Text className="text-[18px] font-medium text-white">Loading</Text>

        <UserRow
          useVaultTheme={false}
          profileImageUrl={DEFAULT_AVATAR}
          managedVault={false}
          name="John Doe"
          subtitle="@johndoe"
          verifiedBadge={false}
          loading
        />
      </View>
    </View>
  );
}
