import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { gql } from '@soundxyz/gql-string';
import {
  ERROR_TYPE,
  PILLARS,
  SUBSCRIPTION_ERROR_ACTIONS,
  SUBSCRIPTION_INFO_ACTIONS,
} from '@soundxyz/vault-utils/constants';
import { ROUTES } from '../constants/routeConstants';
import { useToast } from '../contexts/ToastContext';
import { useMutation } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  ArtistByHandleDocument,
  CancelSubscriptionDocument,
  GetActiveVaultsSubscriptionsDocument,
  GetArtistInvitesPageDocument,
  GetArtistsToExploreDocument,
  GetMessageChannelDocument,
  GetPinnedMessagesDocument,
  GetPriorityActiveVaultSubscriptionsDocument,
  GetSubscriptionByIdDocument,
  GetUserChannelsDocument,
  SubscribeToVaultDocument,
  TopVaultInvitersDocument,
  UserVaultInviteStatsDocument,
  VaultContentByFolderDocument,
  VaultContentByFolderPositionDocument,
  VaultContentByIdDocument,
  VaultContentBySlugDocument,
} from '../graphql/generated';
import { clearSubscriptionPaymentIntent } from '../providers/StripeProvider';
import { artistNavigationPath } from '../utils/navigationUtils';
import { constructQueryParams } from '../utils/stringUtils';
import { logError } from './logger/useLogError';
import { logInfo } from './logger/useLogInfo';
import { useStableCallback } from './useStableCallback';

gql(/* GraphQL */ `
  query GetActiveVaultsSubscriptions($after: String, $first: Int) {
    activeVaultSubscriptions(after: $after, first: $first) {
      edges {
        cursor
        node {
          id
          ...MenuVaultSubscription
          ...MyVaultSubscription
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  mutation SubscribeToVault($input: MutationSubscribeToVaultInput!) {
    subscribeToVault(input: $input) {
      __typename
      ... on MutationSubscribeToVaultSuccess {
        data {
          id
          status
          vault {
            id
          }
        }
      }
      ... on Error {
        message
      }
    }
  }

  mutation CancelSubscription($input: MutationCancelSubscriptionInput!) {
    cancelSubscription(input: $input) {
      __typename
      ... on MutationCancelSubscriptionSuccess {
        data {
          id
          vault {
            id
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [SubscribeToVaultDocument, CancelSubscriptionDocument],
  refetch: [
    GetActiveVaultsSubscriptionsDocument,
    GetArtistsToExploreDocument,
    ArtistByHandleDocument,
    GetSubscriptionByIdDocument,
    GetPriorityActiveVaultSubscriptionsDocument,
    GetArtistInvitesPageDocument,
    UserVaultInviteStatsDocument,
    TopVaultInvitersDocument,
    VaultContentByFolderDocument,
    VaultContentByFolderPositionDocument,
    VaultContentByIdDocument,
    VaultContentBySlugDocument,
    GetMessageChannelDocument,
    GetPinnedMessagesDocument,
    GetUserChannelsDocument,
  ],
});

const pillar = PILLARS.SUBSCRIPTION;
const useSubscribeToVault = () => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const navigate = useNavigate();

  const subscribeMutation = useMutation(SubscribeToVaultDocument, {
    retry: 3,
  });

  const cancelSubscriptionMutation = useMutation(CancelSubscriptionDocument, {});

  const { openToast } = useToast();

  const navigationPath = ({
    redirect,
    linkValue,
  }: {
    redirect?: string | null;
    linkValue?: string | null;
  }): string => {
    if (redirect === 'onboarding') {
      const queryParams = constructQueryParams({ artistHandle: linkValue });
      return `${ROUTES.ONBOARDING}/username${queryParams ? `?${queryParams}` : ''}`;
    }

    if (linkValue) {
      return artistNavigationPath(linkValue, '/');
    }

    return ROUTES.VAULTS;
  };

  const subscribe = useStableCallback(
    ({
      vaultId,
      linkValue,
      subscriptionId,
      shouldRedirect = true,
      onSuccess,
    }: {
      vaultId: string;
      linkValue: string | null;
      subscriptionId: string;
      shouldRedirect?: boolean;
      onSuccess?: () => void;
    }) => {
      subscribeMutation
        .mutateAsync({
          input: { vaultId, subscriptionId, inviteCode: invite, smsCampaignResponseShortcode },
        })
        .then(result => {
          clearSubscriptionPaymentIntent({ vaultId });

          switch (result.data.subscribeToVault.__typename) {
            case 'MutationSubscribeToVaultSuccess': {
              onSuccess?.();
              if (shouldRedirect) {
                const navigateTo = navigationPath({ redirect, linkValue });
                navigate(navigateTo);
              }
              openToast({
                text: 'You have successfully subscribed to this vault!',
                variant: 'success',
              });
              logInfo({
                action: SUBSCRIPTION_INFO_ACTIONS.SUBSCRIPTION_SUCCESS,
                message: 'User successfully subscribed to vault',
                pillar,
                data: {
                  vaultId,
                  subscriptionId,
                  linkValue,
                },
              });
              break;
            }
            case 'StripeApiError': {
              logError({
                action: SUBSCRIPTION_ERROR_ACTIONS.SUBSCRIPTION_ERROR,
                error: new Error(result.data.subscribeToVault.message),
                level: 'error',
                message: result.data.subscribeToVault.message,
                errorType: ERROR_TYPE.STRIPE_API_ERROR,
                pillar,
                indexedTags: {
                  vaultId,
                  subscriptionId,
                  linkValue,
                },
                toast: `An internal error occurred. Please try again.`,
                toastDuration: 10000,
              });

              break;
            }
            case 'NotFoundError': {
              logError({
                action: SUBSCRIPTION_ERROR_ACTIONS.SUBSCRIPTION_ERROR,
                error: new Error(result.data.subscribeToVault.message),
                level: 'error',
                message: result.data.subscribeToVault.message,
                errorType: ERROR_TYPE.NOT_FOUND_ERROR,
                pillar,
                indexedTags: {
                  vaultId,
                  subscriptionId,
                  linkValue,
                  source: 'subscribeToVaultMutation',
                },
                toast: `An internal error occurred. Please try again.`,
                toastDuration: 10000,
              });

              if (linkValue) navigate(artistNavigationPath(linkValue, '/'));
              break;
            }
            default: {
              break;
            }
          }
        })
        .catch(error => {
          clearSubscriptionPaymentIntent({ vaultId });

          logError({
            action: SUBSCRIPTION_ERROR_ACTIONS.SUBSCRIPTION_ERROR,
            error: error,
            level: 'error',
            message: 'Error subscribing to vault',
            errorType: ERROR_TYPE.UNKNOWN,
            pillar,
            indexedTags: {
              vaultId,
              subscriptionId,
              linkValue,
              source: 'subscribeToVaultMutation',
            },
            toast: `An internal error occurred. Please try again.`,
            toastDuration: 10000,
          });
        });
    },
  );

  const unsubscribe = useStableCallback(
    ({ subscriptionId, onSuccess }: { subscriptionId: string; onSuccess?: () => void }) => {
      cancelSubscriptionMutation
        .mutateAsync({ input: { subscriptionId } })
        .then(({ data }) => {
          switch (data.cancelSubscription.__typename) {
            case 'MutationCancelSubscriptionSuccess': {
              onSuccess?.();
              logInfo({
                action: SUBSCRIPTION_INFO_ACTIONS.UNSUBSCRIBE_SUCCESS,
                message: 'User successfully subscribed to vault',
                pillar,
                data: {
                  subscriptionId,
                  vaultId: data.cancelSubscription.data.vault.id,
                },
              });
              break;
            }
            case 'NotFoundError': {
              logError({
                action: SUBSCRIPTION_ERROR_ACTIONS.SUBSCRIPTION_ERROR,
                error: new Error(data.cancelSubscription.message),
                level: 'error',
                message: data.cancelSubscription.message,
                errorType: ERROR_TYPE.NOT_FOUND_ERROR,
                pillar,
                indexedTags: {
                  subscriptionId,
                },
                toast: `An internal error occurred. Please try again.`,
                toastDuration: 10000,
              });
              break;
            }
            default: {
              openToast({
                text: `An internal error occurred trying to unsubscribe. Please try again.`,
                variant: 'error',
                duration: 10000,
              });
              break;
            }
          }
        })
        .catch(error => {
          logError({
            action: SUBSCRIPTION_ERROR_ACTIONS.SUBSCRIPTION_ERROR,
            error,
            level: 'error',
            message: 'Error unsubscribing from vault',
            errorType: ERROR_TYPE.UNKNOWN,
            pillar,
            indexedTags: {
              subscriptionId,
            },
            toast: `An internal error occurred. Please try again.`,
            toastDuration: 10000,
          });
        });
    },
  );

  return {
    subscribe,
    unsubscribe,
    isLoading: subscribeMutation.isLoading || cancelSubscriptionMutation.isLoading,
  };
};

export { useSubscribeToVault };
