import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useVaultTheme as useVaultThemeHook } from '../../hooks/useVaultTheme';

export const ActionField = ({
  children,
  useVaultTheme = false,
  label,
  loading = false,
  disabled = false,
  onClick,
  buttonType = 'button',
}: {
  children: React.ReactNode;
  useVaultTheme: boolean;
  label: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonType?: 'submit' | 'button';
}) => {
  useVaultThemeHook({
    enabled: useVaultTheme,
  });

  return (
    <div className="flex w-full flex-row overflow-hidden rounded-full">
      <div
        className={twMerge(
          'flex w-[72%] flex-shrink rounded-full rounded-br-none rounded-tr-none border border-solid  md2:w-4/6',
          useVaultTheme ? 'border-vault_text/10' : 'border-base_text/10',
        )}
      >
        {children}
      </div>

      <button
        aria-label={label}
        type={buttonType}
        disabled={disabled || loading}
        onClick={onClick}
        className={twMerge(
          'flex-grow appearance-none items-center justify-center border-none px-6 py-3.5 font-title text-title-xs font-medium  outline-none hover:cursor-pointer hover:opacity-80 disabled:opacity-50 disabled:hover:cursor-not-allowed md2:text-title-s',
          useVaultTheme
            ? 'bg-vault_accent text-vault_accent_text'
            : 'bg-base_accent text-base_opposite_text',
        )}
      >
        {loading ? (
          <FontAwesomeIcon
            icon={faSpinner}
            className={twMerge(
              'inline-block animate-spin rounded-full text-title-xs font-medium  md2:text-title-m',
              useVaultTheme ? 'text-vault_text' : 'text-base_text',
            )}
          />
        ) : (
          label
        )}
      </button>
    </div>
  );
};
