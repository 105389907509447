import type { ChangeEventHandler, FC, FormEventHandler } from 'react';
import { useEffect } from 'react';
import { useLoginWithSms } from '@privy-io/react-auth';
import { AsYouType, type CountryCode } from 'libphonenumber-js/min';
import { Navigate, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { BackButton } from '../../components/buttons/BackButton';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { FormErrorIndicator } from '../../components/forms/FormErrorIndicator';
import { Item, Select } from '../../components/forms/Select';
import { Logo } from '../../components/svg/Logo';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { COUNTRY_CODES } from '../../constants/phoneConstants';
import { ROUTES } from '../../constants/routeConstants';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../constants/urlConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useSignIn } from '../../hooks/useSignIn';
import { useTimer } from '../../hooks/useTimer';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';

export const SignInPage: FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const artistHandle = searchParams.get('artistHandle');
  const payeeSecretId = searchParams.get('psid');
  const redirect = searchParams.get('redirect');
  const bottomSheetType = searchParams.get('openBottomSheet');
  const trackId = searchParams.get('trackId');
  const source = searchParams.get('source');
  const invite = searchParams.get('invite');
  const linkSpotify = searchParams.get('linkSpotify');
  const linkAppleMusic = searchParams.get('linkAppleMusic');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const sourceReleaseCampaignId = searchParams.get('sourceReleaseCampaignId');
  const eventType = searchParams.get('eventType');
  const showReceiptModal = searchParams.get('showReceiptModal');
  const receiptId = searchParams.get('receiptId');

  const fromJoin = source === 'join';

  const {
    countryCode,
    errorText,
    isOpen,
    phone,
    setCountryCode,
    setErrorText,
    setIsOpen,
    setPhone,
    validPhoneNumber,
    codeRenabled,
    lastActivePhoneNumber,
    onSignInClick,
  } = useSignIn();

  const { loginStatus, loggedInUser } = useAuthContext();

  const {
    state: { status },
  } = useLoginWithSms();

  const { seconds: codeSentDisabledSecondsRemaining, isRunning: isInCooldown } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  useEffect(() => {
    if (codeSentDisabledSecondsRemaining === 0 || !validPhoneNumber || codeRenabled === 1) {
      setErrorText(null);
    } else {
      setErrorText(`Please wait ${codeSentDisabledSecondsRemaining} seconds before trying again`);
    }
  }, [codeRenabled, codeSentDisabledSecondsRemaining, setErrorText, validPhoneNumber]);

  if (loginStatus === LoginStatus.LOADING) {
    return <FullPageLoading withVaultTheme={false} />;
  }

  const navigationPath = ({
    artistHandle,
    loggedInUser,
  }: {
    artistHandle?: string | null;
    loggedInUser?: {
      highestSubscriptionLevel?: string | null;
    } | null;
  }) => {
    if (artistHandle != null) {
      return artistNavigationPath(artistHandle, '/');
    }
    if (loggedInUser?.highestSubscriptionLevel != null) {
      return ROUTES.VAULTS;
    }
    return ROUTES.VAULTS;
  };

  if (loginStatus === LoginStatus.LOGGED_IN) {
    const navigateTo = navigationPath({ artistHandle, loggedInUser });

    return <Navigate to={navigateTo} />;
  }

  if (status === 'awaiting-code-input' && lastActivePhoneNumber) {
    const queryParams = constructQueryParams({
      artistHandle,
      openBottomSheet: bottomSheetType,
      redirect,
      code,
      psid: payeeSecretId,
      trackId,
      invite,
      linkSpotify,
      linkAppleMusic,
      sourceReleaseCampaignId,
      eventType,
      source,
      showReceiptModal: showReceiptModal,
      receiptId,
      c: smsCampaignResponseShortcode,
    });
    return <Navigate to={`${ROUTES.VERIFY}?${queryParams}`} />;
  }

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    const currentInput = e.target.value;
    let rawInput = currentInput.replace(/\D/g, ''); // Remove non-digit characters

    // Check if the last character was removed and was a formatting character
    if (phone.length > currentInput.length && '()- '.includes(phone?.[phone.length - 1] ?? '')) {
      rawInput = rawInput.substring(0, rawInput.length - 1);
    }

    const formatter = new AsYouType(countryCode as CountryCode);
    const formatted = formatter.input(rawInput);
    setPhone(formatted);
  };

  const onNextClick: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (isOpen) return;

    trackEvent({
      type: EVENTS.SIGN_IN_CLICK,
      properties: { artistHandle },
    });

    onSignInClick({
      source: 'SignInPage',
    });
  };

  return (
    <div className="relative flex items-center justify-center  h-screen-safe">
      <View className="absolute top-[12%] mx-auto mt-auto flex w-full flex-1 flex-col gap-8 md2:top-[35%]  md2:max-w-sm">
        <div className="flex flex-col items-center gap-5">
          <View className="relative flex w-full flex-row items-center justify-between">
            <BackButton
              className={twMerge('absolute left-6 w-fit', !!artistHandle ? 'block' : 'hidden')}
            />
            <View className="flex w-full items-center justify-center self-center">
              <View className="flex h-[48px] w-[48px] flex-col items-center justify-center">
                <Logo variant="default" />
              </View>
            </View>
          </View>
          <Text className="font-title text-title-xl font-normal text-white">
            {fromJoin ? 'Join Vault' : 'Sign In'}
          </Text>
        </div>

        <div className="box-border flex w-full flex-col gap-7 px-6 md2:px-0">
          <form onSubmit={onNextClick} className="flex w-full flex-col gap-4">
            <View className="flex flex-col">
              <View className="flex justify-center gap-2">
                <View className="flex justify-end ">
                  <Select
                    value={COUNTRY_CODES.find(({ code }) => code === countryCode)?.code ?? ''}
                    onValueChange={val => setCountryCode(val)}
                    itemProps={{
                      className:
                        'bg-transparent text-white hover:bg-transparent focus:bg-transparent',
                    }}
                    className="w-[5em] bg-transparent p-0 font-base text-base-l font-normal text-white hover:bg-transparent"
                    contentClassName="bg-[#1f1f1f] w-[5em] max-h-[400px]"
                    onOpenChange={setIsOpen}
                    disabled={status === 'sending-code'}
                    shouldHideIcon
                  >
                    {COUNTRY_CODES.map(({ code, flag, dial_code }) => (
                      <Item
                        key={code}
                        value={code}
                        className="box-border flex w-[5em] flex-row justify-center bg-transparent py-0 pl-0 font-base text-base-xl font-normal text-white hover:bg-transparent focus:bg-transparent"
                        dropDownClassName="box-border bg-[#1f1f1f] hover:bg-[#1f1f1f] focus:bg-[#1f1f1f] font-base text-base-xl font-normal rounded-sm justify-center pr-1 overflow-x-clip"
                      >
                        <Text className="mx-2 text-[24px]">{flag}</Text>
                        <Text className="pr-2 text-[16px] md2:pr-0">{dial_code}</Text>
                      </Item>
                    ))}
                  </Select>
                </View>
                <input
                  type="tel"
                  value={phone}
                  placeholder="Enter Phone Number"
                  name="phone"
                  autoComplete="tel"
                  onChange={onChange}
                  disabled={status === 'sending-code'}
                  autoFocus
                  className="border-none bg-transparent font-base text-base-l font-normal text-white focus:w-[unset] focus:border-none focus:outline-none"
                />
                {errorText != null && (
                  <div className="absolute right-16">
                    <FormErrorIndicator />
                  </div>
                )}
              </View>
              <View
                className={twMerge(
                  'mt-3 h-[1px] w-full bg-base700',
                  errorText != null && 'bg-destructive300',
                )}
              />
              {errorText != null && (
                <Text className="my-3 text-center font-base text-base-m font-normal text-destructive300">
                  {errorText}
                </Text>
              )}
            </View>
            <Button
              label="Next"
              type="primary"
              buttonType="submit"
              className="w-full"
              loading={status === 'sending-code' || status === 'submitting-code'}
              event={{ type: EVENTS.NEXT, properties: { type: 'Sign In' } }}
              disabled={
                status === 'sending-code' ||
                status === 'submitting-code' ||
                !!codeSentDisabledSecondsRemaining ||
                isInCooldown
              }
              disabledClassName="opacity-30"
            />
          </form>
          <Text className="text-center font-base text-base-m font-normal text-base400">
            By signing up, you agree <br /> to the{' '}
            <a
              href={TOS_URL}
              target="_blank"
              className="text-yellow100 hover:cursor-pointer"
              onClick={e => isOpen && e.preventDefault()}
            >
              Terms
            </a>{' '}
            &{' '}
            <a
              href={PRIVACY_POLICY_URL}
              target="_blank"
              className="text-yellow100 hover:cursor-pointer"
              onClick={e => isOpen && e.preventDefault()}
            >
              Privacy Policy
            </a>
          </Text>
        </div>
      </View>
    </div>
  );
};
