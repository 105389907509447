import { type FC, useEffect, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { Image } from '../../components/common/Image';
import { View } from '../../components/common/View';
import { UserPlaceholderImage } from '../../components/user/UserPlaceholderImage';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { MembershipConfirmationView } from '../../components/views/MembershipView';
import { COLOR } from '../../constants/colorConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { ArtistByHandleDocument } from '../../graphql/generated';
import { fetchReceiptId } from '../../hooks/campaign/fetchReceiptId';
import { useStableCallback } from '../../hooks/useStableCallback';
import { hexToColorSpace } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';

gql(/* GraphQL */ `
  mutation UpdateUserOnboarding($input: MutationUpdateUserInput!) {
    updateUser(input: $input) {
      __typename

      ... on Error {
        message
      }

      ... on MutationUpdateUserSuccess {
        data {
          id
          username
        }
      }
    }
  }
`);

export const OnboardingUsernamePage: FC = () => {
  const { loginStatus, loggedInUser } = useAuthContext();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const artistHandle = searchParams.get('artistHandle');
  const trackId = searchParams.get('trackId');
  const invite = searchParams.get('invite');
  const redirect = searchParams.get('redirect');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const sourceReleaseCampaignId = searchParams.get('sourceReleaseCampaignId');
  const showReceiptModal = searchParams.get('showReceiptModal');
  const releaseCampaignId = searchParams.get('sourceReleaseCampaignId');
  const source = searchParams.get('source');
  const eventType = searchParams.get('receiptType');
  const receiptIdParam = searchParams.get('receiptId');

  const navigationPath = useStableCallback(
    ({
      artistHandle,
      receiptId,
    }: {
      artistHandle?: string | null;
      receiptId?: string | null;
    }): string => {
      if (artistHandle != null) {
        const queryParams = constructQueryParams({
          trackId,
          invite,
          redirect: redirect,
          showReceiptModal,
          sourceReleaseCampaignId: releaseCampaignId,
          source,
          receiptType: eventType,
          receiptId: receiptIdParam ?? receiptId,
        });

        return artistNavigationPath(artistHandle, redirect ? `/${redirect}` : '/', queryParams);
      }

      return ROUTES.VAULTS;
    },
  );

  const navigateTo = useStableCallback((receiptId?: string | null) =>
    navigationPath({
      artistHandle,
      receiptId,
    }),
  );

  const { isLoading: isLoadingArtist, data: artist } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
    select: data => data.data.artistLink?.artist,
  });

  useEffect(() => {
    if (loginStatus === LoginStatus.LOGGED_IN && !!loggedInUser?.username) {
      navigate(navigateTo());
    }
  }, [artistHandle, loggedInUser?.username, loginStatus, navigate, navigateTo, receiptIdParam]);

  const onComplete = useStableCallback(async () => {
    /**
     * For new users coming through a campaign flow, receipts are only created after:
     * - User gets a membership
     *
     * We fetch the receipt ID here at onboarding completion to ensure we can
     * redirect to the correct receipt modal state after the user is fully set up.
     */
    const receiptId = await fetchReceiptId({
      artistHandle,
      releaseCampaignId: sourceReleaseCampaignId,
      source: source as 'apple' | 'spotify' | null,
      showReceiptModal,
      receiptIdParam,
      loggedIn: loginStatus === LoginStatus.LOGGED_IN && !!loggedInUser?.id,
      eventType,
    });
    navigate(navigateTo(receiptId));
  });

  const dominantColor = artist?.profileImage?.dominantColor;

  const background = useMemo(() => {
    if (!artist) return null;
    return (
      <>
        {artist.profileImage.artistFullProfileImageUrl != null ? (
          <Image
            src={artist.profileImage.artistFullProfileImageUrl}
            alt="Blurred Profile Image"
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-70 blur-3xl filter"
          />
        ) : (
          <UserPlaceholderImage
            id={artist.id}
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-80 blur-3xl filter"
          />
        )}

        <View
          className="absolute bottom-0 z-base aspect-square h-full w-full bg-gradient-to-b from-transparent to-black"
          style={{
            '--tw-gradient-from': `rgba(${hexToColorSpace(dominantColor ?? COLOR.black)},0.1) var(--tw-gradient-from-position)`,
          }}
        />
      </>
    );
  }, [dominantColor, artist]);

  const { width } = useWindow();

  if (loginStatus === LoginStatus.LOADING || isLoadingArtist) {
    return <FullPageLoading withVaultTheme />;
  } else if (loggedInUser?.username && !artist) {
    return <Navigate to={navigateTo()} />;
  } else if (width != null && width >= 500) {
    return (
      <View className="box-border flex h-full w-full items-center justify-center overflow-hidden px-6">
        {background}
        <View
          className={twMerge(
            'relative h-fit w-full rounded-[24px] bg-vault_background',
            !!artist && 'w-fit',
          )}
        >
          <MembershipConfirmationView
            className={twMerge(
              'md-[390px] w-full rounded-[24px] bg-vault_background p-1 md:p-4',
              !!artist && 'w-fit',
            )}
            vaultId={artist?.mainVaultId}
            isLoading={false}
            artist={
              artist
                ? {
                    linkValue: artist.linkValue,
                    name: artist.name,
                    membershipImageUrl: artist.profileImage.artistFullProfileImageUrl,
                  }
                : null
            }
            loggedInUserUsername={loggedInUser?.username}
            loginStatus={loginStatus}
            inviteCode={invite}
            smsCampaignResponseShortcode={smsCampaignResponseShortcode}
            sourceReleaseCampaignId={sourceReleaseCampaignId}
            onComplete={onComplete}
            isModal={!!artist}
          />
        </View>
      </View>
    );
  }

  return (
    <MembershipConfirmationView
      vaultId={artist?.mainVaultId}
      isLoading={false}
      artist={
        artist
          ? {
              linkValue: artist.linkValue,
              name: artist.name,
              membershipImageUrl: artist.profileImage.artistFullProfileImageUrl,
            }
          : null
      }
      loggedInUserUsername={loggedInUser?.username}
      loginStatus={loginStatus}
      inviteCode={invite}
      smsCampaignResponseShortcode={smsCampaignResponseShortcode}
      sourceReleaseCampaignId={sourceReleaseCampaignId}
      onComplete={onComplete}
    />
  );
};
