import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faChevronLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';

export const AuthBox = ({
  header,
  onBackClick,
  subText,
  footerInfo,
  userAction,
  fill = true,
  membershipCard,
  transparent = false,
  useVaultTheme,
}: {
  header: string | React.ReactNode;
  onBackClick?: () => void;
  subText?: string | React.ReactNode;
  footerInfo?: string | React.ReactNode;
  userAction?: React.ReactNode;
  fill?: boolean;
  membershipCard?: React.ReactNode;
  transparent?: boolean;
  useVaultTheme?: boolean;
}) => {
  return (
    <div
      className={twMerge(
        'box-content flex overflow-hidden rounded-[24px] ',
        fill ? 'w-full' : 'w-full max-w-[390px]',
        transparent ? '' : useVaultTheme ? 'bg-vault_background' : 'bg-base_background',
      )}
    >
      <div
        className={twMerge(
          'flex w-full items-center justify-center bg-vault_text/10 ',
          !!membershipCard ? 'p-4' : 'px-4 py-6',
          useVaultTheme ? 'bg-vault_text/10' : 'bg-base_text/10',
        )}
      >
        <div
          className={twMerge(
            'flex  w-full min-w-[311px] flex-col items-center  text-center',
            !!membershipCard ? 'gap-5' : 'max-w-[324px] gap-4 md2:max-w-[348px]',
          )}
        >
          {membershipCard}
          <div className=" flex w-full flex-col items-center justify-center gap-3">
            <div className="relative flex w-full items-center justify-center">
              {!!onBackClick && (
                <button
                  onClick={onBackClick}
                  className="absolute left-0 cursor-pointer appearance-none border-none bg-transparent outline-none hover:opacity-80"
                  aria-label="Back"
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className={twMerge(
                      'text-title-m ',
                      useVaultTheme ? 'text-vault_text' : 'text-base_text',
                    )}
                  />
                </button>
              )}
              <h2
                className={twMerge(
                  'font-title text-title-l font-medium ',
                  useVaultTheme ? 'text-vault_text' : 'text-base_text',
                )}
              >
                {header}
              </h2>
            </div>
            {!!subText && (
              <div
                className={twMerge(
                  'text-center font-base text-base-m ',
                  useVaultTheme ? 'text-vault_text' : 'text-base_text',
                )}
              >
                {typeof subText === 'string' ? (
                  <p
                    className={twMerge(
                      'text-center font-base text-base-m font-normal',
                      useVaultTheme ? 'text-vault_text' : 'text-base_text',
                    )}
                  >
                    {subText}
                  </p>
                ) : (
                  subText
                )}
              </div>
            )}
          </div>

          <div className="flex min-h-[58px] w-full items-center justify-center">{userAction}</div>

          <div className="flex min-h-[32px] text-base-s">
            {typeof footerInfo === 'string' ? <p>{footerInfo}</p> : footerInfo}
          </div>
        </div>
      </div>
    </div>
  );
};
