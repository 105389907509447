import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEnvelope } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faCircleUser } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { TopInvitersFragmentDoc } from '../../graphql/generated';
import { InvitedUserFragmentDoc } from '../../graphql/generated';
import { VaultInviteStatsFragmentDoc } from '../../graphql/generated';
import {
  ArtistInviteViewFragmentDoc,
  type FragmentType,
  getFragment,
} from '../../graphql/generated';

import { useCopy } from '../../hooks/useCopy';
import { useWindow } from '../../hooks/useWindow';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getFromList } from '../../utils/arrayUtils';
import { pluralizeText } from '../../utils/textUtils';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { ProfileImage } from '../user/ProfileImage';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';

gql(/* GraphQL */ `
  fragment ArtistInviteView on Artist {
    id
    name
    profileImage {
      id
      artistFullProfileImageUrl: imageOptimizedUrl
      fallbackImageUrl: cdnUrl
    }
    linkValue
    mainVaultId
  }

  fragment InvitedUser on User {
    id
    displayName
    username
    createdAt
    avatar {
      id
      cdnUrl
      userSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
    }
    updatedAt
  }

  fragment VaultInviteStats on InviteStats {
    inviteCount
    previewInvitedUsers {
      ...InvitedUser
    }
  }

  fragment TopInviters on TopVaultInviter {
    inviteCount
    inviter {
      ...InvitedUser
    }
    rank
  }
`);

export function VaultInviteView({
  artist,
  stats,
  topInviters,
}: {
  artist: FragmentType<ArtistInviteViewFragmentDoc>;
  stats: FragmentType<VaultInviteStatsFragmentDoc>;
  topInviters: FragmentType<TopInvitersFragmentDoc>[];
}) {
  const { loggedInUser } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();

  const { isDesktop } = useWindow();

  const { id, name, profileImage, linkValue, mainVaultId } = getFragment(
    ArtistInviteViewFragmentDoc,
    artist,
  );

  const adminArtist = useMemo(() => {
    return getFromList(
      loggedInUser?.adminArtists,
      adminArtist => adminArtist.artistId === id && adminArtist,
    );
  }, [loggedInUser?.adminArtists, id]);

  const { inviteCount, previewInvitedUsers } = getFragment(VaultInviteStatsFragmentDoc, stats);

  const isStatsEmpty = inviteCount === 0;
  const isTopInvitersEmpty = topInviters.length === 0;

  const inviteLink = useMemo(() => {
    if (!loggedInUser?.inviteCode) {
      return null;
    }

    return `${window.location.origin}/${linkValue}?invite=${loggedInUser.inviteCode}`;
  }, [linkValue, loggedInUser?.inviteCode]);

  const { copy: copyInviteLink } = useCopy({
    text: inviteLink,
    successMessage: 'Invite link copied to clipboard!',
  });

  const mailtoLink = useMemo(() => {
    if (!inviteLink) {
      return null;
    }

    return `mailto:?subject=Join%20${encodeURIComponent(name)}'s%20vault!&body=Use%20my%20signup%20link%20to%20join%20${encodeURIComponent(name)}'s%20exclusive%20music%20and%20community%20on%20Vault%20${encodeURIComponent(inviteLink)}`;
  }, [inviteLink, name]);

  return (
    <View
      className={twMerge(
        'relative flex min-h-full w-full flex-1 flex-col items-center',
        isDesktop ? 'md2:-mt-[61px]' : '-mt-[84px]',
      )}
    >
      <View className="relative top-0 z-base aspect-square w-full overflow-clip">
        {profileImage != null ? (
          <Image
            src={profileImage.artistFullProfileImageUrl ?? profileImage.fallbackImageUrl}
            alt={name}
            className={twMerge(
              'z-below aspect-square w-full object-cover object-left-top',
              isDesktop && 'md2:rounded-t-[20px]',
            )}
          />
        ) : (
          <UserPlaceholderImage
            id={id}
            className="z-below aspect-square w-full"
            svgClassName={isDesktop ? 'md2:rounded-t-[20px]' : undefined}
          />
        )}
        <View
          className={twMerge(
            'absolute top-0 z-base aspect-square w-full bg-gradient-to-b from-vault_background to-transparent to-40% md2:hidden',
            isDesktop && 'md2:rounded-t-[20px]',
          )}
        />
        <View
          className={twMerge(
            'absolute top-20 z-base aspect-square w-full bg-gradient-to-b from-transparent to-vault_background to-80% md2:to-60%',
          )}
        />
      </View>

      <View className="z-above1 box-border w-full">
        <View className="-mt-[100px] box-border flex min-h-screen w-full flex-col bg-gradient-to-b from-transparent to-vault_text/3 px-4 py-6 md2:-mt-[290px] md2:px-6">
          <View className="mx-4 flex flex-col items-center">
            <View className="mb-[20px] flex items-center justify-center">
              <Text className="line-clamp-2 text-center font-title text-[32px] font-medium text-vault_text">
                Help grow
                <br />
                this vault
              </Text>
            </View>
          </View>

          <View className="mx-4 flex flex-col items-center">
            <View className="mb-[20px] flex items-center justify-center">
              <Text className="line-clamp-2 text-center font-base text-[16px] font-normal leading-[20px] text-vault_text">
                Invite friends to help this artist grow and
                <br />
                see your ranking against other fans!
              </Text>
            </View>
          </View>

          {/* Do not display buttons if no inviteCode available */}
          {!!inviteLink && (
            <View className="mx-4 mt-4 flex items-center justify-center gap-3">
              <Button
                label="Copy invite link"
                type="primary-themed"
                leadingIcon={faLink}
                onClick={() => {
                  trackEvent({
                    type: EVENTS.COPY,
                    properties: { type: 'invite-link' },
                  });

                  copyInviteLink();
                }}
              />

              {!!mailtoLink && (
                <Button
                  label=""
                  className="h-[48px] w-[48px] rounded-full bg-vault_text/20 text-[20px] text-vault_text hover:bg-opacity-30"
                  leadingIcon={faEnvelope}
                  onClick={() => {
                    trackEvent({
                      type: EVENTS.SHARE_VAULT_INVITE,
                      properties: { type: 'email' },
                    });

                    window.location.href = mailtoLink;
                  }}
                  iconOnly
                />
              )}
            </View>
          )}

          <View className="mt-[40px] box-border w-full px-4">
            <View className="box-border flex w-full flex-col items-center justify-center gap-3 rounded-xl bg-vault_text/20 p-6">
              {isStatsEmpty ? (
                <>
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    fontSize={24}
                    className="text-vault_text/50"
                  />
                  <Text className="text-center font-title text-[18px] font-medium text-vault_text">
                    No friends referred
                  </Text>
                </>
              ) : (
                <>
                  <UserGroup users={previewInvitedUsers} inviteCount={inviteCount} />
                  <Text className="text-center font-title text-[18px] font-medium text-vault_text">
                    {inviteCount} {pluralizeText({ count: inviteCount, text: 'friend' })} referred
                  </Text>
                </>
              )}
            </View>
          </View>

          <View className="mt-[40px] box-border w-full px-4 pb-6">
            <View className="mb-3 box-border flex w-full items-center justify-between text-vault_text/50">
              <Text className="font-base text-[14px] font-normal leading-[20px]">
                Top referrers
              </Text>
              <Text className="font-base text-[14px] font-normal leading-[20px]">Referrals</Text>
            </View>

            {isTopInvitersEmpty ? (
              <View className="box-border flex w-full flex-col items-center justify-center gap-3 rounded-xl bg-vault_text/20 p-6">
                <View className="flex items-center gap-3">
                  <FontAwesomeIcon
                    icon={faCircleUser}
                    fontSize={24}
                    className="text-vault_text/50"
                  />
                  <Text className="text-center font-base text-[14px] font-medium text-vault_text">
                    Be the first to refer a friend to this vault
                  </Text>
                </View>
              </View>
            ) : (
              topInviters.map(user => {
                const { inviteCount, inviter, rank } = getFragment(TopInvitersFragmentDoc, user);
                const { id, avatar, displayName, username } = getFragment(
                  InvitedUserFragmentDoc,
                  inviter,
                );

                const displayNameOrUsername = displayName || username || 'Unknown';

                const onAvatarClick = () => {
                  trackEvent({
                    type: EVENTS.OPEN_BOTTOMSHEET,
                    properties: {
                      bottomsheetType: BOTTOMSHEET_TYPES.USER_PROFILE,
                      userId: id,
                    },
                  });

                  openBottomsheet({
                    type: BOTTOMSHEET_TYPES.USER_PROFILE,
                    shared: {
                      withVaultTheme: true,
                    },
                    userProfileBottomsheetProps: {
                      joinDate: null,
                      vaultId: mainVaultId,
                      userId: id,
                      avatarUrl: avatar.userSmallProfileImageUrl || avatar.cdnUrl,
                      vaultArtistId: adminArtist?.artistId,
                      username,
                      displayName: displayNameOrUsername,
                      showAdminOptions: false,
                      withVaultTheme: true,
                      userLocation: null,
                    },
                  });
                };

                return (
                  <View
                    key={id}
                    className="mb-1 box-border flex w-full items-center justify-between gap-3 rounded-xl bg-vault_text/20 px-4 py-3"
                  >
                    <View className="flex items-center gap-4">
                      <Text className="w-4 text-center font-base text-[14px] font-medium tabular-nums text-vault_text/50">
                        {rank}
                      </Text>
                      <ProfileImage
                        profileImageUrl={avatar.userSmallProfileImageUrl || avatar.cdnUrl}
                        className="h-6 w-6 cursor-pointer rounded-full"
                        onClick={onAvatarClick}
                      />
                      <Text className="text-center font-base text-[14px] font-medium text-vault_text">
                        {displayNameOrUsername}
                      </Text>
                    </View>

                    <Text className="text-center font-base text-[14px] font-medium text-vault_text">
                      {inviteCount}
                    </Text>
                  </View>
                );
              })
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

function UserGroup({
  users,
  inviteCount,
}: {
  users: FragmentType<InvitedUserFragmentDoc>[];
  inviteCount: number;
}) {
  const remainingInvites = inviteCount - users.length;

  return (
    <View className="flex items-center">
      <View className="mr-3 flex w-full items-center justify-center">
        {users.map(userFrag => {
          const { avatar, updatedAt } = getFragment(InvitedUserFragmentDoc, userFrag);

          return (
            <ProfileImage
              key={updatedAt}
              profileImageUrl={avatar.userSmallProfileImageUrl || avatar.cdnUrl}
              className="-mr-2 h-6 w-6 rounded-full border-2 border-solid border-base800"
              onClick={undefined}
            />
          );
        })}
      </View>
      {remainingInvites > 0 && (
        <Text className="text-nowrap font-base text-[14px] font-normal text-base400">
          +{remainingInvites} more
        </Text>
      )}
    </View>
  );
}

export function SkeletonVaultInvitesView() {
  return (
    <View className="no-scrollbar -mb-4 mt-12 flex min-h-full w-full flex-1 flex-col items-center overflow-y-scroll">
      <View className="relative top-0 z-base aspect-square w-full overflow-clip" />
      <View className="z-above1 -mt-[270px] flex w-full flex-col md2:-mt-[400px]">
        <View className="flex flex-col items-center">
          <LoadingSkeleton className="mb-[12px] h-[32px] w-[250px] bg-vault_text/10" />
          <LoadingSkeleton className="mb-[20px] h-[32px] w-[200px] bg-vault_text/10" />

          <View className="mb-6 flex w-[60%] min-w-[300px] flex-col items-center justify-center gap-2 text-center font-base !text-base-l font-normal md2:mb-[28px]">
            <LoadingSkeleton className="h-[16px] w-full bg-vault_text/10" />
            <LoadingSkeleton className="h-[16px] w-full bg-vault_text/10" />
          </View>

          <View className="mb-10 flex w-[60%] min-w-[300px] flex-row items-center justify-center gap-2 text-center font-base !text-base-l font-normal md2:mb-[28px]">
            <LoadingSkeleton className="h-[48px] w-[200px] rounded-full bg-vault_text/10" />
            <LoadingSkeleton className="h-[48px] w-[48px] rounded-full bg-vault_text/10" />
          </View>

          <View className="mb-6 box-border flex w-[80%] flex-1 flex-col items-center">
            <LoadingSkeleton className="h-[100px] w-full bg-vault_text/10" />
          </View>

          <View className="mt-6 box-border flex w-[80%] flex-1 flex-col items-center gap-2">
            <View className="mb-2 flex w-full items-center justify-between">
              <LoadingSkeleton className="h-[20px] w-[80px] bg-vault_text/10" />
              <LoadingSkeleton className="h-[20px] w-[80px] bg-vault_text/10" />
            </View>

            <LoadingSkeleton className="h-[56px] w-full bg-vault_text/10" />
            <LoadingSkeleton className="h-[56px] w-full bg-vault_text/10" />
            <LoadingSkeleton className="h-[56px] w-full bg-vault_text/10" />
          </View>
        </View>
      </View>
    </View>
  );
}
