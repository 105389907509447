import { useEffect, useMemo } from 'react';
import msFn from 'ms';
import { useSearchParams } from 'react-router-dom';
import { proxy, useSnapshot } from 'valtio';
import * as z from 'zod';
import { gql, type StringDocumentNode } from '@soundxyz/gql-string';
import type { UseMutateAsyncFunction } from '@soundxyz/graphql-react-query/reactQuery';
import { createDeferredPromise } from '@soundxyz/utils';
import { PILLARS } from '@soundxyz/vault-utils/dist/constants';
import { Button } from '../../components/buttons/Button';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { type ExecutionResultWithData, useMutation, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  AppleMusicAuthConnectionDocument,
  AppleMusicDeveloperTokenDocument,
  AuthUserDocument,
  ConnectAppleMusicDocument,
  type ConnectAppleMusicMutation,
  type Exact,
  LinkAppleMusicDocument,
  type MutationConnectAppleMusicInput,
  UnlinkAppleMusicDocument,
} from '../../graphql/generated';
import { LoginStatus } from '../../types/authTypes';
import { IdempotentFunctionCall } from '../../utils/idempotent';
import { PersistenceStorage } from '../../utils/storeUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { useLogError } from '../logger/useLogError';
import { useLatestRef } from '../useLatestRef';
import { useStableCallback } from '../useStableCallback';

gql(/* GraphQL */ `
  query AppleMusicDeveloperToken($origin: URL!, $accessSecret: String) {
    appleMusicDeveloperToken(origin: $origin, accessSecret: $accessSecret) {
      __typename
      ... on QueryAppleMusicDeveloperTokenSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }

  mutation ConnectAppleMusic($input: MutationConnectAppleMusicInput!) {
    connectAppleMusic(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }

  query AppleMusicAuthConnection($userToken: String!) {
    appleMusicAuthConnection(userToken: $userToken) {
      userToken

      user {
        id
      }
    }
  }

  mutation LinkAppleMusic($input: MutationLinkAppleMusicInput!) {
    linkAppleMusic(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }

  mutation UnlinkAppleMusic {
    unlinkAppleMusic {
      __typename
      ... on Error {
        message
      }
    }
  }
`);

const MusicKitInstance: {
  current: MusicKit.MusicKitInstance | null;
} = {
  current: null,
};

export const MusicKitReady = proxy({
  musickitLoaded: false,
  instanceLoaded: false,
});

export async function configureMusicKit({
  developerToken,
  logError,
}: {
  developerToken: string;
  logError: ReturnType<typeof useLogError>;
}) {
  if (!MusicKitReady.musickitLoaded) return;

  try {
    await MusicKit.configure({
      developerToken,
      app: {
        name: 'Vault.fm',
        build: '0.0.1',
      },
    });

    MusicKitInstance.current = MusicKit.getInstance();
    MusicKitReady.instanceLoaded = true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error configuring MusicKit', error);
    logError({
      error,
      errorType: 'THIRD_PARTY_LINK_ERROR',
      message: 'Error configuring MusicKit',
      level: 'error',
      action: 'APPLE_CONNECT_ERROR',
      pillar: PILLARS.THIRD_PARTY,
      feature: 'APPLE_SPOTIFY_CONNECT',
      unindexedExtra: { developerToken },
    });
  }
}

document.addEventListener('musickitloaded', () => {
  MusicKitReady.musickitLoaded = true;
});

export const AppleMusicConnectState = PersistenceStorage({
  schema: z.object({
    userToken: z.string().nullable(),
  }),
  key: 'appleMusicConnectState',
  eager: true,
});

const origin = new URL(window.location.href).origin;

const fifteenMinutes = msFn('15 minutes');

RefetchOnComplete({
  trigger: [ConnectAppleMusicDocument],
  refetch: [AppleMusicAuthConnectionDocument],
});

RefetchOnComplete({
  trigger: [LinkAppleMusicDocument, UnlinkAppleMusicDocument, ConnectAppleMusicDocument],
  refetch: [AppleMusicAuthConnectionDocument, AuthUserDocument],
});

export const connectAppleMusicApi = async ({
  userToken,
  connectAppleMusic,
  openBottomsheet,
  openToast,
  loggedInUserId,
  logError,
}: {
  userToken: string;
  connectAppleMusic: UseMutateAsyncFunction<
    ExecutionResultWithData<ConnectAppleMusicMutation>,
    Error,
    Exact<{
      input: MutationConnectAppleMusicInput;
    }>,
    {
      query: StringDocumentNode<
        ConnectAppleMusicMutation,
        Exact<{
          input: MutationConnectAppleMusicInput;
        }>
      >;
    }
  >;
  openBottomsheet: ReturnType<typeof useBottomsheetContainer>['openBottomsheet'];
  openToast: ReturnType<typeof useToast>['openToast'];
  loggedInUserId: string | null;
  logError: ReturnType<typeof useLogError>;
}) => {
  try {
    const result = await connectAppleMusic({
      input: {
        userToken,
      },
    });

    if (result.data.connectAppleMusic.__typename !== 'MutationConnectAppleMusicSuccess') {
      if (result.data.connectAppleMusic.__typename === 'AppleMusicAlreadyLinkedError') {
        return openBottomsheet({
          type: 'CONFIRMATION',
          confirmationBottomsheetProps: {
            title: 'Apple Music already linked',
            subText:
              'Your Apple Music account is already linked to another Vault account. Would you like to link it to this account instead?',
            onConfirm() {
              connectAppleMusic({
                input: {
                  userToken,
                  overrideAccount: true,
                },
              })
                .then(result => {
                  if (
                    result.data.connectAppleMusic.__typename === 'MutationConnectAppleMusicSuccess'
                  ) {
                    openToast({
                      text: 'Apple Music account linked',
                      variant: 'success',
                    });
                  } else {
                    openToast({
                      text: result.data.connectAppleMusic.message,
                      variant: 'error',
                    });
                  }
                })
                .catch(error => {
                  logError({
                    error,
                    errorType: 'THIRD_PARTY_LINK_ERROR',
                    message: 'Error linking Apple Music account in connectAppleMusicApi',
                    level: 'error',
                    action: 'APPLE_CONNECT_ERROR',
                    pillar: PILLARS.THIRD_PARTY,
                    feature: 'APPLE_SPOTIFY_CONNECT',
                    indexedTags: { loggedInUserId, appleAuthType: 'link-apple-music' },
                    openToast,
                    toast:
                      'An error occurred while linking Apple Music account. Please try again later',
                  });
                });
            },
          },
        });
      }

      return openToast({
        text: result.data.connectAppleMusic.message,
        variant: 'error',
      });
    }

    AppleMusicConnectState.set({
      userToken,
    });

    openToast({
      text: 'Apple Music account connected',
      variant: 'success',
    });
  } catch (error) {
    logError({
      error,
      errorType: 'THIRD_PARTY_LINK_ERROR',
      message: 'Error connecting to Apple Music in connectAppleMusicApi',
      level: 'error',
      action: 'APPLE_CONNECT_ERROR',
      pillar: PILLARS.THIRD_PARTY,
      feature: 'APPLE_SPOTIFY_CONNECT',
      indexedTags: { loggedInUserId, appleAuthType: 'connect' },
      openToast,
      toast: 'An error occurred while connecting Apple Music account. Please try again later',
    });
  }
};

export function useAppleMusicAuth({ enabled }: { enabled: boolean }) {
  const logError = useLogError();
  const { musickitLoaded, instanceLoaded } = useSnapshot(MusicKitReady);

  const { value: connectState } = AppleMusicConnectState.useStore();

  const { openToast } = useToast();

  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const { mutateAsync: unlinkAppleMusic, isLoading: isUnlinking } = useMutation(
    UnlinkAppleMusicDocument,
    {
      retry: 3,
      onSuccess(data) {
        if (data.data.unlinkAppleMusic.__typename !== 'MutationUnlinkAppleMusicSuccess') {
          openToast({
            text: data.data.unlinkAppleMusic.message,
            variant: 'error',
          });
        }
      },
    },
  );

  const { mutateAsync: linkAppleMusic, isLoading: isLinking } = useMutation(
    LinkAppleMusicDocument,
    {
      retry: 3,
    },
  );

  const { mutateAsync: connectAppleMusic, isLoading: isConnecting } = useMutation(
    ConnectAppleMusicDocument,
    {
      retry: 3,
    },
  );

  const isAnyMutationLoading = isUnlinking || isLinking || isConnecting;

  const { data: appleMusicConnection, isLoading: appleMusicConnectionLoading } = useQuery(
    AppleMusicAuthConnectionDocument,
    {
      variables: !!connectState?.userToken && { userToken: connectState.userToken },
      staleTime: 0,
      select(data) {
        return data.data.appleMusicAuthConnection;
      },
    },
  );

  useEffect(() => {
    if (appleMusicConnection === null) {
      AppleMusicConnectState.set({ userToken: null });
    }
  }, [appleMusicConnection]);

  const { loggedInUser, loginStatus } = useAuthContext();

  const loggedInUserId = loggedInUser?.id ?? null;

  const hasAppleMusicConnection = !!loggedInUser?.appleMusicAuthConnections.length;

  const { data: developerToken = null } = useQuery(AppleMusicDeveloperTokenDocument, {
    variables: {
      origin,
      accessSecret: import.meta.env.VITE_APPLE_MUSIC_SECRET_ACCESS_TOKEN,
    },
    staleTime: fifteenMinutes,
    refetchInterval: fifteenMinutes,
    enabled: enabled && !hasAppleMusicConnection && loginStatus !== LoginStatus.LOADING,
    select(data) {
      return data.data.appleMusicDeveloperToken.__typename ===
        'QueryAppleMusicDeveloperTokenSuccess'
        ? data.data.appleMusicDeveloperToken.data
        : null;
    },
  });

  useEffect(() => {
    if (!musickitLoaded) {
      if (typeof MusicKit !== 'undefined' && !MusicKitReady.musickitLoaded) {
        MusicKitReady.musickitLoaded = true;
      }
    }

    if (!musickitLoaded || !developerToken) return;

    IdempotentFunctionCall(() => configureMusicKit({ developerToken, logError }), {
      key: `music-kit-${developerToken}`,
      clearOnFinish: false,
    });
  }, [musickitLoaded, developerToken, logError]);

  return useMemo(() => {
    if (isAnyMutationLoading) {
      return {
        type: 'loading',
      } as const;
    }

    if (hasAppleMusicConnection) {
      return {
        type: 'apple-music-already-linked',
        async unlink() {
          AppleMusicConnectState.set({ userToken: null });
          return Promise.all([unlinkAppleMusic({}), MusicKitInstance.current?.unauthorize()])
            .then(() => {
              openToast({
                text: 'Apple account disconnected',
                variant: 'success',
              });
            })
            .catch(error => {
              logError({
                error,
                errorType: 'THIRD_PARTY_LINK_ERROR',
                message: 'Error unlinking Apple Music account',
                level: 'error',
                action: 'APPLE_CONNECT_ERROR',
                pillar: PILLARS.THIRD_PARTY,
                feature: 'APPLE_SPOTIFY_CONNECT',
                indexedTags: { loggedInUserId, appleAuthType: 'apple-music-already-linked' },
                unindexedExtra: { userToken: appleMusicConnection?.userToken, loggedInUserId },
                openToast,
                toast:
                  'An error occurred while unlinking Apple Music account. Please try again later',
              });
            });
        },
      } as const;
    }

    if (appleMusicConnection) {
      if (appleMusicConnection.user && appleMusicConnection.user?.id === loggedInUserId) {
        return {
          type: 'apple-music-already-linked',
          async unlink() {
            AppleMusicConnectState.set({ userToken: null });
            return Promise.all([
              unlinkAppleMusic({}),
              MusicKitInstance.current?.unauthorize(),
            ]).catch(error => {
              logError({
                error,
                errorType: 'THIRD_PARTY_LINK_ERROR',
                message: 'Error unlinking Apple Music account',
                level: 'error',
                action: 'APPLE_CONNECT_ERROR',
                pillar: PILLARS.THIRD_PARTY,
                feature: 'APPLE_SPOTIFY_CONNECT',
                indexedTags: { loggedInUserId, appleAuthType: 'apple-music-already-linked' },
                unindexedExtra: { userToken: appleMusicConnection?.userToken, loggedInUserId },
                openToast,
                toast:
                  'An error occurred while unlinking Apple Music account. Please try again later',
              });
            });
          },
        } as const;
      }

      if (!loggedInUserId) {
        return {
          type: 'apple-music-connected-without-user',
          userToken: appleMusicConnection.userToken,
        } as const;
      }

      return {
        type: 'link-apple-music',
        userToken: appleMusicConnection.userToken,
        async link() {
          try {
            const result = await linkAppleMusic({
              input: {
                userToken: appleMusicConnection.userToken,
                overrideAccount:
                  !!appleMusicConnection.user && appleMusicConnection.user.id !== loggedInUserId,
              },
            });

            if (result.data.linkAppleMusic.__typename !== 'MutationLinkAppleMusicSuccess') {
              if (result.data.linkAppleMusic.__typename === 'AppleMusicAlreadyLinkedError') {
                return openBottomsheet({
                  type: 'CONFIRMATION',
                  confirmationBottomsheetProps: {
                    title: 'Apple Music already linked',
                    subText:
                      'Your Apple Music account is already linked to another Vault account. Would you like to link it to this account instead?',
                    onConfirm() {
                      linkAppleMusic({
                        input: {
                          userToken: appleMusicConnection.userToken,
                          overrideAccount: true,
                        },
                      })
                        .then(result => {
                          if (
                            result.data.linkAppleMusic.__typename ===
                            'MutationLinkAppleMusicSuccess'
                          ) {
                            openToast({
                              text: 'Apple Music account linked',
                              variant: 'success',
                            });
                          } else {
                            openToast({
                              text: result.data.linkAppleMusic.message,
                              variant: 'error',
                            });
                          }
                        })
                        .catch(error => {
                          logError({
                            error,
                            errorType: 'THIRD_PARTY_LINK_ERROR',
                            message: 'Error linking Apple Music account',
                            level: 'error',
                            action: 'APPLE_CONNECT_ERROR',
                            pillar: PILLARS.THIRD_PARTY,
                            feature: 'APPLE_SPOTIFY_CONNECT',
                            indexedTags: {
                              loggedInUserId,
                              appleAuthType: 'link-apple-music',
                            },
                            unindexedExtra: {
                              userToken: appleMusicConnection.userToken,
                              loggedInUserId,
                            },
                            openToast,
                            toast:
                              'An error occurred while linking Apple Music account. Please try again later',
                          });
                        });
                    },
                  },
                });
              }

              return logError({
                error: new Error(result.data.linkAppleMusic.message),
                errorType: 'THIRD_PARTY_LINK_ERROR',
                message: result.data.linkAppleMusic.message,
                level: 'error',
                action: 'APPLE_CONNECT_ERROR',
                pillar: PILLARS.THIRD_PARTY,
                feature: 'APPLE_SPOTIFY_CONNECT',
                indexedTags: {
                  loggedInUserId,
                  appleAuthType: 'link-apple-music',
                },
                unindexedExtra: {
                  userToken: appleMusicConnection.userToken,
                  loggedInUserId,
                },
                openToast,
                toast:
                  'An error occurred while linking Apple Music account. Please try again later',
              });
            }

            openToast({
              text: 'Apple Music account linked',
              variant: 'success',
            });
          } catch (error) {
            logError({
              error,
              errorType: 'THIRD_PARTY_LINK_ERROR',
              message: 'Error linking Apple Music account',
              level: 'error',
              action: 'APPLE_CONNECT_ERROR',
              pillar: PILLARS.THIRD_PARTY,
              feature: 'APPLE_SPOTIFY_CONNECT',
              indexedTags: {
                loggedInUserId,
                appleAuthType: 'link-apple-music',
              },
              unindexedExtra: {
                userToken: appleMusicConnection.userToken,
                loggedInUserId,
              },
              openToast,
              toast: 'An error occurred while linking Apple Music account. Please try again later',
            });
          }
        },
      } as const;
    }

    if (!instanceLoaded || !MusicKitInstance.current || appleMusicConnectionLoading) {
      return {
        type: 'loading',
      } as const;
    }

    const musicKitInstance = MusicKitInstance.current;

    if (musicKitInstance.isAuthorized && musicKitInstance.musicUserToken) {
      return {
        type: 'connected-without-api-confirmation',
        userToken: musicKitInstance.musicUserToken,
        disconnect() {
          return musicKitInstance.unauthorize();
        },
        connectWithApi() {
          return connectAppleMusicApi({
            userToken: musicKitInstance.musicUserToken,
            connectAppleMusic,
            openBottomsheet,
            openToast,
            loggedInUserId,
            logError,
          }).catch(error => {
            return logError({
              error,
              errorType: 'THIRD_PARTY_LINK_ERROR',
              message: 'Error connecting to Apple Music',
              level: 'error',
              action: 'APPLE_CONNECT_ERROR',
              pillar: PILLARS.THIRD_PARTY,
              feature: 'APPLE_SPOTIFY_CONNECT',
              indexedTags: { loggedInUserId, appleAuthType: 'connected-without-api-confirmation' },
            });
          });
        },
      } as const;
    }

    return {
      type: 'connect',
      async connect() {
        const result = await musicKitInstance.authorize().catch(error => {
          logError({
            error,
            errorType: 'THIRD_PARTY_LINK_ERROR',
            message: 'MusicKit error connecting to Apple Music',
            level: 'error',
            action: 'APPLE_CONNECT_ERROR',
            pillar: PILLARS.THIRD_PARTY,
            feature: 'APPLE_SPOTIFY_CONNECT',
          });

          const musicKitErrorCode = (error as MusicKit.MKError).errorCode;

          if (
            musicKitErrorCode === MusicKit.MKError.AUTHORIZATION_ERROR ||
            musicKitErrorCode === MusicKit.MKError.ACCESS_DENIED
          ) {
            openBottomsheet({
              type: BOTTOMSHEET_TYPES.CUSTOM,
              customBottomsheetProps: {
                body: (
                  <View className="mx-4 flex flex-col gap-4 text-center">
                    <Text className="font-title text-title-l">
                      Sorry, you must have an active Apple Music subscription
                    </Text>
                    <Text className="text-base-l text-base400">
                      We only support pre-saving with accounts with an active Apple Music
                      subscription
                    </Text>
                    <Button
                      className="mt-6"
                      type="primary"
                      label="Got it"
                      onClick={() => closeBottomsheet()}
                    />
                  </View>
                ),
              },
            });
          } else {
            openToast({
              text: 'An error occurred while connecting to Apple Music. Please try again later.',
              variant: 'error',
            });
          }

          return null;
        });

        if (!result) {
          return;
        }

        await connectAppleMusicApi({
          userToken: result,
          connectAppleMusic,
          openBottomsheet,
          openToast,
          loggedInUserId,
          logError,
        }).catch(error => {
          logError({
            error,
            errorType: 'THIRD_PARTY_LINK_ERROR',
            message: 'Error connecting to Apple Music',
            level: 'error',
            action: 'APPLE_CONNECT_ERROR',
            pillar: PILLARS.THIRD_PARTY,
            feature: 'APPLE_SPOTIFY_CONNECT',
            indexedTags: { loggedInUserId, appleAuthType: 'connect' },
          });
        });
      },
    } as const;
  }, [
    isAnyMutationLoading,
    hasAppleMusicConnection,
    appleMusicConnection,
    instanceLoaded,
    appleMusicConnectionLoading,
    unlinkAppleMusic,
    openToast,
    logError,
    loggedInUserId,
    linkAppleMusic,
    openBottomsheet,
    connectAppleMusic,
    closeBottomsheet,
  ]);
}

export function useLinkAppleMusicAccount() {
  const { mutateAsync: linkAppleMusic } = useMutation(LinkAppleMusicDocument, {
    retry: 3,
  });
  const logError = useLogError();

  const { refetchAuthUser } = useAuthContext();

  const { openBottomsheet, onBottomsheetClose } = useBottomsheetContainer();

  const { openToast, toastProps } = useToast();

  const currentToastProps = useLatestRef(toastProps);

  const [searchParams] = useSearchParams();

  return useStableCallback(
    async ({
      userToken = AppleMusicConnectState.state.value?.userToken,
    }: { userToken?: string | null } = {}) => {
      if (!userToken) {
        return {
          type: 'no-user-token',
        } as const;
      }

      const authUser = await refetchAuthUser();

      if (authUser?.data?.data.currentUser.__typename !== 'QueryCurrentUserSuccess') {
        return {
          type: 'no-user',
          signInPath: `${ROUTES.SIGN_IN}?${constructQueryParams({
            linkAppleMusic: true,
            invite: searchParams.get('invite'),
            redirect: window.location.pathname,
            trackId: searchParams.get('trackId'),
            source: searchParams.get('source'),
            artistHandle: searchParams.get('artistHandle'),
          })}`,
        } as const;
      }

      const promise = createDeferredPromise<
        { type: 'apple-music-account-linked' } | { type: 'apple-music-account-not-linked' }
      >();

      linkAppleMusic({
        input: {
          userToken,
          overrideAccount: false,
        },
      })
        .then(result => {
          if (result.data.linkAppleMusic.__typename !== 'MutationLinkAppleMusicSuccess') {
            if (result.data.linkAppleMusic.__typename === 'AppleMusicAlreadyLinkedError') {
              const cleanupBottomsheetClose = onBottomsheetClose(() => {
                promise.resolve({ type: 'apple-music-account-not-linked' });
              });
              promise.promise.finally(() => {
                cleanupBottomsheetClose();
              });
              return openBottomsheet({
                type: 'CONFIRMATION',
                confirmationBottomsheetProps: {
                  title: 'Apple Music already linked',
                  subText:
                    'Your Apple Music account is already linked to another Vault account. Would you like to link it to this account instead?',
                  onConfirm() {
                    linkAppleMusic({
                      input: {
                        userToken,
                        overrideAccount: true,
                      },
                    }).then(result => {
                      if (
                        result.data.linkAppleMusic.__typename === 'MutationLinkAppleMusicSuccess'
                      ) {
                        openToast({
                          text:
                            currentToastProps.current?.text === 'Presaved successfully'
                              ? 'Track presaved and Apple Music account linked!'
                              : 'Apple Music account linked',
                          variant: 'success',
                        });
                        promise.resolve({
                          type: 'apple-music-account-linked',
                        });
                      } else {
                        openToast({
                          text: result.data.linkAppleMusic.message,
                          variant: 'error',
                        });
                        promise.resolve({
                          type: 'apple-music-account-not-linked',
                        });
                      }
                    });
                  },
                },
              });
            }

            openToast({
              text: result.data.linkAppleMusic.message,
              variant: 'error',
            });

            promise.resolve({ type: 'apple-music-account-not-linked' });
          } else {
            openToast({
              text:
                currentToastProps.current?.text === 'Presaved successfully'
                  ? 'Track presaved and Apple Music account linked!'
                  : 'Apple Music account linked',
              variant: 'success',
            });

            promise.resolve({
              type: 'apple-music-account-linked',
            });
          }
        })
        .catch(error => {
          logError({
            error,
            errorType: 'THIRD_PARTY_LINK_ERROR',
            message: 'Error linking Apple Music account in useLinkAppleMusicAccount',
            level: 'error',
            action: 'APPLE_CONNECT_ERROR',
            pillar: PILLARS.THIRD_PARTY,
            feature: 'APPLE_SPOTIFY_CONNECT',
            unindexedExtra: {
              appleMusicAuth: AppleMusicConnectState.state.value,
            },
            openToast,
            toast: "Couldn't link Apple Music account. Please try again later.",
            indexedTags: {
              appleAuthType: 'link-apple-music',
            },
          });
          promise.resolve({
            type: 'apple-music-account-not-linked',
          });
        });

      return promise.promise;
    },
  );
}
