import React, { useMemo } from 'react';
import { format } from 'date-fns';
import millify from 'millify';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { BADGE_MATERIAL, VAULT_LOGO_WHITE_PNG } from '../../constants/imageConstants';
import { useQuery } from '../../graphql/client';
import { GetTotalReceiptNumberDocument } from '../../graphql/generated';
import { useAdminArtist } from '../../hooks/useAdminArtist';
import { useBetterGate } from '../../hooks/useFeatureGate';
import { formatSerialNumber, pluralizeText } from '../../utils/textUtils';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';

gql(/* GraphQL */ `
  query GetTotalReceiptNumber($input: QueryTotalReceiptCountInput!) {
    totalReceiptCount(input: $input)
  }
`);

export function StaticMembershipBadge({
  isLoading,
  artistName,
  serialNumber,
  imageUrl,
  displayName,
  createdAt,
  receiptCount = 0,
  className,
  artistHandle,
}: {
  isLoading: boolean;
  artistName: string;
  serialNumber: number | null | undefined;
  imageUrl: string | null | undefined;
  displayName: string | null | undefined;
  createdAt: string | undefined;
  receiptCount: number | undefined;
  className?: string;
  artistHandle: string;
}) {
  const joinedAt = useMemo(() => {
    if (!createdAt) return '';
    return format(new Date(createdAt), 'M/dd/yy');
  }, [createdAt]);

  const adminArtist = useAdminArtist({
    artistHandle,
  });

  const { data: totalReceiptCount, isLoading: isLoadingTotalReceiptCount } = useQuery(
    GetTotalReceiptNumberDocument,
    {
      variables: !!adminArtist && {
        input: {
          artistId: adminArtist.artistId,
        },
      },
      staleTime: 0,
    },
  );

  const streams = useBetterGate('STREAMS');
  const presaves = useBetterGate('PRESAVES');

  return (
    <View
      className={twMerge(
        'relative flex h-[232px] w-full flex-col items-center overflow-hidden  rounded-xl sm:w-[65%] md2:h-[252px] md2:w-3/4',
        className,
      )}
    >
      <View
        className={twMerge(
          'relative z-above2 h-full w-full border border-solid border-base600 shadow-md',
        )}
      >
        <Image
          className="absolute left-0 aspect-square h-full  w-full object-cover object-left-top"
          src={imageUrl || BADGE_MATERIAL}
          alt="badge"
        />
        {/* Linear Gradient Overlay */}
        <div
          className="absolute bottom-0 left-0 right-0 top-0 "
          style={{
            background:
              'linear-gradient(to bottom, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.75) 100%)',
          }}
        />
        {/* Overlay View */}
        <View className="absolute bottom-0 left-0 flex h-full w-full text-white">
          <View className="flex w-full flex-col justify-between px-4 py-2">
            <View className="flex flex-row items-center justify-between gap-4">
              <View className="flex flex-shrink flex-col">
                {isLoading ? (
                  <LoadingSkeleton className="h-2 w-8" />
                ) : (
                  <Text className="line-clamp-2 overflow-hidden text-ellipsis text-title-m font-bold md2:text-title-l">
                    {artistName}
                  </Text>
                )}
              </View>
              <View className="flex transform items-center gap-1">
                {adminArtist ? null : isLoading || !serialNumber ? (
                  <LoadingSkeleton className="h-2 w-8" />
                ) : (
                  <p className="text-[9.75px] text-white">{formatSerialNumber(serialNumber)}</p>
                )}
                <Image
                  width={40}
                  height={50}
                  src={VAULT_LOGO_WHITE_PNG}
                  className="object-contain"
                  alt="Logo"
                />
              </View>
            </View>
            <View className="flex flex-row items-end justify-between py-3 pl-0 pr-2">
              <View className="flex flex-col gap-1">
                {isLoading ? (
                  <LoadingSkeleton className="h-2 w-10" />
                ) : (
                  <Text className="text-base-m font-bold">{`@${adminArtist?.artistMainLinkValue || displayName}`}</Text>
                )}

                {isLoading ? (
                  <LoadingSkeleton className="h-2 w-14" />
                ) : (
                  <Text className="text-base-s text-base400">{`Joined ${joinedAt}`}</Text>
                )}
              </View>
              <View className="flex flex-col items-end">
                {isLoading ? (
                  <LoadingSkeleton className="mb-0.5 h-5 w-4 rounded-sm" />
                ) : (
                  <Text className="text-title-xl font-bold">
                    {isLoadingTotalReceiptCount
                      ? '...'
                      : millify(totalReceiptCount?.data.totalReceiptCount ?? (receiptCount || 0))}
                  </Text>
                )}
                <Text className="text-base-s text-base400">
                  {pluralizeText({
                    count: receiptCount || 0,
                    text: streams === 'enabled' || presaves === 'enabled' ? 'receipt' : 'RSVP',
                  })}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
