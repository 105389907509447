import { useEffect, useMemo } from 'react';
import { uniqBy } from 'lodash-es';
import { gql } from '@soundxyz/gql-string';
import { ERROR_TYPE, FEATURES, NOTIFICATIONS_ERROR_ACTIONS } from '@soundxyz/vault-utils/constants';
import { useAuthContext } from '../contexts/AuthContext';
import { useInfiniteQuery } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  ArtistNotificationRowFragmentDoc,
  GetActiveVaultSubscriptionsForSettingsDocument,
  makeFragmentData,
  UpdateUserPushNotificationSettingsDocument,
  UpdateUserSmsNotificationSettingsDocument,
} from '../graphql/generated';
import { logError } from './logger/useLogError';

gql(/* GraphQL */ `
  query GetActiveVaultSubscriptionsForSettings($after: String, $first: Int) {
    activeVaultSubscriptionsByPriority(after: $after, first: $first) {
      edges {
        cursor
        node {
          id
          ...ArtistNotificationRow
          artist {
            id
            linkValue
            name
            profileImage {
              id
              url: imageOptimizedUrl(input: { width: 100, height: 100 })
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

RefetchOnComplete({
  trigger: [UpdateUserSmsNotificationSettingsDocument, UpdateUserPushNotificationSettingsDocument],
  refetch: [GetActiveVaultSubscriptionsForSettingsDocument],
});

const LIMIT = 15;

const feature = FEATURES.NOTIFICATIONS;

export function useArtistsNotificationSettings() {
  const { loggedInUser } = useAuthContext();
  const adminArtists = loggedInUser?.adminArtists?.map(({ artistId }) => artistId) ?? [];

  const {
    orderedList,
    isLoading,
    isError,
    error,
    refetch,
    isFetchingNextPage,
    isLoadingNewPage,
    hasNextPage,
    isInitialLoading,
    loadMoreNextPage,
  } = useInfiniteQuery(GetActiveVaultSubscriptionsForSettingsDocument, {
    staleTime: 0,
    filterQueryKey: { loggedInUserId: loggedInUser?.id },
    getNextPageParam: ({ data }) => {
      return (
        data.activeVaultSubscriptionsByPriority.pageInfo.hasNextPage && {
          after: data.activeVaultSubscriptionsByPriority.pageInfo.endCursor,
        }
      );
    },
    list(result) {
      return result.activeVaultSubscriptionsByPriority.edges
        .map(({ node }) => node)
        .filter(({ artist }) => !adminArtists.includes(artist.id));
    },
    uniq({ id }) {
      return id;
    },
    variables({ pageParam }) {
      return {
        after: pageParam?.after ?? null,
        first: LIMIT,
      };
    },
  });

  const adminRows = useMemo(
    () =>
      loggedInUser?.adminArtists?.map(
        ({ artistId, artistMainLinkValue, artistName, artistProfileImage }) => ({
          ...makeFragmentData(
            {
              id: artistId,
              artist: {
                id: artistId,
                name: artistName,
                profileImage: {
                  id: artistProfileImage.id,
                  url: artistProfileImage.artistSmallProfileImageUrl,
                },
                linkValue: artistMainLinkValue,
              },
            },
            ArtistNotificationRowFragmentDoc,
          ),
          id: artistId,
          isManagedVault: true,
        }),
      ) ?? [],
    [loggedInUser?.adminArtists],
  );

  const artists = useMemo(() => {
    if (loggedInUser?.artist != null) {
      return uniqBy([...adminRows, ...orderedList], ({ id }) => id);
    }
    return orderedList;
  }, [loggedInUser?.artist, orderedList, adminRows]);

  useEffect(() => {
    if (!error) return;
    logError({
      action: NOTIFICATIONS_ERROR_ACTIONS.NOTIFICATION_SETTINGS_ERROR,
      error,
      level: 'warning',
      message: 'Failed to fetch subscription notification settings',
      errorType: ERROR_TYPE.FETCH_GQL_ERROR,
      feature,
      indexedTags: {
        userId: loggedInUser?.id,
        source: 'useArtistsNotificationSettings',
      },
    });
  }, [error, loggedInUser?.id]);

  return {
    artists,
    isLoading,
    isError,
    error,
    refetch,
    isFetchingNextPage,
    isLoadingNewPage,
    hasNextPage,
    loadMoreNextPage,
    isInitialLoading,
  };
}
