import { type FormEventHandler, useEffect, useMemo, useState } from 'react';
import { useLoginWithSms } from '@privy-io/react-auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { AUTH_ERROR_ACTIONS, ERROR_TYPE, PILLARS } from '@soundxyz/vault-utils/constants';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { PRIVACY_POLICY_URL } from '../../constants/urlConstants';
import { TOS_URL } from '../../constants/urlConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useQuery } from '../../graphql/client';
import {
  type FragmentType,
  getFragment,
  JoinArtistByIdDocument,
  JoinArtistFragmentDoc,
} from '../../graphql/generated';
import { useLogError } from '../../hooks/logger/useLogError';
import { useFreeTier } from '../../hooks/useFreeTier';
import { useSignIn } from '../../hooks/useSignIn';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useTimer } from '../../hooks/useTimer';
import { useWindow } from '../../hooks/useWindow';
import { SignInStore } from '../../screens/auth/store';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { passiveExhaustiveGuard } from '../../utils/guards';
import { constructQueryParams } from '../../utils/stringUtils';
import { CodeInput } from '../forms/CodeInput';
import { ActionField } from '../structures/ActionField';
import { UserActionField } from '../structures/UserActionField';
import { StaticMembershipBadge } from '../vault/MembershipBadge';
import { AuthBox } from './AuthBox';
import { PhoneAuthInput } from './PhoneAuthInput';

gql(/* GraphQL */ `
  fragment JoinArtist on Artist {
    id
    name
    profileImage {
      id
      dominantColor
      artistFullProfileImageUrl: imageOptimizedUrl
    }

    mainVaultId
    linkValue
  }

  query JoinArtistById($input: QueryArtistByLinkInput!) {
    artist: artistByLink(input: $input) {
      id
      ...JoinArtist
    }
  }
`);
type BaseJoinArtistSignInProps = {
  withMembershipCard?: boolean;
  type:
    | 'inner-circle-landing'
    | 'inner-circle-vault'
    | 'receipts'
    | 'watch'
    | 'listen'
    | 'view'
    | 'messaging'
    | 'rsvp';
  useVaultTheme?: boolean;
  afterJoin?: (() => void) | (() => Promise<void>);
  /**
   * If true, the AuthCTABox will fill the width of its container
   */
  fill: boolean;
};

type JoinArtistSignInProps = BaseJoinArtistSignInProps &
  (
    | {
        artist: FragmentType<typeof JoinArtistFragmentDoc>;
        artistHandle?: string | null | undefined;
      }
    | {
        artist: FragmentType<typeof JoinArtistFragmentDoc> | null | undefined;

        artistHandle: string;
      }
  );

export function AuthCTABox({
  artist: artistFragment,
  artistHandle,
  withMembershipCard,
  type,
  useVaultTheme = true,
  afterJoin,
  fill = true,
}: JoinArtistSignInProps) {
  const { data } = useQuery(JoinArtistByIdDocument, {
    variables: !!artistHandle && { input: { link: artistHandle } },
    enabled: !artistFragment,
    staleTime: 0,
  });

  const [_, setSearchParams] = useSearchParams();
  const artist =
    getFragment(JoinArtistFragmentDoc, artistFragment) ||
    getFragment(JoinArtistFragmentDoc, data?.data.artist);

  const queryParams = constructQueryParams({
    openBottomSheet: 'freeTierModal',
  });

  const { loggedInUser, loginStatus, authError } = useAuthContext();

  const { isOpen, codeRenabled, validPhoneNumber, onSignInClick } = useSignIn();

  const [view, setView] = useState<'join' | 'verify'>('join');

  const { seconds: codeSentDisabledSecondsRemaining, isRunning: isInCooldown } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  const [isSubscribing, setIsSubscribing] = useState(false);

  const {
    loginWithCode,
    state: { status },
  } = useLoginWithSms();

  const isSubmitLoading =
    status === 'sending-code' || status === 'submitting-code' || isSubscribing;
  const isSubmitDisabled =
    isSubmitLoading ||
    (codeSentDisabledSecondsRemaining !== 0 && !!validPhoneNumber && codeRenabled !== 1);

  const userActionFieldCTA = useMemo(() => {
    switch (type) {
      case 'rsvp': {
        return 'RSVP';
      }
      case 'listen':
      case 'view':
      case 'watch':
      case 'messaging':
      case 'inner-circle-landing':
      case 'inner-circle-vault':
      case 'receipts':
      default: {
        return 'Join';
      }
    }
  }, [type]);

  const headerText = useMemo(() => {
    switch (type) {
      case 'inner-circle-landing': {
        return {
          header: 'Join the inner circle',
          subText: (
            <p>
              Join for first access to music, merch,
              <br />
              tickets + all things {artist?.name ? artist.name : ''}
            </p>
          ),
        };
      }
      case 'inner-circle-vault': {
        return {
          header: 'Join the inner circle',
        };
      }
      case 'messaging': {
        return {
          header: 'Unlock messaging',
        };
      }
      case 'receipts': {
        return {
          header: 'Join to collect receipts',
        };
      }
      case 'listen': {
        return {
          header: 'Join to listen',
        };
      }
      case 'view': {
        return {
          header: 'Join to view',
        };
      }
      case 'watch': {
        return {
          header: 'Join to watch',
        };
      }
      case 'rsvp': {
        return {
          header: 'Get notified',
        };
      }
      default: {
        return passiveExhaustiveGuard(type);
      }
    }
  }, [artist?.name, type]);

  const navigate = useNavigate();

  const { openBottomsheet } = useBottomsheetContainer();

  const { isDesktop } = useWindow();

  const { subscribeFreeTier } = useFreeTier();

  const [searchParams] = useSearchParams();

  const inviteCode = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const sourceReleaseCampaignId = searchParams.get('sourceReleaseCampaignId');

  const onJoinFreeClick = useStableCallback(async () => {
    if (!artist) return;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      if (loggedInUser?.username) {
        setIsSubscribing(true);
        try {
          await subscribeFreeTier({
            input: {
              vaultId: artist.mainVaultId,
              inviteCode,
              smsCampaignResponseShortcode,
              sourceReleaseCampaignId,
            },
          });

          await afterJoin?.();
        } finally {
          setIsSubscribing(false);
        }
        return;
      }
      if (!isDesktop && !loggedInUser?.username) {
        return;
      }
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
        membershipConfirmationBottomsheetProps: {
          vaultId: artist.mainVaultId,
          isLoading: false,
          artistHandle: artist.linkValue,
          artistName: artist.name,
          imageUrl: artist.profileImage?.artistFullProfileImageUrl,
          loggedInUserUsername: loggedInUser?.username,
          loginStatus,
          inviteCode: null,
          smsCampaignResponseShortcode: null,
          sourceReleaseCampaignId: null,
          onConfirm: afterJoin,
        },
        shared: {
          hideCloseBottomsheetButton: false,
          preventSwipeToDismiss: false,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          withVaultTheme: true,
          isUnclosable: true,
        },
      });
    } else {
      const queryParams = constructQueryParams({
        artistHandle: artist.linkValue,
        openBottomSheet: 'freeTierModal',
        c: null,
      });

      navigate(`${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
      return;
    }
  });

  const onNextClick: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (isOpen) return;
    if (loggedInUser != null) {
      await onJoinFreeClick();
    } else {
      setSearchParams(queryParams);
      trackEvent({ type: EVENTS.NEXT, properties: { type: 'Sign In' } });
      onSignInClick({
        source: 'VaultLandingView - SignInForm',
        onSuccess: () => {
          setView('verify');
        },
      });
    }
  };

  const membershipCard = artistFragment ? <MembershipCard artist={artistFragment} /> : null;

  const [errorText, setErrorText] = useState<string | null>(null);
  const { onResendCodeClick } = useSignIn();

  const logError = useLogError();

  useEffect(() => {
    if (authError != null) {
      setErrorText('We encountered an error creating your profile');
    }
  }, [authError]);

  const signInState = SignInStore.useStore().value;

  const phoneNumber = signInState?.lastActivePhoneNumber ?? null;

  const hasError = errorText != null;

  const tryLogin = async (code: string): Promise<void> => {
    trackEvent({
      type: EVENTS.RECEIVE_VERIFICATION_CODE,
      properties: {
        artistHandle: null,
        phone_number: phoneNumber,
      },
    });
    try {
      await loginWithCode({ code });
      trackEvent({
        type: EVENTS.SET_VERIFICATION_SUCCESS,
        properties: {
          artistHandle: null,
          phone_number: phoneNumber,
        },
      });
      afterJoin?.();
      // Still loading, need to wait for AuthProvider loginStatus to update
    } catch (e) {
      trackEvent({
        type: EVENTS.SET_VERIFICATION_ERROR,
        properties: {
          artistHandle: null,
          phone_number: phoneNumber,
        },
      });
      logError({
        action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
        error: e,
        errorType: ERROR_TYPE.AUTH_ERROR,
        level: 'warning',
        message: 'We encountered an error verifying your code',
        pillar: PILLARS.AUTH,
        indexedTags: {
          phoneNumber,
        },
        unindexedExtra: {
          source: 'VaultLandingView - VerifyForm',
        },
      });
      setErrorText('We encountered an error verifying your code');

      return;
    }
  };

  const onChange = (value: string) => {
    const newVal = value.replace(/\D/g, '').replace(/\s/g, ''); // Remove non-digit characters

    trackEvent({
      type: EVENTS.INPUT_VERIFICATION_CODE,
      properties: {
        artistHandle: null,
        phone_number: phoneNumber,
      },
    });

    if (newVal.length === 6) {
      void tryLogin(newVal);
    }

    setErrorText(null);
  };

  return (
    <AuthBox
      useVaultTheme={useVaultTheme}
      fill={fill}
      membershipCard={withMembershipCard ? membershipCard : null}
      header={view === 'verify' ? 'Verify' : headerText?.header}
      subText={headerText?.subText}
      onBackClick={view === 'verify' ? () => setView('join') : undefined}
      transparent={type === 'rsvp'}
      userAction={
        view === 'verify' ? (
          <CodeInput
            autoFocus
            length={6}
            placeholder="_"
            onChange={onChange}
            allowedCharacters="numeric"
            hasError={hasError}
            variant="transparent"
            withVaultTheme={useVaultTheme}
          />
        ) : (
          <form onSubmit={onNextClick} className="w-full">
            {loggedInUser != null ? (
              <UserActionField
                userName={
                  loggedInUser.displayName ||
                  loggedInUser.username ||
                  loggedInUser.phone ||
                  '@username'
                }
                profileImageUrl={loggedInUser.avatar.userSmallProfileImageUrl}
                loading={isSubmitLoading}
                disabled={isSubmitDisabled}
                useVaultTheme={useVaultTheme}
                label={userActionFieldCTA}
                buttonType="submit"
              />
            ) : (
              <ActionField
                label={userActionFieldCTA}
                useVaultTheme={useVaultTheme}
                loading={isSubmitLoading}
                disabled={isSubmitDisabled}
                buttonType="submit"
              >
                <PhoneAuthInput useVaultTheme={useVaultTheme} />
              </ActionField>
            )}
          </form>
        )
      }
      footerInfo={
        view === 'verify' ? (
          <p
            className={twMerge(
              'text-center !text-base-s ',
              hasError && 'text-destructive300',
              !hasError && 'opacity-80',
              useVaultTheme ? 'text-vault_text' : 'text-base_text',
            )}
          >
            {hasError ? (
              errorText
            ) : codeSentDisabledSecondsRemaining > 0 && status !== 'submitting-code' ? (
              <>
                Didn't receive the code yet?
                <br />
                <span className="underline">Resend in {codeSentDisabledSecondsRemaining}s</span>
              </>
            ) : (
              <>
                Didn't receive the code yet?
                <br />
                <button
                  onClick={() => {
                    onResendCodeClick({
                      source: 'VaultLandingView - VerifyForm',
                      codeSentDisabledSecondsRemaining,
                    });
                  }}
                  disabled={
                    status === 'sending-code' ||
                    status === 'submitting-code' ||
                    isInCooldown ||
                    loginStatus === LoginStatus.LOADING
                  }
                  className="appearance-none border-none bg-transparent p-0 text-center font-base !text-base-s text-vault_text underline hover:cursor-pointer disabled:opacity-30"
                >
                  {status === 'sending-code'
                    ? 'Sending...'
                    : status === 'submitting-code' || loginStatus === LoginStatus.LOADING
                      ? 'Verifying...'
                      : 'Resend'}
                </button>
              </>
            )}
          </p>
        ) : (
          <p
            className={twMerge(
              'text-base-s',
              useVaultTheme
                ? 'text-vault_text [&_a]:text-vault_accent'
                : 'text-base_text [&_a]:text-base_accent',
            )}
          >
            By signing up, you agree
            <br /> to the&nbsp;
            <a
              href={TOS_URL}
              target="_blank"
              className=" no-underline hover:cursor-pointer"
              onClick={e => isOpen && e.preventDefault()}
            >
              Terms
            </a>
            &nbsp;&&nbsp;
            <a
              href={PRIVACY_POLICY_URL}
              target="_blank"
              className=" no-underline hover:cursor-pointer"
              onClick={e => isOpen && e.preventDefault()}
            >
              Privacy Policy
            </a>
          </p>
        )
      }
    />
  );
}

function MembershipCard({ artist }: { artist: FragmentType<typeof JoinArtistFragmentDoc> }) {
  const { name, linkValue, profileImage } = getFragment(JoinArtistFragmentDoc, artist);

  const randomSerialId = useMemo(() => Math.floor(Math.random() * 1000), []);

  const { loggedInUser } = useAuthContext();

  const displayName = loggedInUser?.displayName ?? loggedInUser?.username ?? 'username';

  return (
    <StaticMembershipBadge
      artistHandle={linkValue}
      imageUrl={profileImage?.artistFullProfileImageUrl}
      isLoading={false}
      artistName={name}
      serialNumber={randomSerialId}
      displayName={displayName}
      createdAt={new Date().toDateString()}
      className="!w-full"
      receiptCount={0}
    />
  );
}
